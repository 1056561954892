import ApiClient from '../../ApiClient'

const INITIAL = 'INITIAL';
const FETCH_APPUNTAMENTI = 'FETCH_APPUNTAMENTI';
const ADD_APPUNTAMENTO = 'ADD_APPUNTAMENTO';
const UPDATE_APPUNTAMENTO = 'UPDATE_APPUNTAMENTO';
const REMOVE_APPUNTAMENTO = 'REMOVE_APPUNTAMENTO';

// action creators
export const fetchAppuntamento = data => ({ type: FETCH_APPUNTAMENTI, payload: data });
export const fetchInitial = data => ({ type: INITIAL, payload: data });
export const addAppuntamento = data => ({ type: ADD_APPUNTAMENTO, payload: data });
export const updateAppuntamento = data => ({ type: UPDATE_APPUNTAMENTO, payload: data });
export const removeAppuntamento = data => ({ type: REMOVE_APPUNTAMENTO, payload: data });

const apiClient = new ApiClient()

// reducer
export const reducer = (state, action) => {
  switch (action.type) {
    case INITIAL:
      return [];
    case FETCH_APPUNTAMENTI:
      return action.payload;
    case ADD_APPUNTAMENTO:
      return [...state, action.payload];
    case UPDATE_APPUNTAMENTO: {
      var foundIndex = state.findIndex(x => x.id == action.payload.id);
      state[foundIndex] = action.payload;
      return [...state];
    }
    case REMOVE_APPUNTAMENTO: {
      const newState = state.filter(a => a.id !== action.payload.id)
      return [...newState];
    }
    default:
      throw new Error();
  }
};

export async function fetchAppuntamenti(dispatch, { from, to }) {
  const response = await apiClient.getAppuntamentiByRange(from, to)
  dispatch(fetchAppuntamento(response.data));
}

export async function addAppuntamenti(dispatch, data) {
  dispatch(addAppuntamento(data));
}

export async function updateAppuntamenti(dispatch, data) {
  dispatch(updateAppuntamento(data));
}

export async function removeAppuntamenti(dispatch, data) {
  dispatch(removeAppuntamento(data));
}