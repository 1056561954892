import React, { useContext, useReducer, useEffect } from "react";
import AppReducer from "./reducers";
import ApiClient from "../../ApiClient";
import { UserNutrizionista } from "../../types/usernutrizionista.interface";

const apiClient = new ApiClient();

interface IInitialState {
  nutrizionista: any;
  user: UserNutrizionista;
  isLoading: boolean;
  isError: boolean;
}
//init
export const initialState: IInitialState = {
  nutrizionista: {},
  user: {
    id: null,
    email: "",
    nome: "",
    cognome: "",
    tos_date_accepted: null,
    tos_version_accepted: null,
    tos_accepted: false,
  },
  isLoading: false,
  isError: false,
};

type UserContextType = {
  nutrizionista: any;
  user: UserNutrizionista;
  isLoading: boolean;
  isError: boolean;
  fetchFailure: () => void;
  fetchInit: () => void;
  fetchUser: (data: UserNutrizionista) => void;
  accettaTos: () => void;
};
const UserContext = React.createContext<UserContextType | undefined>(undefined);

const UserProvider = ({ children }: any) => {
  const [state, dispatch]: [any, any] = useReducer(AppReducer, initialState);

  function fetchInit() {
    dispatch({
      type: "FETCH_INIT",
    });
  }
  function fetchFailure() {
    //failed ad avere l'user, sloggo!
    window.localStorage.clear();
    window.location.reload();
    dispatch({
      type: "FETCH_FAILURE",
    });
  }
  function fetchUser(data: any) {
    dispatch({
      type: "FETCH_USER",
      payload: data,
    });
  }
  
  async function accettaTos() {
    try {
      await apiClient.accettaTos();
      dispatch({
        type: "UPDATE_USER",
        payload: { ...state.user, tos_accepted: true },
      });
    } catch (err: any) {
      fetchFailure();
    }
  }

  //inizializzazione
  useEffect(() => {
    async function fetchData() {
      fetchInit();
      try {
        const response = await apiClient.getUserInfo();
        fetchUser(response.data);
      } catch (err: any) {
        fetchFailure();
      }
    }
    fetchData();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        nutrizionista: state.nutrizionista,
        isLoading: state.isLoading,
        isError: state.isError,
        fetchFailure,
        fetchInit,
        fetchUser,
        accettaTos,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Helper function to get Context
const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
