// reducer
export default (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        sedi: action.payload
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case "ADD":
      return {
        ...state,
        sedi: [...state.sedi, action.payload]
      };
    case "UPDATE": {
      let newSedi = [...state.sedi]
      var foundIndex = newSedi.findIndex(s => s.id == action.payload.id);
      newSedi[foundIndex] = action.payload
      return {
        ...state,
        sedi: [...newSedi]
      };
    }
    case "REMOVE": {
      return {
        ...state,
        sedi: [...state.sedi.filter(s => s.id !== action.payload.id)]
      };
    }
    default:
      throw new Error();
  }
};