import React, { ComponentClass } from "react";
import ApiClient from "../../../ApiClient";
import { validateNutrizionista } from "../../../validators/nutrizionista";
import { UserNutrizionista } from "../../../types/usernutrizionista.interface";

const initUser = (): UserNutrizionista => {
  const p: UserNutrizionista = {
    id: null,
    nome: "",
    cognome: "",
    username: "",
    password: "",
    repeat_password: "",
    email: "",
    tos_version_accepted: null,
    tos_date_accepted: null,
    tos_accepted: false,
  };
  return p;
};

interface State {
  data: StateData;
}

interface StateData {
  users: Array<UserNutrizionista>;
}
// This function takes a component...
export default function Controller(WrappedComponent: any): ComponentClass {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient();

    state: State = {
      data: {
        users: [initUser()],
      },
    };

    creaUtentiNutrizionista = async () => {
      const { data } = this.state;
      //bisogna creare almeno un utente nutrizionista!
      if (data.users.length == 0) {
        window.alert(
          "Attenzione: bisogna creare almeno un account utente per il nutrizionista."
        );
        return;
      }
      //validazione degli utenti!
      let isError = false;
      for (let i = 0; i < data.users.length; i++) {
        const validation = validateNutrizionista(data.users[i]);
        if (validation.isError) {
          window.alert(validation.error);
          isError = true;
        }
      }
      if (!isError) {
        try {
          await this.apiClient.createUsersNutrizionista(
            //@ts-ignore
            this.props.router.params.nid,
            data
          );
          window.alert("Utenti creati!");
          //@ts-ignore
          this.props.router.navigate("/nutrizionisti");
        } catch (err: any) {
          window.alert(
            err.response.data.msg ||
              "Si è verificato un errore durante la creazione dell'account."
          );
        }
      }
    };

    setDatiUsers = (
      i: number,
      field: keyof UserNutrizionista,
      value: string
    ) => {
      if (field === "id") return;
      if (field === "tos_version_accepted") return;
      if (field === "tos_date_accepted") return;
      if (field === "tos_accepted") return;
      const { data } = this.state;
      data.users[i][field] = value;
      this.setState({ data });
    };

    aggiungiUser = () => {
      const { data } = { ...this.state };
      const { users } = data;
      users.push(initUser());
      this.setState({ data });
    };

    rimuoviUser = (i: number) => {
      const { data } = { ...this.state };
      const { users } = data;
      users.splice(i, 1);
      this.setState({ data });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          setDatiUsers={this.setDatiUsers}
          submit={this.creaUtentiNutrizionista}
          state={this.state}
          aggiungiUser={this.aggiungiUser}
          rimuoviUser={this.rimuoviUser}
        />
      );
    }
  };
}
