import React, { Component } from 'react';
import ApiClient from '../../ApiClient'
import { Button, Input, Alert } from 'reactstrap';
import AlertMessage from 'react-s-alert';
import { withRouter } from '../../helper';

class Notifica extends Component {
  apiClient = new ApiClient()

  state = {
    message: "",
  }

  changeMessage = (m) => {
    this.setState({
      message: m
    })
  }

  sendNotifica = async () => {
    const { message } = this.state
    if (message == "") {
      AlertMessage.warning(`Inserisci il messaggio nell'apposito box, e poi premi "Invia".`);
    } else {
      try {
        await this.apiClient.sendNotifica(this.props.router.params.id, {
          message: message
        })
        AlertMessage.success("Notifica iniviata con successo.");
      } catch (err) {
        AlertMessage.error(err.response.data.error);
      }
    }

  }

  render() {
    const { message, } = this.state
    const { paziente } = this.props
    const { has_app_account } = paziente
    return <div>
      {!has_app_account &&
        <div className="mb-10">
          <Alert color="warning"> Questa funzionalità è disponibile solo per gli utenti che hanno l'app mobile.</Alert>
        </div>
      }
      <div className="mb-10">Qui puoi inviare subito un messaggio al paziente, che riceverà una notifica e potrà leggerlo nell'omonima sezione dell'app (attezione: puoi inviare fino ad un massimo di 5 notifiche in un giorno ad un paziente).</div>
      <Input placeholder={"Inserisci un messaggio da mandare al paziente"} disabled={!has_app_account} type="textarea" rows={10} style={{ marginBottom: 10 }} onChange={(evt) => this.changeMessage(evt.target.value)} value={message} />
      <div className={"flexIt"}>
        <Button color="primary" outline disabled={!has_app_account} onClick={this.sendNotifica}>Invia</Button>
      </div>
    </div>
  }
}

export default withRouter(Notifica);