import React, { Component } from 'react';
import { Alert, Spinner, Card, CardBody, Button, Row, Col, Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap';
import Controller from './Controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus, faUserPlus, faTimes, faBell, faMobileAlt, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import moment from 'moment'
import Table from '../../components/Table/Table';
import { withRouter } from '../../helper';

// Implement startWith instead of contain 
const customMatchFunc = (pazienti, searchText) => {
  if (searchText === "" || searchText == undefined || searchText == null) {
    return pazienti
  }
  searchText = searchText.toLowerCase().trim()
  return pazienti.filter(p => (
    p.cognome + " " + p.nome).toLowerCase().includes(searchText) ||
    (p.nome + " " + p.cognome).toLowerCase().includes(searchText) ||
    (p.nome + p.cognome).toLowerCase().includes(searchText) ||
    (p.cognome + p.nome).toLowerCase().includes(searchText)
  )
}

class View extends Component {

  state = {
    popoverNotificaOpen: false
  }

  switchActive = (active, paziente) => {
    paziente.active = active ? 'y' : 'n'
    this.props.updatePaziente(paziente)
  }

  columns = [
    {
      name: '',
      selector: row => <FontAwesomeIcon icon={faMobileAlt} color={row.has_app_account ? "#1196d9" : "#dbdbdb"} />,
      wrap: true,
      width: "50px"
    },
    {
      name: 'Cognome',
      selector: row => row.cognome,
      sortable: true
    },
    {
      name: 'Nome',
      selector: row => row.nome,
      sortable: true
    },
    {
      name: 'Ultimo Appuntamento',
      selector: row => row.dataultimoappuntamento === "-" || row.dataultimoappuntamento == null ? "-" : moment(row.dataultimoappuntamento).format('LL')
    },
    {
      name: 'Ultima Visita',
      selector: row => row.dataultimavisita === "-" || row.dataultimavisita == null ? "-" : moment(row.dataultimavisita).format('LL')
    },
    {
      name: 'Azioni',
      width: "8rem",
      selector: row => <span className={"flexIt"} style={{ justifyContent: 'flex-start' }}>
        <Link to={`/users/${row.id}`}>
          <Button outline color="primary"><FontAwesomeIcon icon={faEye} /></Button>
        </Link>
        &nbsp;
        <Button outline color="danger" onClick={() => this.props.eliminaPaziente(row.id)}><FontAwesomeIcon icon={faTrash} /></Button>
      </span>
    },
  ]

  togglePopoverNotifica = () => this.setState({ popoverNotificaOpen: !this.state.popoverNotificaOpen })

  sendNotifica = () => {
    this.props.sendNotifica()
    this.setState({
      popoverNotificaOpen: false
    })
  }

  render() {
    const { error, loading, users, notificaText } = this.props.state
    const { popoverNotificaOpen, } = this.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <div className="d-flex align-items-center justify-content-center">
              <div style={{ flex: 1 }}>
                <h4>Pazienti</h4>
              </div>
              <div className="d-flex align-items-end justify-content-end">
                <Button id="popoverNotifica" disabled={users.length == 0} className="mr-10" outline color="primary"><FontAwesomeIcon icon={faBell} />&nbsp;Notifica</Button>
                <Popover placement="bottom" isOpen={popoverNotificaOpen} target="popoverNotifica" toggle={this.togglePopoverNotifica}>
                  <PopoverHeader>Notifica</PopoverHeader>
                  <PopoverBody>
                    <Input type="textarea" placeholder="Invia una notifica ai pazienti in elenco" rows={5} onChange={(evt) => this.props.changeTestoNotifica(evt.target.value)} style={{ width: '100%' }} value={notificaText} />
                    <br />
                    <Button disabled={notificaText.length == 0} color={"primary"} block onClick={this.sendNotifica}>Invia</Button>
                  </PopoverBody>
                </Popover>
                <Link to={'/users/add'}><Button outline color="primary"><FontAwesomeIcon icon={faUserPlus} />&nbsp;Nuovo</Button></Link>
              </div>
            </div>
            <hr style={{ margin: 5 }} />
            <Table data={users} columns={this.columns} customMatchFunc={customMatchFunc} />
          </CardBody>
        </Card>

      </div>
    )
  }
}

export default withRouter(Controller(View));