import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container, Nav } from 'reactstrap';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  // AppSidebarNav as AppSidebarNav,
  AppSidebarNav2 as AppSidebarNav,
  AppSidebarToggler,
} from '../../coreui';

import logo from '../../assets/img/brand/logo.png'
import { APP_NAME } from "../../constants"
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import ModalTos from '../../components/ModalTos';
import { useUser } from '../../state/user';
import MyCustomAlert from '../../components/MyCustomAlert';
import { withRouter } from '../../helper';

//trovo routes e nav in base al livello di utente loggato
const DefaultLayout = (props) => {
  const userInfo = useUser()
  const nomeUtente = userInfo.user.nome + " " + userInfo.user.cognome
  const { tos_accepted } = userInfo.user
  if (!tos_accepted)
    return <ModalTos />
  else return (
    <div className="app">
      <AppHeader fixed>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand className="cursorPointer" onClick={() => props.router.navigate("/dashboard")}
          full={{ src: logo, width: '60%', alt: `${APP_NAME} Logo` }}
          minimized={{ src: logo, width: '40%', alt: `${APP_NAME} Logo` }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto mr-5" navbar>
          <AppHeaderDropdown nomeUtente={nomeUtente} />
        </Nav>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg" className={"sidebar-custom-bg"}>
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav navConfig={props.appnavigation} {...props} router={router} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <Alert stack={{ limit: 10 }} effect={'slide'} position={"bottom-right"} timeout={5000} preserveContext={true} contentTemplate={MyCustomAlert} />
          <AppBreadcrumb appRoutes={props.approutes} router={router} />
          <Container fluid>
            <Routes>
              {
                props.approutes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} name={route.name} element={<route.component />} />)
                    : (null);
                })
              }
              <Route path='*' element={<Navigate from="/" to="/dashboard" />} />
            </Routes>
          </Container>
        </main>
      </div>
      <AppFooter>
        <span>&copy; <a href="https://daplife.it">DAPLife</a></span>
        <span className="ml-auto">
          <small>
            Template Powered by CoreUI
          </small>
        </span>
      </AppFooter>
    </div>
  );
}

export default withRouter(DefaultLayout);
