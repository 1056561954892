import React from 'react';
import ApiClient from '../../ApiClient'
import moment from 'moment'
import Alert from 'react-s-alert';

const MAPPING = ["dom", "lun", "mar", "mer", "gio", "ven", "sab"]
const PASTI = ["Colazione", "Spuntino", "Cena", "Pranzo"]

const initPasti = () => {
  return [
    { pasto: "Colazione", ora: "07", minuti: "30", testo: "", collapse: false, },
    { pasto: "Spuntino", ora: "10", minuti: "30", testo: "", collapse: false, },
    { pasto: "Pranzo", ora: "13", minuti: "00", testo: "", collapse: false, },
    { pasto: "Spuntino", ora: "16", minuti: "30", testo: "", collapse: false, },
    { pasto: "Cena", ora: "20", minuti: "00", testo: "", collapse: false, },
  ]
}

// This function takes a component...
export default function Controller(WrappedComponent,) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()

    state = {
      loading: true,
      error: false,
      selectedDate: "",
      dieta: {
        id: null,//se è null allora creo una dieta
        dal: "",//se è vuoto allora inizio chiedendo se usare un template per la nuova dieta, e il periodo temporale della dieta
        al: "",//se è vuoto allora inizio chiedendo se usare un template per la nuova dieta, e il periodo temporale della dieta
        days: [],
        pdf: null,
        note: "",
        visibile: false,
        nome: "",
      },
      paziente: {
        nome: "",
        cognome: "",
      },
      dieteTemplate: [],
      activeIndex: 0,
      //se abilitata, copia automaticamente quello che scrivi in un giorno per tutti gli stessi giorni della settimana (se modifico un lunedi
      // modifico subito tutti i lunedi)
      copiaAutomatica: true
    }

    componentDidMount() {
      //capisco se è una nuova dieta o si sta aprendo una esistente
      if (this.props.router.params.iddieta !== "add") {//si sta aprendo una esistente, la scarico
        this.loadDieta()
      } else {
        //scarico lista diete disponibili per usarle come template eventuale da cui partire
        this.loadDieteTemplate()
      }
      this.loadPaziente()
    }

    loadPaziente = async () => {
      try {
        const pid = this.props.router.params.id
        const paziente = await this.apiClient.getPazienteById(pid)
        const p = {
          nome: paziente.data.nome,
          cognome: paziente.data.cognome,
        }
        this.setState({
          paziente: p
        })
      } catch (err) {
        const p = {
          nome: "Dieta",
          cognome: "",
        }
        this.setState({
          error: true,
          paziente: p
        })
      }
    }

    loadDieteTemplate = async () => {
      try {
        const response = await this.apiClient.getAllDieteTemplate()
        this.setState({
          dieteTemplate: response.data,
          loading: false
        })
      } catch (err) {
        this.setState({
          loading: false,
          error: true
        })
      }
    }

    //toggle per la copia automatica tra i giorni
    toggleCopiaAutomatica = () => {
      this.setState({ copiaAutomatica: !this.state.copiaAutomatica })
    }

    //sto creando una dieta. vedo se partire da un template
    initDieta = (startDate, endDate, template) => {
      const { dieta } = this.state
      dieta.dal = moment(startDate).format('L')
      dieta.al = moment(endDate).format('L')
      dieta.pdf = null
      //salvo la dieta template da usare, se template è diverso da -1 (che corrisponde a "Nuova dieta", senza template)
      let dT = {}
      if (template >= 0) {
        const { dieteTemplate } = this.state
        dT = dieteTemplate.find(d => d.id == template)
        dieta.nome = dT.nome
      }

      dieta.days = []
      const d1 = moment(dieta.dal, 'DD/MM/YYYY')
      const d2 = moment(dieta.al, 'DD/MM/YYYY')
      while (d1 <= d2) {
        if (template >= 0) {
          //prendo pasto corretto in base al giorno della settimana
          dieta.days.push({
            data: d1.format('L'),
            //non posso clonare il semplice array, devo clonare ciascun oggetto dentro senno mantiene il riferimento ad essi!	
            pasti: dT[MAPPING[d1.day()]].map(p => { return { ...p } })
          })
        } else {//default
          dieta.days.push({
            data: d1.format('L'),
            pasti: initPasti()
          })
        }
        //aggiungo giorno
        d1.add(1, 'days')
      }
      this.setState({
        dieta
      })

    }

    //cambia le note della dieta
    changeNote = (note) => {
      const { dieta } = this.state
      dieta.note = note
      this.setState({
        dieta
      })
    }

    //cambia il nome della dieta
    changeNome = (nome) => {
      const { dieta } = this.state
      dieta.nome = nome
      this.setState({
        dieta
      })
    }

    toToday = () => {
      const { dieta } = this.state
      const todayIndex = dieta.days.findIndex(d => d.data === moment().format("DD/MM/YYYY"))
      if (todayIndex !== -1)
        this.setState({ activeIndex: todayIndex })
    }

    //scarico dieta dal backend
    loadDieta = async (iddieta = null) => {
      //se provengo da crea dieta, passo id alla funzione
      //se provengo da visualizza dieta, lo ricavo da url
      if (iddieta == null) {
        iddieta = this.props.router.params.iddieta
      }
      const pid = this.props.router.params.id
      try {
        const dieta = await this.apiClient.getDietaPazienteById(pid, iddieta)
        //formatto correttamente le date
        dieta.al = moment(dieta.al).format('L')
        dieta.dal = moment(dieta.dal).format('L')
        dieta.days.map(d => d.data = moment(d.data).format('L'))
        //il primo giorno che mostro è "oggi", se la dieta lo comprende
        const todayIndex = dieta.days.findIndex(d => d.data === moment().format("DD/MM/YYYY"))
        let activeIndex = 0
        if (todayIndex !== -1)
          activeIndex = todayIndex
        this.setState({
          dieta,
          loading: false,
          activeIndex
        })
      } catch (err) {
        console.log(err)
        this.setState({
          error: true
        })
      }
    }

    //verificare se copiaAutomatica è attivo, nel qual caso copia la giornata odierna ogni 7 giorni
    copiaAutomatica = () => {
      const { copiaAutomatica } = this.state
      if (copiaAutomatica) {
        this.copiaGiornata(7)
      }
    }

    //rearrange items in array pasto
    rearrangePasto = (giornata, k, newK) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      if (newK >= 0 && newK < pasti.length) {//non devo sforare!
        pasti.splice(newK, 0, pasti.splice(k, 1)[0]);
        this.setState({ dieta }, this.copiaAutomatica)
      }
    }

    addPasto = (giornata,) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti.push({ pasto: "Colazione", testo: "", ora: "08", minuti: "00", })
      this.setState({ dieta }, this.copiaAutomatica)
    }

    changeTipoPasto = (giornata, k, pasto) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti[k].pasto = pasto
      this.setState({ dieta }, this.copiaAutomatica)
    }

    changeTestoPasto = (giornata, k, testo) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti[k].testo = testo
      this.setState({ dieta }, this.copiaAutomatica)
    }

    //riordina i pasti di una giornata nello stato, per ora e minuti
    sortPasti = () => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti.sort((a, b) => {
        return ((a.ora + a.minuti <= b.ora + b.minuti) ? -1 : 1)
      })
      this.setState({
        dieta
      }, this.copiaAutomatica)
    }

    changeHourPasto = (giornata, k, ore) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti[k].ora = ore
      this.setState({ dieta }, this.sortPasti)
    }

    changeMinutePasto = (giornata, k, min) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti[k].minuti = min
      this.setState({ dieta }, this.sortPasti)
    }

    removePasto = (giornata, k,) => {
      const { dieta, activeIndex } = this.state
      const { pasti } = dieta.days[activeIndex]
      pasti.splice(k, 1);
      this.setState({ dieta }, this.copiaAutomatica)
    }

    //copia la dieta di un giorno (activeIndex) in tutti i giorni di dieta
    copiaGiornataOvunque = () => {
      if (window.confirm("Sei sicuro? Questa operazione copierà la giornata presente in tutti i giorni della dieta.")) {
        const { dieta, activeIndex } = { ...this.state }
        const { pasti } = dieta.days[activeIndex]
        //sample da replicare    
        const sample = pasti.map(p => { return { ...p } })
        for (let i = 0; i < dieta.days.length; i++) {
          dieta.days[i].pasti = sample.map(p => { return { ...p, } })
        }
        this.setState({
          dieta
        })
      }
    }

    //copia il pasto della giornata attiva ogni giorno della settimana uguale
    copiaGiornata = () => {
      const { dieta, activeIndex } = { ...this.state }
      const { pasti } = dieta.days[activeIndex]
      //sample da replicare    
      const sample = pasti.map(p => { return { ...p } })
      const weekday = moment(dieta.days[activeIndex].data, 'DD/MM/YYYY').day()
      for (let i = 0; i < dieta.days.length; i++) {
        //modifico solo date successive a quella attuale, sennò modifico cose vecchie e si perde lo storico
        //della dieta paziente e la corrispondenza dei "fatto": se gli cambio un pasto che aveva messo
        //"fatto" poi sto scrivendo che ha fatto qualcosa di diverso!
        if (moment(dieta.days[i].data, 'DD/MM/YYYY') >= moment() && moment(dieta.days[i].data, 'DD/MM/YYYY').day() == weekday) {
          dieta.days[i].pasti = sample.map((p, k) => {
            return {
              ...dieta.days[i].pasti[k],
              pasto: p.pasto,
              ora: p.ora,
              minuti: p.minuti,
              testo: p.testo
            }
          })
        }
      }
      this.setState({
        dieta
      })
    }

    downloadPdf = () => {
      if (this.state.dieta.pdf != null) {
        const downloadLink = document.createElement("a");
        const fileName = "dieta.pdf";
        downloadLink.href = this.state.dieta.pdf;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }

    setPdf = (event) => {
      if (event == null) {
        this.setState({
          dieta: {
            ...this.state.dieta,
            pdf: null
          }
        })
        return
      }
      const file = event.target.files[0]
      this.fileToBase64(file, (err, result) => {
        if (result) {
          this.setState({
            dieta: {
              ...this.state.dieta,
              pdf: result
            }
          })
        }
      })
    }

    fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        cb(null, reader.result)
      }
      reader.onerror = function (error) {
        cb(error, null)
      }
    }

    submit = async () => {
      const { dieta } = this.state
      if (this.validateDieta(dieta)) {
        if (dieta.id == null) {//nuova dieta!
          try {
            const result = await this.apiClient.creaDietaPaziente(this.props.router.params.id, dieta)
            Alert.success("Dieta creata!")
            this.props.router.navigate(`/users/${this.props.router.params.id}/diete/${result.data}`)
            this.loadDieta(result.data)
          } catch (err) {
            Alert.error("Errore, impossibile creare la dieta.")
          }
        } else {//modifica dieta esistente!
          try {
            await this.apiClient.updateDietaPaziente(this.props.router.params.id, dieta)
            Alert.success("Dieta modificata!")
            //this.props.router.navigate(`/users/${this.props.router.params.id}`)
          } catch (err) {
            Alert.error("Errore, impossibile modificare la dieta.")
          }
        }
      } else {
        Alert.error("Errore, impossibile eseguire l'operazione.")
      }
    }

    delete = async () => {
      const { dieta } = this.state
      if (window.confirm("Sei sicuro di voler eliminare la dieta?")) {
        try {
          await this.apiClient.deleteDietaPaziente(this.props.router.params.id, dieta)
          Alert.success("Dieta eliminata!")
          this.props.router.navigate(`/users/${this.props.router.params.id}`)
        } catch (err) {
          Alert.error("Errore, impossibile eliminare la dieta.")
        }
      }
    }

    cambiaNomeDieta = (n) => {
      const { dieta } = this.state
      dieta.nome = n
      this.setState({
        dieta
      })
    }

    changeActiveIndex = (activeIndex) => {
      const { dieta } = this.state
      if (activeIndex >= 0 && activeIndex < dieta.days.length)
        this.setState({
          activeIndex
        })
    }

    validateDieta = (dieta) => {
      if (!moment(dieta.dal, 'DD/MM/YYYY').isValid()) {
        return false
      } else if (!moment(dieta.al, 'DD/MM/YYYY').isValid()) {
        return false
      } else if (dieta.nome !== null && dieta.nome.length > 255) {
        return false
      } else {
        //controllo le diverse giornate: data, pasto valido
        for (const d of dieta.days) {
          //controllo data della giornata
          if (!moment(d.data, 'DD/MM/YYYY').isValid()) {
            return false
          }
          //per ogni pasto, controllo che sia uno tra Colazione, Spuntino, Pranzo o Cena
          for (const p of d.pasti) {
            if (!moment(d.data + " " + p.ora + ":" + p.minuti, 'DD/MM/YYYY HH:mm').isValid()) {
              return false
            }
            if (!PASTI.includes(p.pasto)) {
              return false
            }
          }
        }
      }
      return true
    }

    //data la dieta correntemente in visione, crea un template da essa
    creaTemplate = async () => {
      if (window.confirm("Vuoi creare una dieta template a partire da questa dieta?")) {
        try {
          const { dieta } = this.state
          //la risposta, se è ok conterrà l'id del template creato, così ce lo porto subito
          const response = await this.apiClient.createDietaTemplateFromDieta(this.props.router.params.id, dieta.id)
          Alert.success("Dieta template creato!")
          this.props.router.navigate(`/diete/${response.data}`)
        } catch (err) {
          Alert.error("Errore, impossibile creare la dieta template.")
        }
      }
    }

    render() {
      return <WrappedComponent
        {...this.props}
        state={this.state}
        rearrangePasto={this.rearrangePasto}
        addPasto={this.addPasto}
        changeTipoPasto={this.changeTipoPasto}
        changeTestoPasto={this.changeTestoPasto}
        changeHourPasto={this.changeHourPasto}
        changeMinutePasto={this.changeMinutePasto}
        removePasto={this.removePasto}
        submit={this.submit}
        toToday={this.toToday}
        delete={this.delete}
        cambiaNomeDieta={this.cambiaNomeDieta}
        changeActiveIndex={this.changeActiveIndex}
        initDieta={this.initDieta}
        copiaGiornataOvunque={this.copiaGiornataOvunque}
        toggleCopiaAutomatica={this.toggleCopiaAutomatica}
        changeNote={this.changeNote}
        changeNome={this.changeNome}
        creaTemplate={this.creaTemplate}
        downloadPdf={this.downloadPdf}
        setPdf={this.setPdf}
      />
    }
  }
}
