import React, { useEffect, useState } from "react";
import IDocumento from "../../types/IDocumento";
import Table from "../Table";
import { Label, Button, Input, FormGroup, FormText, Alert } from "reactstrap";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ApiClient from "../../ApiClient";
import AlertMessage from "react-s-alert";
import { useParams } from "react-router-dom";

const apiClient = new ApiClient();

export const MAX_FILE_PER_PAZIENTE = 5;
export const MAX_FILE_SIZE = 2 * 1024 * 1024; //piu di 2 MB

const Documenti = () => {
  const [file, setFile] = useState<File>();
  const [documenti, setDocumenti] = useState<Array<IDocumento>>([]);
  let params = useParams();

  useEffect(() => {
    loadDocumenti();
  }, []);

  const getDocument = async (nome: string, id: number) => {
    if (params.id == null) {
      return;
    }
    const pid: number = parseInt(params.id);
    const file = await apiClient.getDocumentoPazienteById(pid, id);

    const href = URL.createObjectURL(file.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", nome); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const loadDocumenti = () => {
    if (params.id == null) {
      return;
    }
    const pid: number = parseInt(params.id);
    apiClient.getDocumentiPaziente(pid).then((d) => setDocumenti(d));
  };

  const deleteDocument = async (id: number) => {
    if (window.confirm("Sei sicuro di voler eliminare questo file?")) {
      if (params.id == null) {
        return;
      }
      const pid: number = parseInt(params.id);
      try {
        await apiClient.deleteDocumentoPazienteById(pid, id);
        AlertMessage.success("Documento eliminato con successo!");
        loadDocumenti();
      } catch (err) {
        AlertMessage.error(
          "Si è verificato un errore durante l'eliminazione del documento."
        );
      }
    }
  };

  const columns = [
    {
      name: "ID",
      selector: (row: IDocumento) => row.id,
      width: "50px",
    },
    {
      name: "Data caricamento",
      width: "230px",
      selector: (row: IDocumento) =>
        moment(row.createdat).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: "Nome",
      selector: (row: IDocumento) => row.nome,
    },
    {
      name: "Azioni",
      width: "120px",
      selector: (row: IDocumento) => (
        <span className={"flexIt"} style={{ justifyContent: "flex-start" }}>
          <Button
            type="submit"
            outline
            color="primary"
            onClick={() => getDocument(row.nome, row.id)}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
          &nbsp;
          <Button outline color="danger" onClick={() => deleteDocument(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </span>
      ),
    },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      //validazione
      if (e.target.files[0].size > MAX_FILE_SIZE) {
        AlertMessage.warning(
          "Attenzione: il documento che hai scelto supera la dimensione massima consentita di 2MB."
        );
        return;
      }
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async () => {
    if (!file || params.id == null) {
      return;
    }
    try {
      const pid: number = parseInt(params.id);
      await apiClient.uploadDocumentoPaziente(pid, file);
      AlertMessage.success("Documento caricato con successo!");
      setFile(undefined);
      loadDocumenti();
    } catch (err) {
      AlertMessage.error(
        "Si è verificato un errore durante il caricamento del documento."
      );
    }
  };

  return (
    <div>
      {documenti.length >= MAX_FILE_PER_PAZIENTE && (
        <Alert color="warning">
          Attenzione, non puoi caricare altri documenti per questo paziente
          poichè hai raggiunto il massimo possibile. Elimina un documento se
          vuoi caricarne un altro.
        </Alert>
      )}
      <Label>Documenti di questo paziente:</Label>
      <Table data={documenti} columns={columns} />
      <hr />
      <FormGroup>
        <Label for="carica-file">Carica nuovo documento</Label>
        <span className="d-flex align-items-center justify-content">
          <Input
            id="carica-file"
            name="file"
            type="file"
            disabled={documenti.length >= MAX_FILE_PER_PAZIENTE}
            onChange={handleFileChange}
          />
          &nbsp;&nbsp;&nbsp;
          <Button
            outline
            color="primary"
            onClick={handleUploadClick}
            disabled={documenti.length >= MAX_FILE_PER_PAZIENTE || !file}
          >
            Carica
          </Button>
        </span>
        <FormText>
          Puoi caricare fino a {MAX_FILE_PER_PAZIENTE} documenti di qualsiasi
          tipo, con dimensione massima di 2MB ciascuno.
        </FormText>
      </FormGroup>
    </div>
  );
};

export default Documenti;
