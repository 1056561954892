import React, { useState, useEffect } from 'react';
import ApiClient from '../../ApiClient'
import { Spinner, Alert, Col, Row, Label, Input, FormGroup, TabPane, Button, Nav, NavItem, TabContent } from 'reactstrap'
import './style.css'
import AlertMessage from "react-s-alert";
import { ALLERGIE, INTEGRATORI, FARMACI, MultiSelectCb, PATOLOGIE, DEFAULT_QUESTIONARIO_NUTRIZIONISTA, DIETA_PARTICOLARE, QTA_ACQUA, ALVO, CICLO, RISPOSTA_SI_NO, ATTIVITA_SPORTIVA_WEEK } from '../../views/ProfiloPaziente/Scheda';
import { NavLink, useParams } from 'react-router-dom';

const apiClient = new ApiClient()

export default () => {
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState(0);
  const pid = id
  const [paziente, setPaziente] = useState({
    isLoading: true,
    isError: false,
    ...DEFAULT_QUESTIONARIO_NUTRIZIONISTA,
  })

  useEffect(() => {
    loadPaziente()
  }, [])

  const salvaQuestionario = async () => {
    try {
      const { questionario_utente } = paziente
      //uso le chiavi del modello di default per prendere i valori da paziente  
      //così non devo riscrivere questo pezzo ogni volta che si aggiunge una voce al questionario  
      let questionario_nutrizionista = {}
      Object.keys(DEFAULT_QUESTIONARIO_NUTRIZIONISTA).map(k => questionario_nutrizionista = { ...questionario_nutrizionista, [k]: paziente[k] })
      await apiClient.updateQuestionario(pid, { questionario_utente, questionario_nutrizionista })
      AlertMessage.success("Salvataggio effettuato!");
    } catch (err) {
      console.log(err)
      AlertMessage.error("Errore, impossibile salvare il questionario.");
    }
  }

  const onMultipleSelectChange = (value, arr, arrName) => {//gestisce la selezione multipla nelle select multiple
    //se il value cliccato è gia nell'array, lo rimuovo (deselezione), se non c'è lo inserisco (selezione)
    if (arr.some(a => a == value)) {//ha dentro l'elemento!
      arr = arr.filter(a => a != value)
    } else {//altrimenti, aggiungo ai selezionati
      arr.push(value)
    }
    const state = { ...paziente }
    state[arrName] = arr
    setPaziente(state)
  }

  const loadPaziente = async () => {
    let _paziente = { ...paziente }
    try {
      const result = await apiClient.getPazienteById(pid)
      //dati questionario.se il paziente ha in pancia i dati li prendo, senno lascio quelli di default nello stato.
      //lo faccio per una retro compatibilità per gli utenti precedenti all'aggiunta del questionario
      _paziente = {
        ..._paziente,
        ...result.data.questionario_nutrizionista
      }
      _paziente = {
        ..._paziente,
        isLoading: false,
        isError: false,
      }
    } catch (err) {
      _paziente = {
        ..._paziente,
        isLoading: false,
        isError: true,
      }
    }
    setPaziente({
      ..._paziente,
    })
  }

  if (paziente.isError) {
    return <Alert color="danger">
      Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
    </Alert>
  } else if (paziente.isLoading) {
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  } else return <div className="animated fadeIn">
    <Row>
      <Col md={12}>
        <Button style={{ float: 'right' }} outline color="primary" onClick={salvaQuestionario}>Salva Anamnesi</Button>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col md={3}>
        <Nav vertical pills>
          <NavItem
            className={`${activeTab != 0 ? 'nav-link ' : ''}`}
            onClick={() => setActiveTab(0)}>
            <NavLink
              className={`${activeTab == 0 ? 'nav-link ' : ''}`}
            >
              ANAMNESI FAMILIARE
            </NavLink>
          </NavItem>
          <NavItem
            className={`${activeTab != 1 ? 'nav-link ' : ''}`}
            onClick={() => setActiveTab(1)}>
            <NavLink
              className={`${activeTab == 1 ? 'nav-link ' : ''}`}
            >
              ANAMNESI FISIOLOGICA
            </NavLink>
          </NavItem>
          <NavItem
            className={`${activeTab != 2 ? 'nav-link ' : ''}`}
            onClick={() => setActiveTab(2)}>
            <NavLink
              className={`${activeTab == 2 ? 'nav-link ' : ''}`}
            >
              ANAMNESI PATOLOGICA – FARMACOLOGICA
            </NavLink>
          </NavItem>
          <NavItem
            className={`${activeTab != 4 ? 'nav-link ' : ''}`}
            onClick={() => setActiveTab(4)}>
            <NavLink
              className={`${activeTab == 4 ? 'nav-link ' : ''}`}
            >
              ALTRO
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col md={9}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            <Row style={{ marginBottom: 15 }}>
              <Col md={2} style={{ fontWeight: "bold" }}>Periodo</Col>
              <Col md={2} style={{ fontWeight: "bold" }}>Familiare</Col>
              <Col md={4} style={{ fontWeight: "bold" }}>Diagnosi</Col>
              <Col md={4} style={{ fontWeight: "bold" }}>Appunti</Col>
            </Row>
            {
              paziente.anamnesiFamiliare.map((a, k) => <Row key={k} style={{ marginBottom: 10 }}>
                <Col md={2}>
                  <Input type="textarea" placeholder="Inserire il periodo..." rows={3}
                    onChange={(evt) => {
                      const anamnesi = [...paziente.anamnesiFamiliare]
                      anamnesi[k].periodo = evt.target.value
                      setPaziente({ ...paziente, anamnesiFamiliare: anamnesi })
                    }}
                    value={a.periodo} />
                </Col>
                <Col md={2}>
                  <Input type="textarea" placeholder="Inserire il familiare..." rows={3}
                    onChange={(evt) => {
                      const anamnesi = [...paziente.anamnesiFamiliare]
                      anamnesi[k].familiare = evt.target.value
                      setPaziente({ ...paziente, anamnesiFamiliare: anamnesi })
                    }}
                    value={a.familiare} />
                </Col>
                <Col md={4}>
                  <Input type="textarea" placeholder="Inserire la diagnosi..." rows={3}
                    onChange={(evt) => {
                      const anamnesi = [...paziente.anamnesiFamiliare]
                      anamnesi[k].diagnosi = evt.target.value
                      setPaziente({ ...paziente, anamnesiFamiliare: anamnesi })
                    }}
                    value={a.diagnosi} />
                </Col>
                <Col md={4}>
                  <Input type="textarea" placeholder="Inserire eventuali note..." rows={3}
                    onChange={(evt) => {
                      const anamnesi = [...paziente.anamnesiFamiliare]
                      anamnesi[k].note = evt.target.value
                      setPaziente({ ...paziente, anamnesiFamiliare: anamnesi })
                    }}
                    value={a.note} />
                </Col>
              </Row>)
            }
          </TabPane>
          <TabPane tabId={1}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Motivo della visita</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire il motivo della visita..." rows={3} onChange={(evt) => setPaziente({ ...paziente, motivoVisita: evt.target.value })} value={paziente.motivoVisita} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Obiettivi</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire l'obiettivo..." rows={3} onChange={(evt) => setPaziente({ ...paziente, obiettivo: evt.target.value })} value={paziente.obiettivo} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Attività lavorativa svolta</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire l'attività lavorativa svolta e per quante ore..." rows={3} onChange={(evt) => setPaziente({ ...paziente, attivitaLavorativa: evt.target.value })} value={paziente.attivitaLavorativa} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="svolgeAttivitàFisica">
                    Svolge attività fisica?
                  </Label>
                  <Input type="select" name="svolgeAttivitàFisica" id="svolgeAttivitàFisica" className="form-control"
                    value={paziente.svolgeAttivitàFisica} onChange={(evt) => setPaziente({ ...paziente, svolgeAttivitàFisica: evt.target.value })}>
                    {
                      RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Row>
                  {ATTIVITA_SPORTIVA_WEEK.map(e => <React.Fragment>
                    <Col md={2} key={e} className='d-flex align-items-center'>
                      <FormGroup check>
                        <Label check for={e}>
                          <Input disabled={paziente.svolgeAttivitàFisica != "SI"} type="checkbox" name={e} id={e} checked={paziente.attivitaFisica.find(a => a.day == e && a.enabled) != undefined}
                            onChange={(evt) => setPaziente({ ...paziente, attivitaFisica: [...paziente.attivitaFisica.filter(a => a.day != e), { day: e, enabled: evt.target.checked, note: paziente.attivitaFisica.find(a => a.day == e).note }] })}
                          />{' '}{e}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={10}>
                      <Input type="text" disabled={paziente.svolgeAttivitàFisica != "SI"} placeholder="Inserire orario o delle note..."
                        onChange={(evt) => setPaziente({ ...paziente, attivitaFisica: [...paziente.attivitaFisica.filter(a => a.day != e), { day: e, enabled: paziente.attivitaFisica.find(a => a.day == e).enabled, note: evt.target.value }] })}
                        value={paziente.attivitaFisica.find(a => a.day == e).note} />
                    </Col>
                  </React.Fragment>
                  )}
                </Row>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="qtaAcqua">
                    <Label style={{ marginTop: 10 }} >Quantità acqua bevuta al giorno</Label>
                  </Label>
                  <Input type="select" name="qtaAcqua" id="qtaAcqua" className="form-control"
                    value={paziente.qtaAcqua} onChange={(evt) => setPaziente({ ...paziente, qtaAcqua: evt.target.value })}>
                    {
                      QTA_ACQUA.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Numero di pasti al giorno?</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire il numero di pasti giornalieri..." rows={3} onChange={(evt) => setPaziente({ ...paziente, numeroPasti: evt.target.value })} value={paziente.numeroPasti} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Quale/i pasto/i salta più spesso?</Label>
                <Input type="textarea" placeholder="Indicare quale o quali pasti salta più sesso..." rows={5} onChange={(evt) => setPaziente({ ...paziente, saltaPasti: evt.target.value })} value={paziente.saltaPasti} />
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Dove consuma i pasti più di frequente?</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire dove consuma i pasti più di frequente..." rows={3} onChange={(evt) => setPaziente({ ...paziente, doveConsuma: evt.target.value })} value={paziente.doveConsuma} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Hai bisogno di pasti al sacco?</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Se si, indica se c’è possibilità di riscaldare il pasto..." rows={3} onChange={(evt) => setPaziente({ ...paziente, pranziSacco: evt.target.value })} value={paziente.pranziSacco} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="dietaParticolare">
                    <Label style={{ marginTop: 10 }} >Segue una dieta particolare?</Label>
                  </Label>
                  <Input type="select" name="dietaParticolare" id="dietaParticolare" className="form-control"
                    value={paziente.dietaParticolare} onChange={(evt) => setPaziente({ ...paziente, dietaParticolare: evt.target.value })}>
                    {
                      DIETA_PARTICOLARE.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Alimenti graditi</Label>
                <Input type="textarea" placeholder="Inserire eventuali alimenti graditi..." rows={5} onChange={(evt) => setPaziente({ ...paziente, alimentiGraditi: evt.target.value })} value={paziente.alimentiGraditi} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Alimenti non graditi</Label>
                <Input type="textarea" placeholder="Inserire eventuali alimenti non graditi..." rows={5} onChange={(evt) => setPaziente({ ...paziente, alimentiNonGraditi: evt.target.value })} value={paziente.alimentiNonGraditi} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Alimenti che non mancano mai nella sua alimentazione</Label>
                <Input type="textarea" placeholder="Inserire eventuali alimenti che non mancano mai nella sua alimentazione..." rows={5} onChange={(evt) => setPaziente({ ...paziente, alimentiNonMancano: evt.target.value })} value={paziente.alimentiNonMancano} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Oltre al pane e la pasta, è solito consumare altri cereali o simili come orzo, farro, quinoa, cous cous?</Label>
                <Input type="textarea" placeholder="Inserire eventuali altri cereali o simili..." rows={5} onChange={(evt) => setPaziente({ ...paziente, cereali: evt.target.value })} value={paziente.cereali} />
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="consumaCarboidrati">
                    Consuma carboidrati complessi (pane, pasta, riso etc) sia nel pranzo che nella cena?
                  </Label>
                  <Input type="select" name="consumaCarboidrati" id="consumaCarboidrati" className="form-control"
                    value={paziente.consumaCarboidrati} onChange={(evt) => setPaziente({ ...paziente, consumaCarboidrati: evt.target.value })}>
                    {
                      RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="consumaFruttaSecca">
                    <Label style={{ marginTop: 10 }} >Consuma frutta secca come noci, mandorle, nocciole, anacardi o semi oleaginosi come semi di zucca, di girasole, di chia, di sesamo?</Label>
                  </Label>
                  <Input type="select" name="consumaFruttaSecca" id="consumaFruttaSecca" className="form-control"
                    value={paziente.consumaFruttaSecca} onChange={(evt) => setPaziente({ ...paziente, consumaFruttaSecca: evt.target.value })}>
                    {
                      RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Consuma frutta?</Label>
                <Input type="textarea" placeholder="Se Si: in quale momento della giornata e quanta" rows={5} onChange={(evt) => setPaziente({ ...paziente, consumaFrutta: evt.target.value })} value={paziente.consumaFrutta} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Consuma dolci abitualmente?</Label>
                <Input type="textarea" placeholder="Se si: in quale momento della giornata e quali dolci consuma " rows={5} onChange={(evt) => setPaziente({ ...paziente, consumaDolci: evt.target.value })} value={paziente.consumaDolci} />
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Label style={{ marginTop: 15 }} >Ore di sonno a notte</Label>
                  </Col>
                  <Col md={12}>
                    <Input type="textarea" placeholder="Inserire il numero di ore di sonno a notte..." rows={1} onChange={(evt) => setPaziente({ ...paziente, oreSonno: evt.target.value })} value={paziente.oreSonno} />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Hai mai perso peso per poi riprenderlo?</Label>
                <Input type="textarea" placeholder="Se si indica quante volte è successo e quanti chili" rows={5} onChange={(evt) => setPaziente({ ...paziente, persoPeso: evt.target.value })} value={paziente.persoPeso} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Hai mai seguito diete con pasti sostitutivi?</Label>
                <Input type="textarea" placeholder="Se si, indica il nome del protocollo e/o dell’integratore usato " rows={5} onChange={(evt) => setPaziente({ ...paziente, pastiSostitutivi: evt.target.value })} value={paziente.pastiSostitutivi} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Altre annotazioni</Label>
                <Input type="textarea" placeholder="Inserire eventuali altre annotazioni..." rows={5} onChange={(evt) => setPaziente({ ...paziente, altreAnnotazioniAlimenti: evt.target.value })} value={paziente.altreAnnotazioniAlimenti} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={2}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="alvo">
                    <Label style={{ marginTop: 10 }} >ALVO</Label>
                  </Label>
                  <Input type="select" name="alvo" id="alvo" className="form-control"
                    value={paziente.alvo} onChange={(evt) => setPaziente({ ...paziente, alvo: evt.target.value })}>
                    {
                      ALVO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="ciclo">
                    <Label style={{ marginTop: 10 }} >Ciclo</Label>
                  </Label>
                  <Input type="select" name="alvo" id="alvo" className="form-control"
                    value={paziente.ciclo} onChange={(evt) => setPaziente({ ...paziente, ciclo: evt.target.value })}>
                    {
                      CICLO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="obesitaInfantile">
                    Hai sofferto di obesità infantile?
                  </Label>
                  <Input type="select" name="obesitaInfantile" id="obesitaInfantile" className="form-control"
                    value={paziente.obesitaInfantile} onChange={(evt) => setPaziente({ ...paziente, obesitaInfantile: evt.target.value })}>
                    {
                      RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="stato">
                    Si trova in stato di
                  </Label>
                  <div className={"multi-select-profilo-paziente"} style={{ height: "auto", overflow: "auto" }}>
                    <FormGroup check>
                      <Label style={{ marginTop: 10 }} check>
                        <Input type="checkbox" checked={paziente.gravidanza} onChange={(evt) => setPaziente({ ...paziente, gravidanza: evt.target.value })} />{' '}
                        Gravidanza
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label style={{ marginTop: 10 }} check>
                        <Input type="checkbox" checked={paziente.allattamento} onChange={(evt) => setPaziente({ ...paziente, allattamento: evt.target.value })} />{' '}
                        Allattamento
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="soffrePatologie">
                    <FormGroup check>
                      <Label style={{ marginTop: 10 }} check>
                        <Input type="checkbox" checked={paziente.soffrePatologie} onChange={(evt) => setPaziente({ ...paziente, soffrePatologie: evt.target.checked, patologie: [] })} />{' '}
                        È affetto da patologie
                      </Label>
                    </FormGroup>
                  </Label>
                  <div className={"multi-select-profilo-paziente"}>
                    {PATOLOGIE.map(p => <MultiSelectCb key={p} label={p} checked={paziente.patologie.some(pa => pa === p)} onChange={() => onMultipleSelectChange(p, paziente.patologie, "patologie")} disabled={!paziente.soffrePatologie} />)}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Hai intolleranze? </Label>
                <Input type="textarea" placeholder="Indicare se ha delle intolleranze e quali..." rows={5} onChange={(evt) => setPaziente({ ...paziente, intolleranze: evt.target.value })} value={paziente.intolleranze} />
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label style={{ marginTop: 10 }} for="allergie">
                    <FormGroup check>
                      <Label style={{ marginTop: 10 }} check>
                        <Input type="checkbox" checked={paziente.soffreAllergie} onChange={(evt) => setPaziente({ ...paziente, soffreAllergie: evt.target.checked, allergie: [] })} />{' '}
                        È affetto da allergie
                      </Label>
                    </FormGroup>
                  </Label>
                  <FormGroup>
                    <div className={"multi-select-profilo-paziente"}>
                      {ALLERGIE.map(a => <MultiSelectCb key={a} label={a} checked={paziente.allergie.some(al => al === a)} onChange={() => onMultipleSelectChange(a, paziente.allergie, "allergie")} disabled={!paziente.soffreAllergie} />)}
                    </div>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Assume Farmaci</Label>
                <FormGroup>
                  <div className={"multi-select-profilo-paziente"}>
                    {FARMACI.map(f => <MultiSelectCb key={f} label={f} checked={paziente.farmaci.some(fa => fa === f)} onChange={() => onMultipleSelectChange(f, paziente.farmaci, "farmaci")} />)}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Parametri ematici e/o diagnostici di cui tener conto</Label>
                <Input type="textarea" placeholder="Note diagnostiche rilevanti del paziente" rows={5} onChange={(evt) => setPaziente({ ...paziente, diagnostica: evt.target.value })} value={paziente.diagnostica} />
              </Col>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Altre annotazioni</Label>
                <Input type="textarea" placeholder="Inserire eventuali altre annotazionii.." rows={5} onChange={(evt) => setPaziente({ ...paziente, altreAnnotazioniPatologica: evt.target.value })} value={paziente.altreAnnotazioniPatologica} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={4}>
            <Row>
              <Col md={12}>
                <Label style={{ marginTop: 15 }} >Assume Integratori</Label>
                <FormGroup>
                  <div className={"multi-select-profilo-paziente"}>
                    {INTEGRATORI.map(i => <MultiSelectCb key={i} label={i} checked={paziente.integratori.some((inte) => inte === i)} onChange={() => onMultipleSelectChange(i, paziente.integratori, "integratori")} />)}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label style={{ marginTop: 10 }} for="genitoriDiabete">
                        I tuoi genitori sono o sono mai stati affetti da diabete?
                      </Label>
                      <Input type="select" name="genitoriDiabete" id="genitoriDiabete" className="form-control"
                        value={paziente.genitoriDiabete} onChange={(evt) => setPaziente({ ...paziente, genitoriDiabete: evt.target.value })}>
                        {
                          RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label style={{ marginTop: 10 }} for="genitoriCardiovascolare">
                        I tuoi genitori sono o sono mai stati affetti da patologie Cardiovascolari?
                      </Label>
                      <Input type="select" name="genitoriCardiovascolare" id="genitoriCardiovascolare" className="form-control"
                        value={paziente.genitoriCardiovascolare} onChange={(evt) => setPaziente({ ...paziente, genitoriCardiovascolare: evt.target.value })}>
                        {
                          RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label style={{ marginTop: 10 }} for="genitoriTiroide">
                        I tuoi genitori sono o sono mai stati affetti da alterazioni tiroidee?
                      </Label>
                      <Input type="select" name="genitoriTiroide" id="genitoriTiroide" className="form-control"
                        value={paziente.genitoriTiroide} onChange={(evt) => setPaziente({ ...paziente, genitoriTiroide: evt.target.value })}>
                        {
                          RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label style={{ marginTop: 10 }} for="consumiAlcoliche">
                        Consumi bevande alcoliche?
                      </Label>
                      <Input type="select" name="consumiAlcoliche" id="consumiAlcoliche" className="form-control"
                        value={paziente.consumiAlcoliche} onChange={(evt) => setPaziente({ ...paziente, consumiAlcoliche: evt.target.value })}>
                        {
                          RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label style={{ marginTop: 10 }} for="usiBIO">
                        Usi prodotti 'BIO'?
                      </Label>
                      <Input type="select" name="usiBIO" id="usiBIO" className="form-control"
                        value={paziente.usiBIO} onChange={(evt) => setPaziente({ ...paziente, usiBIO: evt.target.value })}>
                        {
                          RISPOSTA_SI_NO.map(value => <option value={value}>{value}</option>)
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Col>
    </Row>

  </div >
}
//gestisce una risposta a due si/no del questionario utente
const RispostaQuestionarioUtente = ({ domanda, risposte, index, onChange }) => {
  return <Col md={12}>
    <Row>
      <Col md={8}>
        <Label style={{ marginTop: 10 }} className="text-bold"> {domanda}</Label>
      </Col>
      <Col md={2}>
        <FormGroup check className={"mr-10"}>
          <Label style={{ marginTop: 10 }} check>
            <Input type="checkbox" checked={risposte[0]} onClick={() => { onChange(index, true) }} /> Si
          </Label>
        </FormGroup>
      </Col>
      <Col md={2}>
        <FormGroup check className={"mr-10"}>
          <Label style={{ marginTop: 10 }} check>
            <Input type="checkbox" checked={risposte[1]} onClick={() => { onChange(index, false) }} /> No
          </Label>
        </FormGroup>
      </Col>
    </Row>
  </Col>
}