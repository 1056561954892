import React from 'react'
import './style.css'
import { CardBody, Row, Col } from 'reactstrap'
import CustomCard from '../CustomCard'

export default (props) => {
  const { value, label, icon } = props
  return <CustomCard style={{ flex: 1 }}>
    <CardBody>
      <Row>
        <Col md={12} className="box-progressi-label">
          <h5>{label}</h5>
        </Col>
        <Col md={12} className="box-progressi-value">
          <h3 className="text-bold primary-color">{value}</h3>
        </Col>
      </Row>
    </CardBody>
  </CustomCard>
}