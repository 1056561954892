import React, { useContext, useReducer, createContext, useEffect } from 'react'
import AppReducer from './reducers';
import ApiClient from '../../ApiClient';

const apiClient = new ApiClient()

//init 
export const initialState = {
  sedi: [],
  isLoading: false,
  isError: false
}

const SediContext = createContext(null)

const SediProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  function fetchInit() {
    dispatch({
      type: "FETCH_INIT",
    });
  }
  function fetchFailure() {
    dispatch({
      type: "FETCH_FAILURE",
    });
  }
  function fetchSuccess(data) {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: data
    });
  }
  async function addSede(data) {
    try {
      await apiClient.createSedeNutrizionista(data)
      dispatch({
        type: "ADD",
        payload: data
      });
      return true
    } catch (err) {
      return false;
    }
  }
  async function updateSede(data) {
    try {
      await apiClient.updateSedeNutrizionista(data)
      dispatch({
        type: "UPDATE",
        payload: data
      });
      return true
    } catch (err) {
      return false;
    }
  }
  async function removeSede(data) {
    try {
      const result = await apiClient.deleteSedeNutrizionista(data.id)
      dispatch({
        type: "REMOVE",
        payload: data
      });
      return true
    } catch (err) {
      return false
    }
  }

  //inizializzazione sedi
  useEffect(() => {
    async function fetchData() {
      fetchInit()
      try {
        const response = await apiClient.getSediNutrizionista()
        fetchSuccess(response.data)
      } catch (err) {
        fetchFailure()
      }
    }
    fetchData()
  }, [])

  return <SediContext.Provider
    value={{
      sedi: state.sedi,
      isLoading: state.isLoading,
      isError: state.isError,
      fetchFailure,
      fetchInit,
      fetchSuccess,
      addSede,
      updateSede,
      removeSede,
    }}>
    {children}
  </SediContext.Provider>
}

// Helper function to get Context
const useSedi = () => {
  const context = useContext(SediContext);
  if (!context) {
    throw new Error('useSedi must be used within a SediProvider');
  }
  return context;
}

export { SediProvider, useSedi };