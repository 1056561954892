//validazione account utente nutrizionista: password ripetuta uguale, formato password

import { UserNutrizionista } from "../types/usernutrizionista.interface";

interface OutputNutrizionista {
  isError: boolean;
  error: string;
}
const validateNutrizionista = (user: UserNutrizionista) => {
  const output: OutputNutrizionista = {
    isError: false,
    error: "",
  };
  //pattern per verificare password
  var pattern = new RegExp(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])[\w@#$%^?~-]{8,16}$/,
    "i"
  ); // fragment locater
  try {
    if (user.password === undefined) {
      throw new Error(`Attenzione: la password non è valida.`);
    }
    if (user.password !== user.repeat_password) {
      throw new Error(
        `Attenzione: le due password per l'utente ${user.username} non coincidono.`
      );
    } else if (!pattern.test(user.password)) {
      throw new Error(
        `Attenzione: la password per l'utente ${user.username} deve essere lunga tra gli 8 e i 16 caratteri e avere almeno un numero.`
      );
    }
  } catch (err: any) {
    output.isError = true;
    output.error = err.message;
  }
  return output;
};

export { validateNutrizionista };
