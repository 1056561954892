import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, } from 'reactstrap';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const getPhoneNumber = (value) => {
  return value.replace("+39", "").split(' ').join('')
}
export default ({ value, className }) => {
  return <Button className={className} type="button" outline color="success" disabled={String(value) === ""}
    onClick={() => window.open(`https://web.whatsapp.com/send?phone=39${getPhoneNumber(value)}`, "whatsapp")}>
    <FontAwesomeIcon icon={faWhatsapp} />
  </Button>
}