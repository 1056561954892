import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { SingleDatePicker } from "react-dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import ApiClient from "../../ApiClient";
import moment from "moment";
import AlertMessage from "react-s-alert";
import Table from "../../components/Table/Table";
import ITerapia from "../../types/ITerapia";
import "./style.css";

const emptyTerapia = {
  id: undefined,
  dal: moment().format("YYYY-MM-DD"),
  al: moment().format("YYYY-MM-DD"),
  dettagli: "",
  createdat: "",
  updatedat: "",
};

const ElencoTerapie = () => {
  const apiClient = new ApiClient();
  const params = useParams();
  const pid = params.id == null ? 0 : parseInt(params.id); //brutta cosa...
  const [terapie, setTerapie] = useState<ITerapia[]>([]);
  const [focusedDal, setFocusedDal] = useState(false);
  const [focusedAl, setFocusedAl] = useState(false);
  const [editingTerapia, setEditingTerapia] = useState<ITerapia>(emptyTerapia);

  const [toggleEditorTerapia, setToggleEditorTerapia] =
    useState<boolean>(false);

  const loadTerapie = async () => {
    const terapie = await apiClient.getTerapiePaziente(pid);
    setTerapie(terapie);
  };
  useEffect(() => {
    loadTerapie();
  }, []);

  const columns = [
    {
      name: "Dal",
      selector: (row: ITerapia) => moment(row.dal).format("L"),
      width: "110px",
      sortable: true,
    },
    {
      name: "Al",
      selector: (row: ITerapia) => moment(row.al).format("L"),
      width: "110px",
    },
    {
      name: "Dettagli",
      width: "auto",
      selector: (row: ITerapia) => row.dettagli,
      format: (row: ITerapia) => (
        <span style={{ whiteSpace: "unset" }}>{row.dettagli}</span>
      ),
    },
    {
      name: "Azioni",
      width: "8rem",
      selector: (row: ITerapia) => (
        <React.Fragment>
          <Button
            outline
            color="primary"
            onClick={() => startEditTerapiaModal(row)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          &nbsp;&nbsp;
          <Button outline color="danger">
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => deleteTerapia(row.id)}
            />
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const submit = async () => {
    try {
      if (editingTerapia.dal != null && editingTerapia.al != null) {
        if (editingTerapia.id == undefined) {
          //creo!
          await apiClient.creaTerapia(
            pid,
            editingTerapia.dettagli,
            editingTerapia.dal,
            editingTerapia.al
          );
        } else {
          //aggiorno!
          await apiClient.updateTerapia(
            pid,
            editingTerapia.id,
            editingTerapia.dettagli,
            editingTerapia.dal,
            editingTerapia.al
          );
        }
        AlertMessage.success("Operazione eseguita");
        loadTerapie();
        setToggleEditorTerapia(false);
      }
    } catch (err) {
      AlertMessage.error(
        "Si è verificato un errore durante l'esecuzione dell'operazione."
      );
    }
  };

  const deleteTerapia = async (terapiaid: number | undefined) => {
    if (window.confirm("Sei sicuro di voler eliminare questa terapia?")) {
      try {
        if (terapiaid == undefined) throw new Error();
        await apiClient.deleteTerapia(pid, terapiaid);
        AlertMessage.success("Terapia eliminata.");
        loadTerapie();
      } catch (err) {
        AlertMessage.error("Errore, impossibile eliminare la terapia.");
        console.log(err);
      }
    }
  };

  const renderModalTerapia = () => {
    const closeBtn = (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => setToggleEditorTerapia(false)}
      >
        <span aria-hidden="true">×</span>
      </button>
    );
    return (
      <Modal
        size="md"
        className="modal-editing-terapia"
        isOpen={toggleEditorTerapia}
        toggle={() => setToggleEditorTerapia(!toggleEditorTerapia)}
      >
        <ModalHeader
          toggle={() => setToggleEditorTerapia(!toggleEditorTerapia)}
          close={closeBtn}
        >
          {editingTerapia.id == undefined ? "Crea terapia" : "Modifica terapia"}
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={12}>
              <h6>
                Compila i seguenti dati con il periodo dal/al del trattamento, e
                inseriscine i dettagli.
              </h6>
            </Col>
            <Col md={12}>
              <Label for="dal">Dal</Label>
            </Col>
            <Col md={12}>
              <SingleDatePicker
                id="dal"
                enableOutsideDays
                small
                numberOfMonths={1}
                date={moment(editingTerapia.dal, "YYYY-MM-DD")}
                onDateChange={(data: any) => {
                  setEditingTerapia({
                    ...editingTerapia,
                    dal: moment(data).format("YYYY-MM-DD"),
                  });
                }} // PropTypes.func.isRequired*/
                focused={focusedDal} // PropTypes.bool
                onFocusChange={(evt) => {
                  let focused: any = evt.focused;
                  setFocusedDal(focused);
                }} // PropTypes.func.isRequired
              />
            </Col>
            <br />
            <br />
            <Col md={12}>
              <Label for="al">Al</Label>
            </Col>
            <Col md={12}>
              <SingleDatePicker
                id="al"
                enableOutsideDays
                small
                numberOfMonths={1}
                date={moment(editingTerapia.al, "YYYY-MM-DD")}
                onDateChange={(data: any) => {
                  setEditingTerapia({
                    ...editingTerapia,
                    al: moment(data).format("YYYY-MM-DD"),
                  });
                }} // PropTypes.func.isRequired*/
                focused={focusedAl} // PropTypes.bool
                onFocusChange={(evt) => {
                  let focused: any = evt.focused;
                  setFocusedAl(focused);
                }} // PropTypes.func.isRequired
              />
            </Col>
            <br />
            <br />
            <Col md={12}>
              <FormGroup>
                <Label>Dettagli</Label>
                <Input
                  type="textarea"
                  rows={5}
                  value={editingTerapia.dettagli}
                  onChange={(evt) =>
                    setEditingTerapia({
                      ...editingTerapia,
                      dettagli: evt.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submit}>
            Salva
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setToggleEditorTerapia(!toggleEditorTerapia)}
          >
            Annulla
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const startNuovaTerapiaModal = () => {
    setEditingTerapia(emptyTerapia);
    setToggleEditorTerapia(true);
  };

  const startEditTerapiaModal = (terapia: ITerapia) => {
    setEditingTerapia({ ...terapia });
    setToggleEditorTerapia(true);
  };

  return (
    <div className="animated fadeIn">
      {renderModalTerapia()}
      <Row>
        <Col md={9}>
          <h4>Terapie</h4>
        </Col>
        <Col md={3}>
          <Button
            style={{ float: "right" }}
            outline
            color="primary"
            onClick={startNuovaTerapiaModal}
          >
            <FontAwesomeIcon icon={faListUl} />
            &nbsp;Nuova
          </Button>
        </Col>
      </Row>
      <hr />
      <Table data={terapie} columns={columns} />
    </div>
  );
};

export default ElencoTerapie;
