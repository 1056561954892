import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Collapse, Button, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown, faAngleUp, faPlusCircle, faTimesCircle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

class PastiDieta extends Component {

  state = {
    collapse: Array(this.props.pasti.length).map(item => false)
  }

  toggle = (k) => {
    const { collapse } = this.state
    collapse[k] = !collapse[k]
    this.setState({ collapse })
  }

  printHours = (k, setHour) => {
    const { disabled } = this.props
    return <Input type="select" className="form-control" disabled={disabled} value={setHour} onChange={(evt) => this.changeHourPasto(k, evt.target.value)}>
      {Array(24).fill(0).map((item, k) => <option key={k} value={String(k + 1).padStart(2, "0")}>{String(k + 1).padStart(2, "0")}</option>)}
    </Input>
  }
  printMinutes = (k, setMinutes) => {
    const { disabled } = this.props
    return <Input type="select" className="form-control" disabled={disabled} value={setMinutes} onChange={(evt) => this.changeMinutePasto(k, evt.target.value)}>
      <option value={"00"}>00</option>
      <option value={"15"}>15</option>
      <option value={"30"}>30</option>
      <option value={"45"}>45</option>
    </Input>
  }

  //rearrange items in array pasto
  rearrangePasto = (k, newK) => {
    const { collapse } = this.state
    const tmp = collapse[k]
    collapse[k] = collapse[newK]
    collapse[newK] = tmp
    this.setState({ collapse })
    this.props.rearrangePasto(this.props.giornata, k, newK)
  }

  changeTipoPasto = (k, name) => {
    this.props.changeTipoPasto(this.props.giornata, k, name)
  }

  changeComponentePasto = (k, j, c) => {
    this.props.changeComponentePasto(this.props.giornata, k, j, c)
  }

  removeComponentePasto = (k, j) => {
    this.props.removeComponentePasto(this.props.giornata, k, j)
  }

  changeHourPasto = (k, ore) => {
    //chiudo prima tutti i pasti
    this.setState({
      collapse: Array(this.props.pasti.length).map(item => false)
    }, () => this.props.changeHourPasto(this.props.giornata, k, ore))
  }

  changeTestoPasto = (k, testo) => {
    this.props.changeTestoPasto(this.props.giornata, k, testo)
  }

  addPasto = () => {
    const { collapse } = this.state
    collapse.push(false)
    this.setState({ collapse })
    this.props.addPasto(this.props.giornata)
  }

  changeMinutePasto = (k, min) => {
    //chiudo prima tutti i pasti
    this.setState({
      collapse: Array(this.props.pasti.length).map(item => false)
    }, () => this.props.changeMinutePasto(this.props.giornata, k, min))
  }

  removePasto = (k,) => {
    const { collapse } = this.state
    collapse.splice(k, 1);
    this.setState({ collapse })
    this.props.removePasto(this.props.giornata, k)
  }

  renderPasto = ({ pasto, ora, minuti, fatto, testo }, collapse, k,) => {
    const { disabled } = this.props
    //const collapse = this.state.pasti[k].collapse
    return <Col md={12} key={k}>
      <div className="boxPasto cursorPointer">
        <div className="flexIt ">
          <h4 onClick={() => this.toggle(k)}>
            <FontAwesomeIcon style={{ minWidth: 20 }} icon={collapse ? faAngleDown : faAngleRight} />&nbsp; {pasto}
          </h4>
          <span className={"flexIt"}>
            {
              fatto && <span className="mr-10 d-flex align-items-center justify-content-between">
                <FontAwesomeIcon icon={faThumbsUp} />&nbsp;Fatto
              </span>
            }
            {this.printHours(k, ora)}
            &nbsp;
            {this.printMinutes(k, minuti)}
            <span className={"flexIt"} className="ml-10">
              <Button outline color="danger" style={{ height: 40, width: 40 }} onClick={() => this.removePasto(k)} >
                <FontAwesomeIcon style={{ fontSize: 20 }} className={"cursorPointer"} icon={faTimesCircle} />
              </Button>
            </span>
          </span>
        </div>
        <Collapse isOpen={collapse}>
          <hr />
          <div className="mb-10">
            Tipologia pasto:&nbsp;
              <Input type="select" className="form-control" disabled={disabled} value={pasto} onChange={(evt) => this.changeTipoPasto(k, evt.target.value)}>
              <option value="Colazione">Colazione</option>
              <option value="Pranzo">Pranzo</option>
              <option value="Spuntino">Spuntino</option>
              <option value="Cena">Cena</option>
            </Input>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Input type="textarea" rows={5} disabled={disabled} onChange={(evt) => this.changeTestoPasto(k, evt.target.value)} style={{ width: '100%' }} value={testo} />
          </div>
        </Collapse>
      </div>
    </Col>
  }

  render() {
    const { pasti, disabled } = this.props
    const { collapse } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          {pasti.map((pasto, k) => this.renderPasto(pasto, collapse[k], k))}
        </Row>
        <Row>
          <Col md={12} className={"box-add-pasto d-flex align-items-center justify-content-center"}>
            {!disabled &&
              <Button color="primary" onClick={this.addPasto} >
                <FontAwesomeIcon icon={faPlusCircle} />&nbsp; Aggiungi pasto
              </Button>
            }
          </Col>
        </Row>
      </div>
    )
  }
}
PastiDieta.defaultProps = {
  disabled: false
}
export default PastiDieta;

