import React, { Component } from 'react';
import Controller from './Controller';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert, Spinner, Card, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import Riepilogo from './Riepilogo'
import SchedaPaziente from './Scheda'
import ElencoVisite from '../ElencoVisite/View'
import ElencoDiete from './ElencoDiete'
import Appuntamenti from './Appuntamenti'
import Notifica from './Notifica'
import ConsigliNutrizionali from './ConsigliNutrizionali'
import ModalNewAppAccount from '../../components/ModalNewAppAccount/ModalNewAppAccount';
import { withRouter } from '../../helper';
import moment from 'moment';
import RiepilogoSchedaPaziente from '../../components/RiepilogoSchedaPaziente';
import Documenti from '../../components/Documenti/Documenti';
import Progressi from './Progressi';
import ElencoTerapie from './ElencoTerapie';

class View extends Component {

  state = {
    activeTab: 0,
    modalNewAppAccountIsOpen: false,
  }

  componentDidMount = () => {
    //se è indicato un anchor, metto tab relativo
    const tabIndex = this.schedeProfilo.findIndex(s => s.anchor != '' && window.location.hash != undefined && window.location.hash.includes(s.anchor))
    this.setState({ activeTab: tabIndex === -1 ? 0 : tabIndex })
  }

  toggle = activeTab => {
    this.setState({ activeTab });
  }

  toggleModalNewAppAccountIsOpen = () => this.setState({ modalNewAppAccountIsOpen: !this.state.modalNewAppAccountIsOpen })

  eliminaPaziente = () => {
    if (window.confirm("Sei sicuro di voler eliminare questo paziente?")) {
      this.props.eliminaPaziente()
    }
  }

  loadPaziente = () => this.props.loadPaziente()

  schedeProfilo = [
    { anchor: "#riepilogo", name: "Riepilogo", Component: Riepilogo },
    { anchor: "#progressi", name: "Progressi", Component: Progressi },
    { anchor: "#anamnesi", name: "Anamnesi", Component: RiepilogoSchedaPaziente },
    { anchor: "#documenti", name: "Documenti", Component: Documenti },
    { anchor: "#visite", name: "Visite", Component: ElencoVisite },
    { anchor: "#diete", name: "Diete", Component: ElencoDiete },
    { anchor: "#terapie", name: "Terapie", Component: ElencoTerapie },
    { anchor: "#appuntamenti", name: "Appuntamenti", Component: Appuntamenti },
    { anchor: "#datipersonali", name: "Dati Personali", Component: SchedaPaziente },
    { anchor: "#consigli", name: "Consigli Nutrizionali", Component: ConsigliNutrizionali },
    { anchor: "#notifiche", name: "Invia Notifica", Component: Notifica },
  ]

  render() {
    const { error, loading, paziente } = this.props.state
    const { has_app_account, lastappaccess, hadietaattiva } = paziente
    const { activeTab, modalNewAppAccountIsOpen } = this.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <span class="flexIt">
              <strong style={{ flex: 1 }}>
                Profilo di <strong>{paziente.nome + " " + paziente.cognome}</strong>
              </strong>
              <span class="flexIt">
                <Button color="primary" outline onClick={() => this.props.router.navigate(`/users`)}>Indietro</Button>
                &nbsp;&nbsp;
                <Button color="danger" onClick={this.eliminaPaziente}> Elimina</Button>
              </span>
            </span>
            <hr />
            {lastappaccess == null && <Alert className={"width-100p"} color="danger">Il paziente non ha mai acceduto all'app. </Alert>}
            {lastappaccess != null && moment(lastappaccess) < moment().subtract('60', 'days') && <Alert className={"width-100p"} color="warning">Il paziente non accede all'app dal {new moment(lastappaccess).format('DD/MM/YYYY, HH:mm')}. </Alert>}
            {lastappaccess != null && moment(lastappaccess) >= moment().subtract('60', 'days') && <Alert className={"width-100p"} color="info">Ultimo accesso all'app: {moment(lastappaccess).format('DD/MM/YYYY, HH:mm')}. </Alert>}
            
            {!hadietaattiva && <Alert className={"width-100p"} color="danger">Il paziente non ha una dieta attiva. </Alert>}
            
            {!has_app_account &&
              <div className="mb-10">
                <Alert color="warning" className="d-flex align-items-center justify-content-between">
                  <div>
                    <b>{paziente.nome + " " + paziente.cognome}</b> non ha ancora l'app SìDiet! Crea subito un account per poter beneficiare di tantissime funzionalità aggiuntive.
                  </div>
                  <Button color="primary" onClick={this.toggleModalNewAppAccountIsOpen}>Crea account</Button>
                </Alert>
                <ModalNewAppAccount onSubmitSuccess={this.loadPaziente} isOpen={modalNewAppAccountIsOpen} toggle={this.toggleModalNewAppAccountIsOpen} pid={this.props.router.params.id} />
              </div>
            }
            <Nav tabs horizontal={"end"}>
              {
                this.schedeProfilo.map((item, k) =>
                  <NavItem key={item.name}>
                    <NavLink
                      className={classnames({ active: activeTab === k })}
                      onClick={() => { this.toggle(k); }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>
            <TabContent activeTab={activeTab}>

              {
                this.schedeProfilo.map(({ Component }, k) =>
                  <TabPane tabId={k} key={k}>
                    <Component newPaziente={false} pid={this.props.router.params.id} paziente={this.props.state.paziente} {...this.props} />
                  </TabPane>
                )
              }
            </TabContent>
          </CardBody>
        </Card>

      </div>
    )
  }
}

export default withRouter(Controller(View));