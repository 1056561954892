import React, { Component } from 'react';
import { Col, Row, Button, Popover, PopoverHeader, PopoverBody, Input, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faBell, faCheck, } from '@fortawesome/free-solid-svg-icons'
import ApiClient from '../../ApiClient'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates';
import Alert from 'react-s-alert';
import Table from '../../components/Table/Table';

class Dashboard extends Component {
  apiClient = new ApiClient()

  state = {
    appuntamenti: [],
    giornoAppuntamenti: moment(),
    dpFocused: false,
    popoverNotificaOpen: false,
    notificaText: "",
    pazientiPerNotifica: [],
  }


  componentDidMount() {
    this.loadAppuntamenti()
  }

  togglePopoverNotifica = () => this.setState({ popoverNotificaOpen: !this.state.popoverNotificaOpen })

  loadAppuntamenti = async () => {
    const { giornoAppuntamenti, appuntamenti } = this.state
    try {
      const response = await this.apiClient.getAppuntamentiByDay(giornoAppuntamenti.format('YYYY'), giornoAppuntamenti.format('MM'), giornoAppuntamenti.format('DD'))
      this.setState({
        appuntamenti: response.data,
      })
    } catch (err) {
      console.log(err)
      this.setState({ appuntamenti })
    }
  }

  segnaArrivato = async (idappuntamento, pid) => {
    try {
      await this.apiClient.segnaArrivatoAppuntamento(pid, idappuntamento)
      this.loadAppuntamenti()
    } catch (err) {
      Alert.error("Errore, impossibile completare l'operazione.")
      console.log(err)
    }
  }

  columns = [
    {
      name: 'Nome',
      selector: row => row.cognome + " " + row.nome
    },
    {
      name: 'Orario',
      selector: row => moment(row.data).format("HH:mm"),
      width: "85px"
    },
    {
      name: 'Motivo',
      selector: row => row.motivo
    },
    {
      name: 'Azioni',
      selector: row =>
        <Button key={row.id} id={"UncontrolledTooltip" + row.id} disabled={row.arrivato == 'y'} color={"primary"} outline onClick={() => this.segnaArrivato(row.id, row.paziente)}>
          <UncontrolledTooltip
            placement="top"
            target={"UncontrolledTooltip" + row.id}
          >
            Segna come arrivato
          </UncontrolledTooltip>
          <FontAwesomeIcon icon={faThumbsUp} />
        </Button>,
      width: "85px"
    }
  ]

  sendNotifica = async () => {
    const { pazientiPerNotifica, notificaText } = this.state
    for (const paziente of pazientiPerNotifica) {
      try {
        await this.apiClient.sendNotifica(paziente, { message: notificaText })
      } catch (err) { }
    }
    this.setState({ notificaText: "", popoverNotificaOpen: false })
    Alert.success("Notifiche inviate!")
  }

  handleChange = (payload) => {
    this.setState({ pazientiPerNotifica: [...payload.selectedRows.map(a => a.paziente)] })
  }

  render() {
    const { appuntamenti, giornoAppuntamenti, dpFocused, popoverNotificaOpen, notificaText, pazientiPerNotifica } = this.state
    return <React.Fragment>
      <Row>
        <Col md={12} className="d-flex align-items-center justify-content-between">
          <h4>Appuntamenti</h4>
          <div className="d-flex align-items-center justify-content-between">
            <Button className="mr-10" id="popoverNotifica" disabled={pazientiPerNotifica.length == 0} size="sm" color={"primary"} outline><FontAwesomeIcon icon={faBell} />&nbsp;Notifica</Button>
            <Popover placement="bottom" isOpen={popoverNotificaOpen} target="popoverNotifica" toggle={this.togglePopoverNotifica}>
              <PopoverHeader>Notifica</PopoverHeader>
              <PopoverBody>
                <Input type="textarea" placeholder="Invia una notifica ai pazienti in elenco" rows={5} onChange={(evt) => this.setState({ notificaText: evt.target.value })} value={notificaText} />
                <br />
                <Button disabled={pazientiPerNotifica.length == 0 || notificaText.length == 0} color={"primary"} block onClick={this.sendNotifica}>Invia</Button>
              </PopoverBody>
            </Popover>
            <SingleDatePicker
              isOutsideRange={() => false}
              numberOfMonths={1}
              showDefaultInputIcon
              date={moment(giornoAppuntamenti)} // momentPropTypes.momentObj or null
              onDateChange={data => this.setState({ giornoAppuntamenti: data }, this.loadAppuntamenti)} // PropTypes.func.isRequired
              focused={dpFocused} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ dpFocused: focused })} // PropTypes.func.isRequired
              id="giornoAppuntamenti"
              small
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Table columns={this.columns} data={appuntamenti}
        selectableRows={true}
        onSelectedRowsChange={this.handleChange}
      />
    </React.Fragment>
  }
}

export default Dashboard;

