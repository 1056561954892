import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

// This function takes a component...
export default function Controller(WrappedComponent, ) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()

    state = {
      diete: [],
      loading: true,
      error: false,

    }

    componentDidMount() {
      this.loadDiete()
    }

    loadDiete = async () => {
      try {
        const response = await this.apiClient.getAllDieteTemplate()
        const diete = response.data
        this.setState({
          diete,
          loading: false,
          error: false
        })
      } catch (err) {
        this.setState({
          loading: false,
          error: true
        })
      }
    }

    elimina = async (idTemplate) => {
      if (window.confirm("Sei sicuro di voler eliminare questa dieta template?")) {
        try {
          await this.apiClient.deleteDietaTemplate(idTemplate)
          Alert.success("Dieta eliminata!");
          this.loadDiete()
        } catch (err) {
          Alert.error("Errore, impossibile eliminare la dieta.");
        }
      }
    }

    render() {
      return <WrappedComponent
        {...this.props}
        elimina={this.elimina}
        state={this.state}
      />
    }
  }
}
