import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Form, FormGroup, Input, Label, Button, Alert, Spinner, } from 'reactstrap'
import ApiClient from '../../ApiClient'
import AlertMessage from 'react-s-alert';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'
import { updateSchema, createSchema, createSchemaWithAppAccount } from "../../validators/paziente"
import { useStats } from '../../state/stats';
import { MAIL_CONTACT } from "../../constants"
import EmailToButton from '../../components/EmailToButton';
import WhatsAppToButton from '../../components/WhatsAppToButton';
import MyInput from '../../components/MyInput';
import { withRouter } from '../../helper';

//lista farmaci
export const FARMACI = [
  "Agenti chelanti",
  "Agenti respiratori",
  "Analgesici",
  "Anoressizzanti",
  "Antiaritmici",
  "Antiasmatici",
  "Antibiotici",
  "Antidiabetici",
  "Antidolorifici",
  "Antidiarroici",
  "Antidepressivi",
  "Antiemorragici",
  "Antiipertensivi",
  "Antiipotensivi",
  "Antimicotici",
  "Antinfiammatori",
  "Antireumatici",
  "Antivirali",
  "Chemioterapici ",
  "Contraccettivi ",
  "Inibitori",
  "Lassativi",
  "Ormonali",
  "Statine",
  "Altro",
]
//lista integratori
export const INTEGRATORI = [
  "Prebiotici",
  "Effetto lassativo",
  "Funzionalità gastrica",
  "Funzionalità epatica",
  "Drenanti",
  "Ipoglicemizzanti",
  "Trattamento dislipidemie",
  "Energizzanti",
  "Sali minerali",
  "Multivitaminici",
  "Dimagranti",
  "Insufficienza venosa e microcircolo",
  "Amminoacidi e proteine",
  "Altro"
]
//allergie
export const ALLERGIE = [
  "Parietaria",
  "Graminacee",
  "Acaro della polvere",
  "Nichel",
  "Glutine",
  "Uovo",
  "Latte",
  "Frutta secca",
  "Altro",
]
//patologie
export const PATOLOGIE = [
  "CALCOLOSI RENALE",
  "STEATOSI EPATICA",
  "CALCOLI COLESTISTICA",
  "INFARTO MIOCARDIO",
  "APNEA NOTTURNA",
  "CRAMPI MUSCOLARI FREQUENTI",
  "DIABETE DI TIPO I",
  "DIABETE DI TIPO II",
  "DISLIPIDEMIA",
  "REFLUSSO GASTROESOFAGEO",
  "VOMITO O DIARREA FREQUENTI",
  "STIPSI",
  "NEOPLASIE PREGRESSE",
  "PREGRESSI INTERVENTI CHIRURGICI",
  "PATOLOGIE TIROIDEE",
  "IPERTENSIONE ARTERIOSA IN TERAPIA FARMACOLOGICA",
  "ALTRO",
]

export const DIETA_PARTICOLARE = [
  "-",
  "NO",
  "VEGETARIANA",
  "VEGANA",
  "PER MOTIVI RELIGIOSI"
]

export const ALVO = [
  "-",
  "REGOLARE",
  "STIPSI - Lieve (giorni alterni)",
  "STIPSI - Media (1 o 2 volte a settimana)",
  "STIPSI - Grave (meno di una volta a settimana)",
  "DIARREA IN OGNI CASO"
]

export const CICLO = [
  "-",
  "REGOLARE",
  "ASSENTE",
  "AMENORREA",
  "MENOPAUSA"
]

export const QTA_ACQUA = [
  "-",
  "MENO DI 0.5L",
  "TRA 0.5L e 1.5L",
  "TRA 1L e 2L",
  "BEVE 2L"
]

export const RISPOSTA_SI_NO = [
  "-",
  "SI",
  "NO"
]

export const ATTIVITA_SPORTIVA_WEEK = ["LUN", "MAR", "MER", "GIO", "VEN", "SAB", "DOM"];// ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"];

export const DEFAULT_QUESTIONARIO_NUTRIZIONISTA = {
  farmaci: [],
  integratori: [],
  soffreAllergie: false,
  allergie: [],
  soffreStipsi: false,
  consumaCarboidrati: "-",
  consumaFruttaSecca: "-",
  diagnostica: "",
  dietaParticolare: "-",
  stipsi: "Lieve",
  gravidanza: false,
  allattamento: false,
  soffrePatologie: false,
  patologie: [],
  obiettivo: "",
  attivitaLavorativa: "",
  motivoVisita: "",
  intolleranze: "",
  consumaFrutta: "",
  consumaDolci: "",
  saltaPasti: "",
  pranziSacco: "",
  alimentiGraditi: "",
  alimentiNonGraditi: "",
  alimentiNonMancano: "",
  obesitaInfantile:"-",
  genitoriDiabete:"-",
  genitoriCardiovascolare:"-",
  genitoriTiroide:"-",
  consumiAlcoliche:"-",
  usiBIO:"-",
  cereali: "",
  svolgeAttivitàFisica: "",
  anamnesiFamiliare: [
    { periodo: "", familiare: "", diagnosi: "", appunti: "" },
    { periodo: "", familiare: "", diagnosi: "", appunti: "" },
    { periodo: "", familiare: "", diagnosi: "", appunti: "" },
    { periodo: "", familiare: "", diagnosi: "", appunti: "" },
  ],
  attivitaFisica: ATTIVITA_SPORTIVA_WEEK.map(e => { return { day: e, enabled: false, note: "" } }),
  persoPeso: "",
  pastiSostitutivi: "",
  altreAnnotazioniAlimenti: "",
  altreAnnotazioniPatologica: "",
  oreSonno: "",
  doveConsuma: "",
  numeroPasti: "",
  alvo: "-",
  qtaAcqua: "-",
  ciclo: "-"
}
//messaggio che avvisa che ci sono pochi pazienti rimanenti creabili
export const AlertPazientiRimanenti = () => {
  const { data, } = useStats()
  if (data.pazienti_rimanenti === 0) {
    return <div className="d-flex justify-content-center align-items-center width-100p">
      <Alert className={"width-100p"} color="danger">ATTENZIONE: non puoi più registrare account pazienti.
        Contattaci a <a href={`mailto:${MAIL_CONTACT}`}>{MAIL_CONTACT}</a> per acquistarne altri. </Alert>
    </div>
  } else if (data.pazienti_rimanenti <= 10) {
    return <div className="d-flex justify-content-center align-items-center width-100p">
      <Alert className={"width-100p"} color="warning">ATTENZIONE: hai solo {data.pazienti_rimanenti} account pazienti rimanenti.</Alert>
    </div>
  }
  else return null
}

//elemento checkbox+label per scheda pazienti (farmaci, patologie, ...)
export const MultiSelectCb = ({ label, onChange, checked, disabled = false }) => {
  return <FormGroup check>
    <Label check>
      <Input type="checkbox" onChange={onChange} disabled={disabled} checked={checked} />{' '}
      {label}
    </Label>
  </FormGroup>
}

class Scheda extends Component {

  apiClient = new ApiClient()

  //TODO: refactor dello stato, raccogliendo gli attributi di paziente
  state = {
    id: "",
    nome: "",
    cognome: "",
    username: "",
    password: "",
    sesso: null,
    email: "",
    telefono1: "",
    datanascita: null,
    viaresidenza: "",
    cittaresidenza: "",
    //singledastepicker
    focused: false,
    loading: true,
    error: false,
    questionario_nutrizionista: { ...DEFAULT_QUESTIONARIO_NUTRIZIONISTA },
    validationErrors: [],
    //regola se l'input della password deve essere visibile o no
    type: "password",
    has_app_account: false,
  }

  componentDidMount() {
    if (!this.props.newPaziente) {
      this.loadPaziente()
    } else {
      this.setState({
        loading: false
      })
    }
  }

  loadPaziente = async () => {
    try {
      const { pid } = this.props
      const paziente = await this.apiClient.getPazienteById(pid)
      paziente.data.datanascita = moment(paziente.data.datanascita, 'DD/MM/YYYY')
      if (paziente.data.questionario_nutrizionista == undefined) {//ha i dati del questionario!
        paziente.data.questionario_nutrizionista = {
          ...DEFAULT_QUESTIONARIO_NUTRIZIONISTA
        }
      }
      this.setState({
        ...paziente.data,
      })
    } catch (err) {
      this.setState({
        error: true
      })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  submit = async (evt) => {
    evt.preventDefault()
    let { id, nome, cognome, username, sesso, password, email, cittaresidenza, viaresidenza, datanascita, telefono1, has_app_account, questionario_nutrizionista } = { ...this.state }
    //preparo data
    datanascita = moment(datanascita, 'DD/MM/YYYY').format('L')
    //preparo oggetto, che dipenderà dal fatto che sia richiesto anche l'account app
    let paziente = { nome, cognome, sesso, email, cittaresidenza, viaresidenza, datanascita, telefono1, has_app_account, questionario_nutrizionista }

    if (id === "") {
      if (has_app_account) {
        paziente = {
          ...paziente,
          username, password,
        }
      }
      //validation
      //lo schema di validazione dipende da se creare o meno anche l'account app
      const validator = has_app_account ? createSchemaWithAppAccount : createSchema;
      const { error } = validator.validate(paziente, { abortEarly: false })
      if (error) {//dati non validi
        AlertMessage.error("Errore, alcuni campi non sono compilati correttamente.")
        this.setState({
          validationErrors: error.details
        })
      } else {//validation ok
        this.createPaziente(paziente)
        this.setState({
          validationErrors: []
        })
      }
    } else {
      //preparo oggetto
      paziente = { ...paziente, id }
      //validation
      const { error } = updateSchema.validate(paziente, { abortEarly: false })
      if (error) {//dati non validi
        AlertMessage.error("Errore, alcuni campi non sono compilati correttamente.")
        this.setState({
          validationErrors: error.details
        })
      } else {//validation ok
        this.updatePaziente(paziente)
        this.setState({
          validationErrors: []
        })
      }
    }
  }

  //gestisce la selezione multipla nelle select multiple
  onMultipleSelectChange = (value, arr, arrName) => {
    //se il value cliccato è gia nell'array, lo rimuovo (deselezione), se non c'è lo inserisco (selezione)
    if (arr.some(a => a == value)) {//ha dentro l'elemento!
      arr = arr.filter(a => a != value)
    } else {//altrimenti, aggiungo ai selezionati
      arr.push(value)
    }
    const state = { ...this.state }
    state[arrName] = arr
    this.setState(state)
  }

  async createPaziente(body) {
    try {
      const response = await this.apiClient.createPaziente(body)
      if (response.success) {
        AlertMessage.success("Utente creato!")
        this.props.router.navigate(`/users/${response.data}`)
      } else {
        throw new Error("fallito")
      }
    }
    catch (err) {
      //controllo se c'è stato un errore di conflitto su email/username
      if (err.response && err.response.status !== 500) {
        AlertMessage.error(err.response.data.error)
      } else {
        AlertMessage.error("Errore, impossibile creare l'utente.")
      }
    }
  }


  async updatePaziente(body) {
    try {
      const response = await this.apiClient.updatePaziente(body)
      if (response.success)
        AlertMessage.success("Utente modificato!")
      else
        throw new Error("fallito")
    } catch (err) {
      AlertMessage.error("Errore, impossibile modificare l'utente.")
    }

  }

  //funzioni per permettere la selezione di mese e anno nel datepicker
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <Input
          type="select"
          className="form-control"
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </Input>
      </div>
      <div>
        <Input type="select" className="form-control" value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {Array(100).fill(0).map((e, i) => <option key={e} value={moment().year() - i}>{moment().year() - i}</option>)}
        </Input>
      </div>
    </div>

  renderForm = () => {
    const { nome, cognome, username, password, email, sesso, datanascita, telefono1, viaresidenza, cittaresidenza, validationErrors,
      focused, has_app_account } = this.state
    return <React.Fragment>
      {this.props.newPaziente && <AlertPazientiRimanenti />}
      <Form onSubmit={this.submit} >
        <Row>
          <Col md={8}>
            {this.props.newPaziente && <h4>Nuovo Paziente</h4>}
          </Col>
          <Col md={4} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" outline color="primary">Salva</Button>
            &nbsp;
            {
              this.props.newPaziente &&
              <Button type="reset" color="primary" outline onClick={() => this.props.router.navigate(`/users`)}>Indietro</Button>
            }
          </Col>
        </Row>
        <hr />
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="nome">Nome</Label>
              <MyInput validation={validationErrors} type="text" name="nome" onChange={(evt) => this.setState({ nome: evt.target.value })} value={nome} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cognome">Cognome</Label>
              <MyInput validation={validationErrors} type="text" name="cognome" onChange={(evt) => this.setState({ cognome: evt.target.value })} value={cognome} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="sesso">Sesso</Label>
              <MyInput validation={validationErrors} type="select" className="form-control" name="sesso" onChange={(evt) => this.setState({ sesso: evt.target.value })} value={sesso} >
                <option value="" selected>Seleziona</option>
                <option value="Uomo">Uomo</option>
                <option value="Donna">Donna</option>
              </MyInput>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <div className="d-flex align-items-start justify-content-start">
                <MyInput
                  validation={validationErrors}
                  type="email"
                  name="email"
                  onChange={(evt) => this.setState({ email: evt.target.value })}
                  value={email} />
                <EmailToButton value={email} className="ml-10" />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="datanascita">Data di Nascita</Label>
              <MyInput noInput={true} name="datanascita" validation={validationErrors}>
                <SingleDatePicker
                  placeholder={"(gg/mm/aaaa)"}
                  isOutsideRange={() => false}
                  small
                  numberOfMonths={1}
                  block
                  date={datanascita} // momentPropTypes.momentObj or null
                  onDateChange={datanascita => {
                    if (moment(datanascita).isValid())
                      this.setState({ datanascita })
                  }} // PropTypes.func.isRequired
                  focused={focused} // PropTypes.bool
                  renderMonthElement={this.renderMonthElement}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                />
              </MyInput>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cittaresidenza">Città di Residenza</Label>
              <MyInput validation={validationErrors} type="text" name="cittaresidenza" onChange={(evt) => this.setState({ cittaresidenza: evt.target.value })} value={cittaresidenza} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="viaresidenza">Via di Residenza</Label>
              <MyInput validation={validationErrors} type="text" name="viaresidenza" onChange={(evt) => this.setState({ viaresidenza: evt.target.value })} value={viaresidenza} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="telefono1">Telefono</Label>
              <div className="d-flex align-items-start justify-content-between">
                <MyInput validation={validationErrors} type="text" name="telefono1" onChange={(evt) => this.setState({ telefono1: evt.target.value })} value={telefono1} />
                <WhatsAppToButton value={telefono1} className="ml-10" />
              </div>
            </FormGroup>
          </Col>
          <Col md={6} />
          {(this.props.newPaziente || (!this.props.newPaziente && has_app_account)) &&
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <hr />
                </Col>
                {
                  this.props.newPaziente &&
                  <Col md={12}>
                    <Label>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" checked={has_app_account} onChange={(evt) => this.setState({ has_app_account: evt.target.checked })} />{' '}
                          Crea account App
                        </Label>
                      </FormGroup>
                    </Label>
                  </Col>
                }
                <Col md={6}>
                  <FormGroup>
                    <Label for="username">Username</Label>
                    <MyInput validation={validationErrors} type="text" disabled={!has_app_account || !this.props.newPaziente} autoComplete="new-password" name="username" onChange={(evt) => this.setState({ username: evt.target.value })} value={username} />
                  </FormGroup>
                </Col>
                {
                  this.props.newPaziente ?
                    <Col md={6}>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <div className="d-flex align-items-start justify-content-between">
                          <MyInput validation={validationErrors} type={this.state.type} disabled={!has_app_account} autoComplete="new-password" name="password" onChange={(evt) => this.setState({ password: evt.target.value })} value={password} />
                          {this.state.type == "password" ?
                            <Button className="ml-10" color="primary" disabled={!has_app_account} onClick={() => this.setState({ type: "input" })}>Mostra</Button>
                            :
                            <Button className="ml-10" color="primary" onClick={() => this.setState({ type: "password" })}>Nascondi</Button>
                          }
                        </div>
                      </FormGroup>
                    </Col>
                    :
                    <Col md={6} />
                }
                <Col md={12}>
                  <hr />
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </Form>
    </React.Fragment>
  }


  render() {
    const { error, loading, } = this.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        {this.props.newPaziente ?
          <Card>
            <CardBody>
              {this.renderForm()}
            </CardBody>
          </Card>
          : this.renderForm()
        }
      </div>
    )
  }
}

Scheda.defaultProps = {
  newPaziente: true
}

export default withRouter(Scheda);

