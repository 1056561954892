import moment from 'moment'

const validateAppuntamento = (appuntamento) => {
  const output = {
    isError: false,
    error: ""
  }
  //pattern per verificare che linkvc sia valido
  var pattern = new RegExp(/^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/, 'i'); // fragment locater
  try {
    if (appuntamento.paziente === null) {
      throw new Error("Attenzione, indicare il paziente dell'appuntamento.")
    } else if (!moment(appuntamento.data).isValid()) {
      throw new Error('Attenzione, la data di inizio evento non è valida.')
    } else if (!moment(appuntamento.data_end).isValid()) {
      throw new Error('Attenzione, la data di fine evento non è valida.')
    } else if (moment(appuntamento.data) > moment(appuntamento.data_end)) {
      throw new Error("Attenzione, l'orario di fine appuntamento non può essere precedente all'orario di inizio appuntamento.")
    } else if (appuntamento.linkvc !== undefined && appuntamento.linkvc !== null && appuntamento.linkvc !== "" && !pattern.test(appuntamento.linkvc)) {
      throw new Error("Attenzione, il link alla videochiamata non è valido.")
    }
  } catch (err) {
    output.isError = true
    output.error = err.message
  }
  return output
}

export { validateAppuntamento }