import { faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, } from 'reactstrap';
const validateEmail = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export default ({ value, className }) => {
  return <Button className={className} type="button" outline color="primary" disabled={!validateEmail(value)} onClick={() => window.open(`mailto:${value}`)}>
    <FontAwesomeIcon icon={faAt} />
  </Button>
}