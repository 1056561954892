import React, { Component } from 'react';
import { Card, CardBody, Col, Row, } from 'reactstrap'
import Appuntamenti from './Appuntamenti'
import ApiClient from '../../ApiClient'
import { WidgetPazienti, ChartAppuntamenti } from '../../components/StatsNutrizionista';

class Dashboard extends Component {
  apiClient = new ApiClient()

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <WidgetPazienti />
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <Appuntamenti />
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardBody style={{ width: "100%", height: "40vh" }}>
                <ChartAppuntamenti />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div >
    )
  }
}

export default Dashboard;

