import { cilList, cilSpeedometer, cilUser, cilCalendar } from "@coreui/icons";

export default {

  //menu per un utente nutrizionista
  nutrizionista: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: cilSpeedometer
      },
      {
        name: 'Pazienti',
        url: '/users',
        icon: cilUser
      },
      {
        name: 'Modelli di Dieta',
        url: '/diete',
        icon: cilList
      },
      {
        name: 'La tua Agenda',
        url: '/calendario',
        icon: cilCalendar
      },
    ]
  },

  //menu per un utente admin
  admin: {
    items: [
      {
        name: 'Nutrizionisti',
        url: '/nutrizionisti',
        icon: cilUser,
      },
      {
        name: 'Statistiche',
        url: '/dashboard',
        icon: cilSpeedometer,
      },
      {
        name: 'Pazienti',
        icon: cilUser,
        url: '/pazienti',
      },
      {
        name: 'Logs',
        icon: cilList,
        url: '/logs',
      },

    ]
  }
};
