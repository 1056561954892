import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

const initPasti = () => {
  return [
    { pasto: "Colazione", ora: "07", minuti: "30", testo: "", collapse: false, },
    { pasto: "Spuntino", ora: "10", minuti: "30", testo: "", collapse: false, },
    { pasto: "Pranzo", ora: "13", minuti: "00", testo: "", collapse: false, },
    { pasto: "Spuntino", ora: "16", minuti: "30", testo: "", collapse: false, },
    { pasto: "Cena", ora: "20", minuti: "00", testo: "", collapse: false, },
  ]
}
// This function takes a component...
export default function Controller(WrappedComponent,) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()

    state = {
      users: [],
      loading: false,
      error: false,
      dieta: {
        nome: "",
        id: null,
        lun: initPasti(),
        mar: initPasti(),
        mer: initPasti(),
        gio: initPasti(),
        ven: initPasti(),
        sab: initPasti(),
        dom: initPasti(),
      }

    }

    componentDidMount() {
      //capisco se è una nuova dieta o si sta aprendo una esistente
      if (this.props.router.params.id !== "add") {//si sta aprendo una esistente, la scarico
        this.setState({ loading: true })
        this.loadDieta(this.props.router.params.id)
      }
    }

    loadDieta = async (id) => {
      try {
        const response = await this.apiClient.getDietaTemplateById(id)
        const dieta = response.data
        this.setState({
          loading: false,
          dieta: dieta
        })
      } catch (err) {
        console.log(err)
        this.setState({
          loading: false,
          error: true
        })
      }
    }

    //rearrange items in array pasto
    rearrangePasto = (giornata, k, newK) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      if (newK >= 0 && newK < pasti.length) {//non devo sforare!
        pasti.splice(newK, 0, pasti.splice(k, 1)[0]);
        this.setState({ dieta })
      }
    }

    addPasto = (giornata,) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti.push({ pasto: "Colazione", testo: "", ora: "08", minuti: "00", })
      this.setState({ dieta })
    }

    changeTipoPasto = (giornata, k, pasto) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti[k].pasto = pasto
      this.setState({ dieta })
    }

    //riordina i pasti di una giornata nello stato, per ora e minuti
    sortPasti = (giornata) => {
      const { dieta } = this.state
      dieta[giornata].sort((a, b) => {
        return ((a.ora + a.minuti <= b.ora + b.minuti) ? -1 : 1)
      })
      this.setState({
        dieta
      })
    }

    changeHourPasto = (giornata, k, ore) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti[k].ora = ore
      this.setState({ dieta }, () => this.sortPasti(giornata))
    }

    changeMinutePasto = (giornata, k, min) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti[k].minuti = min
      this.setState({ dieta }, () => this.sortPasti(giornata))
    }

    changeTestoPasto = (giornata, k, testo) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti[k].testo = testo
      this.setState({ dieta })
    }

    removePasto = (giornata, k,) => {
      const { dieta } = this.state
      const pasti = dieta[giornata]
      pasti.splice(k, 1);
      this.setState({ dieta })
    }

    submit = async () => {
      const { dieta } = this.state
      //nome non può essere vuoto!
      if (dieta.nome == "") {
        Alert.error("Errore: inserire il nome della dieta.");
      } else {
        //capisco se è una nuova dieta o si sta aprendo una esistente
        if (this.props.router.params.id !== "add") {//esiste gia, update
          try {
            await this.apiClient.updateDietaTemplate(dieta)
            Alert.success("Dieta modificata!");
          } catch (err) {
            Alert.error("Errore, impossibile modificare la dieta.");
          }
        } else {//non esiste, creo
          try {
            const result = await this.apiClient.createDietaTemplate(dieta)
            Alert.success("Dieta creata!");
            this.props.router.navigate(`/diete/${result.data}`)
            this.loadDieta(result.data)
          } catch (err) {
            Alert.error("Errore, impossibile creare la dieta.");
          }
        }
      }
    }

    elimina = async () => {
      if (window.confirm("Sei sicuro di voler eliminare questa dieta template?")) {
        const iddieta = this.props.router.params.id
        try {
          await this.apiClient.deleteDietaTemplate(iddieta)
          Alert.success("Dieta eliminata!");
          this.props.router.navigate("/diete")
        } catch (err) {
          Alert.error("Errore, impossibile eliminare la dieta.");
        }
      }
    }

    cambiaNomeDieta = (n) => {
      const { dieta } = this.state
      dieta.nome = n
      this.setState({
        dieta
      })
    }

    render() {
      return <WrappedComponent
        {...this.props}
        state={this.state}
        rearrangePasto={this.rearrangePasto}
        addPasto={this.addPasto}
        changeTipoPasto={this.changeTipoPasto}
        changeHourPasto={this.changeHourPasto}
        changeMinutePasto={this.changeMinutePasto}
        removePasto={this.removePasto}
        changeTestoPasto={this.changeTestoPasto}
        submit={this.submit}
        cambiaNomeDieta={this.cambiaNomeDieta}
        elimina={this.elimina}
      />
    }
  }
}
