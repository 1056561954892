import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import ApiClient from '../ApiClient'
import { withRouter } from '../helper';

//se è loggato un admin, non mando richiesta per avere nome utente, ma scrivo solo "Admin"
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const isLogged = window.localStorage.getItem("token") != null
const isAdmin = isLogged && jwtDecode(window.localStorage.getItem("token")).a

const propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  ...Dropdown.propTypes
};

const defaultProps = {
  direction: 'down'
};

const apiClient = new ApiClient()

export const logout = async () => {
  if (!isAdmin) {
    await apiClient.logout()
  } else {
    await apiClient.logoutAdmin()
  }
  window.localStorage.clear();
  window.location.reload();
}

const AppHeaderDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle nav>
        {props.nomeUtente}
        &nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDown} size={"sm"} />
      </DropdownToggle>
      <DropdownMenu end style={{ overflow: 'hidden' }}>
        {!isAdmin &&
          <DropdownItem onClick={() => props.router.navigate("/profilo")} >
            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            &nbsp;&nbsp;Profilo
          </DropdownItem>
        }
        <DropdownItem onClick={logout}>
          <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>&nbsp;&nbsp;Esci
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

AppHeaderDropdown.propTypes = propTypes;
AppHeaderDropdown.defaultProps = defaultProps;

export default withRouter(AppHeaderDropdown);
