import Dashboard from './views/Dashboard/Dashboard.js';
import Login from './views/Login/Login.js';
import GestionePazienti from './views/GestionePazienti/View';
import GestioneDiete from './views/GestioneDiete/View';
import ProfiloPaziente from './views/ProfiloPaziente/View';
import SchedaPaziente from './views/ProfiloPaziente/Scheda';
import Visita from './views/Visita/Visita';
import Dieta from './views/Dieta/View';
import DietaPaziente from './views/DietaPaziente/View';
import AdminNuovoNutrizionista from './views/Admin/NuovoNutrizionista/View';
import AdminNuoviUtentiNutrizionista from './views/Admin/NuoviUtentiNutrizionista/View';
import AdminPazienti from './views/Admin/Pazienti';
import AdminLogs from './views/Admin/Logs';
import Nutrizionisti from './views/Admin/Nutrizionisti';
import DashboardAdmin from './views/Admin/Dashboard';
import ProfiloNutrizionista from './views/ProfiloNutrizionista/View';
import Calendario from './views/Calendario/View';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = {
  //routes viste da un utente nutrizionista
  nutrizionista : [
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/users', exact: true, name: 'Pazienti', component: GestionePazienti },
    { path: '/users/add', exact: true, name: 'Nuovo Paziente', component: SchedaPaziente},
    { path: '/users/:id', exact: true, name: 'Profilo', component: ProfiloPaziente},  
    { path: '/users/:id/diete/:iddieta', exact: true, name: 'Dieta Paziente', component: DietaPaziente},  
    { path: '/diete', exact: true, name: 'Diete', component: GestioneDiete }, 
    { path: '/diete/:id', exact: true, name: 'Dieta', component: Dieta }, 
    { path: '/users/:id/visite/:idvisita', exact: true, name: 'Visita', component: Visita }, 
    { path: '/profilo', exact: true, name: 'Profilo', component: ProfiloNutrizionista }, 
    { path: '/calendario', exact: true, name: 'Calendario', component: Calendario }, 
  ],
  //routes vista da un admin
  admin : [
    { path: '/dashboard', exact: true, name: 'Dashboard', component: DashboardAdmin }, 
    { path: '/nutrizionisti', exact: true, name: 'Nutrizionisti', component: Nutrizionisti }, 
    { path: '/nutrizionista', exact: true, name: 'Crea Nutrizionista', component: AdminNuovoNutrizionista }, 
    { path: '/nutrizionisti/:nid/users', exact: true, name: 'Crea Nutrizionista', component: AdminNuoviUtentiNutrizionista }, 
    { path: '/pazienti', exact: true, name: 'Pazienti', component: AdminPazienti }, 
    { path: '/logs', exact: true, name: 'Logs', component: AdminLogs }, 
  ]
}

export default routes;
