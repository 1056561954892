import React, { ComponentClass } from "react";
import ApiClient from "../../../ApiClient";
import { validateNutrizionista } from "../../../validators/nutrizionista";
import { Nutrizionista } from "../../../types/nutrizionista.interface";
import { UserNutrizionista } from "../../../types/usernutrizionista.interface";

const initUser = (): UserNutrizionista => {
  const p: UserNutrizionista = {
    id: null,
    nome: "",
    cognome: "",
    username: "",
    password: "",
    repeat_password: "",
    email: "",
    tos_version_accepted: null,
    tos_date_accepted: null,
    tos_accepted: false,
  };
  return p;
};

interface State {
  data: StateData;
}

interface StateData {
  nutrizionista: Nutrizionista;
  users: Array<UserNutrizionista>;
}
// This function takes a component...
export default function Controller(WrappedComponent: any): ComponentClass {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient();

    state: State = {
      data: {
        nutrizionista: {
          ragsociale: "",
          nome: "",
          cognome: "",
          email: "",
          pazienti_rimanenti: 10,
        },
        users: [initUser()],
      },
    };

    creaNutrizionista = async () => {
      const { data } = this.state;
      //bisogna creare almeno un utente nutrizionista!
      if (data.users.length == 0) {
        window.alert(
          "Attenzione: bisogna creare almeno un account utente per il nutrizionista."
        );
      }
      //validazione degli utenti!
      let isError = false;
      for (let i = 0; i < data.users.length; i++) {
        const validation = validateNutrizionista(data.users[i]);
        if (validation.isError) {
          window.alert(validation.error);
          isError = true;
        }
      }
      if (!isError) {
        try {
          await this.apiClient.createNutrizionista(data);
          window.alert("Nutrizionista creato!");
          //@ts-ignore
          this.props.router.navigate("/nutrizionisti");
        } catch (err: any) {
          window.alert(
            err.response.data.msg ||
              "Si è verificato un errore durante la creazione dell'account."
          );
        }
      }
    };

    setDatiUsers = (
      i: number,
      field: keyof UserNutrizionista,
      value: string
    ) => {
      if (field === "id") return;
      if (field === "tos_version_accepted") return;
      if (field === "tos_date_accepted") return;
      if (field === "tos_accepted") return;
      const { data } = this.state;
      data.users[i][field] = value;
      this.setState({ data });
    };

    setDatiNutrizionista = (field: keyof Nutrizionista, value: any) => {
      if (field === "id") return;
      const { data } = this.state;
      const n: any = data.nutrizionista;
      n[field] = value;
      data.nutrizionista = { ...n };
      this.setState({ data });
    };

    aggiungiUser = () => {
      const { data } = { ...this.state };
      const { users } = data;
      users.push(initUser());
      this.setState({ data });
    };

    rimuoviUser = (i: number) => {
      const { data } = { ...this.state };
      const { users } = data;
      users.splice(i, 1);
      this.setState({ data });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          setDatiNutrizionista={this.setDatiNutrizionista}
          setDatiUsers={this.setDatiUsers}
          submit={this.creaNutrizionista}
          state={this.state}
          aggiungiUser={this.aggiungiUser}
          rimuoviUser={this.rimuoviUser}
        />
      );
    }
  };
}
