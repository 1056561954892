import React, { useState } from 'react';
import { Button, Modal, ModalFooter, ModalBody, Row, Col, Label, ModalHeader, Input, FormGroup, InputGroup, InputGroupAddon } from 'reactstrap';
import './style.css'
import { createAppAccount } from '../../validators/paziente';
import Alert from 'react-s-alert';
import ApiClient from '../../ApiClient';
import { AlertPazientiRimanenti } from '../../views/ProfiloPaziente/Scheda';
import MyInput from '../MyInput';

export default ({ pid, isOpen, toggle, onSubmitSuccess }) => {

  const apiClient = new ApiClient()

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [validationErrors, setValidationErrors] = useState([])

  const _submit = async () => {
    const data = { username, password }
    //validation di username e password
    const { error } = createAppAccount.validate(data, { abortEarly: false })
    if (error) {//dati non validi
      Alert.error("Errore, alcuni campi non sono compilati correttamente.")
      setValidationErrors(error.details)
    } else {//validation ok
      try {
        const response = await apiClient.createAppAccountPaziente({
          id: pid,
          ...data
        })
        if (response.success) {
          Alert.success("Account per l'app SìDiet creato!")
          if (toggle !== undefined) toggle()
          if (onSubmitSuccess !== undefined) onSubmitSuccess()
        } else {
          throw new Error("fallito")
        }
      }
      catch (err) {
        //controllo se c'è stato un errore di conflitto su email/username
        if (err.response && err.response.status == 409) {
          Alert.error(err.response.data.error)
        } else {
          Alert.error("Errore, impossibile creare l'utente.")
        }
      }
      setValidationErrors([])
    }
  }

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => toggle(false)}
    >
      <span aria-hidden="true">×</span>
    </button>
  );

  return <Modal isOpen={isOpen} toggle={() => toggle(false)} size="lg">
    <ModalHeader toggle={() => toggle(true)} close={closeBtn}>
      Crea account paziente per App SìDiet
    </ModalHeader>
    <ModalBody>
      <AlertPazientiRimanenti />
      <div className="mb-20">
        Inserisci di seguito l'username e la password dell'account del paziente.
      </div>
      <Row form>
        <Col md={8}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="username">Username</Label>
                <MyInput validation={validationErrors} type="text" name="username" autoComplete="new-password" onChange={(evt) => setUsername(evt.target.value)} value={username} />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="password">Password</Label>
                <div className="d-flex align-items-start justify-content-between">
                  <MyInput validation={validationErrors} type={passwordVisible ? "text" : "password"} autoComplete="new-password" name="password" onChange={(evt) => setPassword(evt.target.value)} value={password} />
                  <Button className="ml-10" color="primary" onClick={() => setPasswordVisible(!passwordVisible)}>
                    {passwordVisible ? "Nascondi" : "Mostra"}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <img src={"/app.png"} className="width-100p" />
        </Col>

      </Row>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={_submit}>
        Conferma
      </Button>{' '}
      <Button color="primary" outline onClick={() => toggle(false)}>Annulla</Button>
    </ModalFooter>
  </Modal>
}