import React, { useCallback, useEffect, useState } from 'react';
import { useAppuntamenti } from '../state/appuntamenti'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Appuntamento from './appuntamento'
import MOTIVI_APPUNTAMENTO from '../state/appuntamenti/motivi'
import { FormGroup, Input, Label } from 'reactstrap';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import ApiClient from '../ApiClient';
import AlertMessage from "react-s-alert";

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)
const apiClient = new ApiClient();

const initAppuntamento = () => {
  return {
    id: null,
    data: moment().hours(9).minutes(30),
    data_end: moment().hours(9).minutes(45),
    note: "",
    paziente: null,
    sede: null,
    motivo: "",
    linkvc: "",
  }
}

//ritorna una classe diversa in base al motivo dell'appuntamento
const setColor = (motivo) => {
  const m = MOTIVI_APPUNTAMENTO.find(m => m.value == motivo)
  if (m === undefined) return null
  else return m.color
}
const handleSelect = ({ start, end }, setAppuntamento, setToggleModal) => {
  //appuntamento inizializzato
  const appuntamento = initAppuntamento()
  appuntamento.data = moment(start)
  appuntamento.data_end = moment(end)
  setAppuntamento(appuntamento)
  //apro modal
  setToggleModal(true)
}


export default (props) => {
  const [toggleModal, setToggleModal] = useState(false)
  const { appuntamenti, fetchAppuntamenti, updateAppuntamento } = useAppuntamenti()
  const [appuntamento, setAppuntamento] = useState(initAppuntamento())
  //inizializzo from e to con il primo e l'ultimo giorno di questa settimana
  const [from, setFrom] = useState(moment().weekday(0).hours(0).minutes(0))
  const [to, setTo] = useState(moment().weekday(6).hours(23).minutes(59))

  useEffect(() => {
    fetchAppuntamenti({ from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD') });
  }, [from, to]);


  const moveEvent = async ({ event, start, end }) => {
    try {
      const appuntamento = appuntamenti.find(a => a.id == event.id)
      appuntamento.data = start;
      appuntamento.data_end = end;
      const response = await apiClient.updateAppuntamentoPaziente(
        appuntamento.paziente,
        appuntamento
      )
      AlertMessage.success("Appuntamento modificato!");
      updateAppuntamento(response.data);
    } catch (err) {
      console.log(err);
      AlertMessage.error("Errore, impossibile aggiornare l'appuntamento.");
    }
  }

  const resizeEvent = async ({ event, start, end }) => {
    try {
      const appuntamento = appuntamenti.find(a => a.id == event.id)
      appuntamento.data = start;
      appuntamento.data_end = end;
      const response = await apiClient.updateAppuntamentoPaziente(
        appuntamento.paziente,
        appuntamento
      )
      AlertMessage.success("Appuntamento modificato!");
      updateAppuntamento(response.data);
    } catch (err) {
      console.log(err);
      AlertMessage.error("Errore, impossibile aggiornare l'appuntamento.");
    }
  }

  const [myEvents, setMyEvents] = useState([])
  useEffect(() => {
    const evts = appuntamenti.map(a => {
      return {
        id: a.id,
        title: a.motivo || "Appuntamento",
        start: moment(a.data).toDate(),
        end: moment(a.data_end).toDate(),
        color: setColor(a.motivo)
      }
    })
    setMyEvents(evts)
  }, [appuntamenti])
  return <div>
    {toggleModal &&
      <Appuntamento
        appuntamento={appuntamento}
        setAppuntamento={setAppuntamento}
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        pazienteSelectDisabled={false} {...props}></Appuntamento>
    }
    <DnDCalendar
      onRangeChange={(range) => {
        if (range.start === undefined) {
          setFrom(moment(range[0]))
          setTo(moment(range[range.length - 1]))
        }
        else {
          setFrom(moment(range.start))
          setTo(moment(range.end))
        }
      }}
      localizer={localizer}
      events={myEvents}
      defaultView={"week"}
      style={{ height: 500 }}
      onSelectSlot={(select) => handleSelect(select, setAppuntamento, setToggleModal)}
      onSelectEvent={event => {
        setAppuntamento(appuntamenti.find(e => e.id === event.id))
        setToggleModal(true)
      }}
      step={15}
      popup
      min={new Date(2000, 1, 1, 8)}
      max={new Date(2000, 1, 1, 22)}
      messages={{
        month: 'Mese',
        day: 'Giorno',
        today: 'Oggi',
        event: 'Evento',
        week: 'Settimana',
        previous: '<',
        next: '>',
        yesterday: 'Ieri',
        tomorrow: 'Domani',
        noEventsInRange: 'Non ci sono eventi',
        showMore: (count) => `+${count} appuntamenti`,
        date: 'Data',
        time: 'Orario',
      }}
      eventPropGetter={(event,) => {
        return { style: { backgroundColor: event.color } }
      }}
      draggableAccessor={(event) => true}
      selectable
      resizable
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
    />
  </div>
}