import React from 'react';
import { Input } from 'reactstrap';

export default ({ type, noInput = false, className = "", name, onChange, value, validation, children, disabled, autoComplete }) => {
  const isError = validation.some(v => v.context.key === name)
  const errorMessages = validation.filter(v => v.context.key === name)
  const Parent = noInput ? React.Fragment : Input
  if (isError) {
    className += " border border-danger";
  }
  return <div className="d-flex-column w-100">
    <Parent
      type={type}
      name={name}
      disabled={disabled}
      autoComplete={autoComplete}
      onChange={onChange}
      value={value}
      className={className}>
      {children}
    </Parent>
    {isError &&
      errorMessages.map(e => <small className="text-danger">{e.message}</small>)
    }
  </div>
}