import React from 'react';
import { useSedi } from '../../state/sedi'
import { Input, Alert, Spinner, Label, FormGroup } from 'reactstrap';
import './style.css'
//lista in select delle sedi del nutrizionista
export default (props) => {
  const { sedi, isLoading, isError, } = useSedi()
  //style
  const { hideContainer, hideLabel } = props
  const Container = hideContainer ? React.Fragment : FormGroup
  const onChange = (evt) => {
    if (props.onChange !== undefined)
      props.onChange(evt.target.value !== "" ? evt.target.value : null)
  }
  if (isError)
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  else if (isLoading)
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  else return <Container>
    {!hideLabel && <Label for={"note"}>Sede</Label>}
    <Input type="select" className="form-control" value={props.selected || ""} onChange={onChange}>
      <option value={""}>Seleziona una sede</option>
      {sedi.filter(s => s.disabilitata === 'n').map(s => <option key={s.id} value={s.id}>{s.nome} - {s.citta}, {s.via}</option>)}
    </Input>
  </Container>;
}