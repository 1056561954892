import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Form, FormGroup, Input, Label, Button, Nav, NavItem, TabContent, TabPane, NavLink, Alert, Spinner } from 'reactstrap'
import moment from 'moment'
import classnames from 'classnames';
import Controller from './Controller'
import { SingleDatePicker } from 'react-dates';
import SelectorSedi from '../../components/SelectorSedi'
import { withRouter } from '../../helper';

class Scheda extends Component {

  state = {
    activeTab: 0,
    sdpFocus: false,
    ui: {
      GENERALE: [
        {
          fieldName: "altezza",
          fieldLabel: "Altezza (cm)",
          type: "number"
        },
        {
          fieldName: "peso",
          fieldLabel: "Peso (Kg)",
          type: "number"
        },
        {
          fieldName: "bmi",
          fieldLabel: "BMI",
          type: "number"
        },
        {
          fieldName: "pressioneminima",
          fieldLabel: "Pressione Minima (mmHg)",
          type: "number"
        },
        {
          fieldName: "pressionemassima",
          fieldLabel: "Pressione Massima (mmHg)",
          type: "number"
        },

      ],
      ANTROPOMETRIA: [
        {
          fieldName: "polsosx",
          fieldLabel: "Polso Sx (cm)",
          type: "number"
        },
        {
          fieldName: "polso",
          fieldLabel: "Polso Dx (cm)",
          type: "number"
        },
        {
          fieldName: "avambracciosx",
          fieldLabel: "Avambraccio Sx (cm)",
          type: "number"
        },
        {
          fieldName: "avambraccio",
          fieldLabel: "Avambraccio Dx (cm)",
          type: "number"
        },
        {
          fieldName: "bracciosx",
          fieldLabel: "Braccio Sx (cm)",
          type: "number"
        },
        {
          fieldName: "braccio",
          fieldLabel: "Braccio Dx (cm)",
          type: "number"
        },
        {
          fieldName: "vita",
          fieldLabel: "Vita (cm)",
          type: "number"
        },
        {
          fieldName: "ombelicale",
          fieldLabel: "Ombelicale (cm)",
          type: "number"
        },
        {
          fieldName: "fianchi",
          fieldLabel: "Fianchi (cm)",
          type: "number"
        },
        {
          fieldName: "radicecosciasx",
          fieldLabel: "Radice Coscia Sx (cm)",
          type: "number"
        },
        {
          fieldName: "radicecoscia",
          fieldLabel: "Radice Coscia Dx (cm)",
          type: "number"
        },
        {
          fieldName: "cosciasx",
          fieldLabel: "Coscia Sx (cm)",
          type: "number"
        },
        {
          fieldName: "coscia",
          fieldLabel: "Coscia Dx (cm)",
          type: "number"
        },
      ],
      IMPEDENZIOMETRIA: [
        {
          fieldName: "massagrassa",
          fieldLabel: "Massa Grassa (Kg)",
          type: "number"
        },
        {
          fieldName: "massamagra",
          fieldLabel: "Massa Magra (Kg)",
          type: "number"
        },
        {
          fieldName: "massagrassaperc",
          fieldLabel: "Massa Grassa (%)",
          type: "number"
        },
        {
          fieldName: "massamagraperc",
          fieldLabel: "Massa Magra (%)",
          type: "number"
        },
        {
          fieldName: "metabolismobasale",
          fieldLabel: "Metabolismo Basale (cal)",
          type: "number"
        },
        {
          fieldName: "pesoforma",
          fieldLabel: "Peso Forma (kg)",
          type: "number"
        },
        {
          fieldName: "grassoviscerale",
          fieldLabel: "Grasso Viscerale",
          type: "number"
        },
        {
          fieldName: "rapportovitafianchi",
          fieldLabel: "Rapporto Vita Fianchi",
          type: "number"
        },
        {
          fieldName: "tbw",
          fieldLabel: "Acqua Corporea Totale (L)",
          type: "number"
        },
        {
          fieldName: "icw",
          fieldLabel: "Acqua Intracellulare (L)",
          type: "number"
        },
        {
          fieldName: "ecw",
          fieldLabel: "Acqua Extracellulare (L)",
          type: "number"
        },
        {
          fieldName: "bmr",
          fieldLabel: "Metabolismo Basale (kcal)",
          type: "number"
        },
        {
          fieldName: "bcm",
          fieldLabel: "Massa Cellulare (Kg)",
          type: "number"
        },
        {
          fieldName: "ecm",
          fieldLabel: "Massa Extracellulare (Kg)",
          type: "number"
        },
        {
          fieldName: "rapportoecmbcm",
          fieldLabel: "Rapporto ECM/BCM",
          type: "number"
        },
        {
          fieldName: "bcmi",
          fieldLabel: "Indice Massa Cellulare",
          type: "number"
        },
        {
          fieldName: "sm",
          fieldLabel: "Massa Muscolare (Kg)",
          type: "number"
        },
        {
          fieldName: "smi",
          fieldLabel: "Indice Massa Muscolare",
          type: "number"
        },
        {
          fieldName: "rapportosodiopotassio",
          fieldLabel: "Rapporto Sodio Potassio",
          type: "number"
        },
        {
          fieldName: "rapportopotassiopeso",
          fieldLabel: "Rapporto Potassio Peso (meq/Kg)",
          type: "number"
        },
      ],
      PLICOMETRIA: [
        {
          fieldName: "plicatricipitale",
          fieldLabel: "Plica Tricipitale (mm)",
          type: "number"
        },
        {
          fieldName: "plicasottoscapolare",
          fieldLabel: "Plica Sottoscapolare (mm)",
          type: "number"
        },
      ]
    }
  }

  toggle = activeTab => {
    this.setState({ activeTab });
  }

  renderForm = () => {
    const { activeTab, ui, sdpFocus, } = this.state
    const { visita } = this.props.state
    return <React.Fragment>
      <Row>
        <Col md={12} className={"flexIt"}>
          <h5>
            {this.props.router.params.idvisita === 'add' ? "Nuova visita" : ""}
          </h5>
          <span className={"flexIt"}>
            <Button style={{ float: 'right' }} onClick={this.props.submit} color="primary">Salva</Button>
            &nbsp;
            <Button style={{ float: 'right' }} onClick={() => this.props.router.navigate(`/users/${this.props.router.params.id}#visite`)} color="primary" outline>Indietro</Button>
            {this.props.router.params.idvisita !== "add" &&
              <React.Fragment>&nbsp;
                <Button style={{ float: 'right' }} onClick={() => this.props.elimina(this.props.router.params.idvisita)} color="danger">Elimina</Button>
              </React.Fragment>
            }
          </span>
        </Col>
      </Row>
      <hr />
      <Nav tabs horizontal={"end"}>
        {
          Object.keys(ui).map((el, k) => <NavItem key={k}>
            <NavLink
              key={k}
              className={classnames({ active: activeTab === k })}
              onClick={() => { this.toggle(k); }}
            >
              {el}
            </NavLink>
          </NavItem>
          )
        }
      </Nav>
      <Form>
        <TabContent activeTab={activeTab}>
          {
            Object.keys(ui).map((el, k) =>
              <TabPane key={k} tabId={k}>
                <Row form>
                  {
                    ui[el].map((inputElement, j) => <Col key={j} md={6}>
                      <FormGroup>
                        <Label
                          for={inputElement.fieldName}
                          style={inputElement.fieldName == "bmi" ||
                            inputElement.fieldName == "massagrassa" ||
                            inputElement.fieldName == "massamagra" ||
                            inputElement.fieldName == "massamagraperc" ||
                            inputElement.fieldName == "massagrassaperc" ?
                            { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {}}>
                          {inputElement.fieldLabel}
                          {
                            inputElement.fieldName == "bmi" && this.props.state.bmiSuggerito != 0 && visita[inputElement.fieldName] != this.props.state.bmiSuggerito &&
                            <span className={"btn-set-bmi cursorPointer"} onClick={() => this.props.setBMISuggerito()}>
                              Inserisci {this.props.state.bmiSuggerito}
                            </span>
                          }
                          {
                            inputElement.fieldName == "massamagra" &&
                            this.props.state.massamagraPercentuale != null &&
                            this.props.state.massamagraPercentuale != this.props.state.visita.massamagraperc &&
                            <span className={"btn-set-bmi cursorPointer"} onClick={() => this.props.setMassamagrapercSuggerito()}>
                              Inserisci ({this.props.state.massamagraPercentuale} %)
                            </span>
                          }
                          {
                            inputElement.fieldName == "massagrassa" &&
                            this.props.state.massagrassaPercentuale != null &&
                            this.props.state.massagrassaPercentuale != this.props.state.visita.massagrassaperc &&
                            <span className={"btn-set-bmi cursorPointer"} onClick={() => this.props.setMassagrassapercSuggerito()}>
                              Inserisci ({this.props.state.massagrassaPercentuale} %)
                            </span>
                          }
                          {
                            inputElement.fieldName == "massamagraperc" &&
                            this.props.state.massamagra != null &&
                            this.props.state.massamagra != this.props.state.visita.massamagra &&
                            <span className={"btn-set-bmi cursorPointer"} onClick={() => this.props.setMassamagraSuggerito()}>
                              Inserisci ({this.props.state.massamagra} kg)
                            </span>
                          }
                          {
                            inputElement.fieldName == "massagrassaperc" &&
                            this.props.state.massagrassa != null &&
                            this.props.state.massagrassa != this.props.state.visita.massagrassa &&
                            <span className={"btn-set-bmi cursorPointer"} onClick={() => this.props.setMassagrassaSuggerito()}>
                              Inserisci ({this.props.state.massagrassa} kg)
                            </span>
                          }
                        </Label>
                        <Input
                          onChange={(evt) => {
                            this.props.changeFormNumberValue(inputElement.fieldName, evt.target.value)
                            this.props.onBlur(inputElement.fieldName)
                          }}
                          step={0.01}
                          type={inputElement.type}
                          name={inputElement.fieldName}
                          value={visita[inputElement.fieldName]} />
                      </FormGroup>
                    </Col>
                    )
                  }
                </Row>
              </TabPane>
            )
          }

        </TabContent>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for={"note"}>Note</Label>
              <Input
                type="textarea"
                onChange={(evt) => this.props.changeFormValue("note", evt.target.value)}
                name={"note"}
                value={visita["note"]} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for={"noteprivate"}>Note Private</Label>
              <Input
                type="textarea"
                onChange={(evt) => this.props.changeFormValue("noteprivate", evt.target.value)}
                name={"noteprivate"}
                value={visita["noteprivate"]} />
            </FormGroup>
          </Col>
          <Col md={3} className="flexIt">
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={visita.hapagato == 'y'}
                  onChange={(evt) => this.props.changeFormValue("hapagato", evt.target.checked ? 'y' : 'n')} />
                Segna 'Ha pagato'
              </Label>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for={"valore"}>Costo Visita (€)</Label>
              <Input
                onBlur={() => this.props.onBlur("valore")}
                onChange={(evt) => this.props.changeFormNumberValue("valore", evt.target.value)}
                disabled={visita.hapagato == 'n'}
                step={0.01}
                type={"number"}
                name={"valore"}
                value={visita["valore"]} />
            </FormGroup>
          </Col>
          <Col md={6}></Col>
          <Col md={12}>
            <SelectorSedi selected={visita.sede} onChange={value => this.props.changeFormValue("sede", value)} />
          </Col>
          <Col md={9}></Col>
          <Col md={3}>
            <FormGroup>
              <Label for={"data"}>Data</Label>
              <div className="d-flex align-items-center justify-content-center">
                <SingleDatePicker
                  block
                  small
                  readOnly
                  numberOfMonths={1}
                  openDirection="up"
                  isOutsideRange={() => false}
                  className={"form-control mr-20"}
                  date={moment(visita["data"])} // momentPropTypes.momentObj or null
                  onDateChange={data => this.props.changeFormValue("data", data)} // PropTypes.func.isRequired
                  focused={sdpFocus} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ sdpFocus: focused })} // PropTypes.func.isRequired
                />
                <Input
                  type="time"
                  name="visita-time"
                  id="visita-time"
                  className="ml-20"
                  onChange={(evt) => {
                    let d = moment(visita["data"])
                    const hh = evt.target.value.substr(0, 2)
                    const mm = evt.target.value.substr(3, 2)
                    d.hours(hh)
                    d.minutes(mm)
                    this.props.changeFormValue("data", d)
                  }}
                  value={moment(visita["data"]).format("HH:mm")}
                  placeholder="Orario visita"
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  }


  render() {
    const { loading, error } = this.state
    const { paziente } = this.props.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <span class="flexIt">
              <strong style={{ flex: 1 }}>
                Profilo di <strong>{paziente.nome + " " + paziente.cognome}</strong>
              </strong>
            </span>
            <hr />
            {this.renderForm()}
          </CardBody>
        </Card>
      </div>
    )
  }
}

Scheda.defaultProps = {
  newVisita: true
}

export default withRouter(Controller(Scheda));

