import React, { Component } from 'react';
import moment from 'moment';
import ApiClient from '../../ApiClient'

class StampaDieta extends Component {
  apiClient = new ApiClient()

  state = {
    nutrizionista: { nome: "", cognome: "" },
    paziente: { nome: "", cognome: "" },
  }

  componentDidMount() {
    this.loadNutrizionista()
    this.loadPaziente()
  }

  loadNutrizionista = async () => {
    const result = await this.apiClient.getNutrizionista()
    this.setState({ nutrizionista: result.data })
  }


  loadPaziente = async () => {
    const result = await this.apiClient.getPazienteById(this.props.router.params.id)
    this.setState({ paziente: result.data })
  }

  giorniSettimana = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato",]

  render() {
    const { dieta, } = this.props
    const { nutrizionista, paziente } = this.state
    let days = Array(7).fill(null)
    /*
      devo stampare 7 giorni. 
      parto dal giorno odierno e ne stampo 7, se esistono
      se non ho raggiunto almeno 7 giornate, vado a ritroso a cercare di "completare" la mia stampa.
      se non la completo, lascio vuoti i giorni sconosciuti (caso davvero raro, e succede quando la dieta dura meno di 7 gg...)
    */
    let indexStart = dieta.days.findIndex(d => d.data == moment().format("DD/MM/YYYY"))
    if (indexStart !== -1) {
      dieta.days.slice(indexStart).map((d, k) => {
        //se è una domenica, ha days()=0, allora lo metto a fine array (pos. 6)
        let index = moment(d.data, "DD/MM/YYYY").days() == 0 ? 6 : moment(d.data, "DD/MM/YYYY").days() - 1
        if (days[index] === null) {
          days[index] = d
        }
      })
    }
    //se era -1, ho ancora tutti null in days, quindi lo comporrò con il seguente algoritmo
    //se non era -1, sopra ho preso i giorni da oggi in poi. se manca qualcosa, lo colmo con il seguente algoritmo
    days.map((d, i) => {
      if (d === null) {
        //se questo d è null, devo cercare il giorno corrispettivo. questo giorno della settimana
        //ha days() == 0 se è domenica. Quindi, il mio numero ricercato sarà:
        let ricercato = i == 6 ? 0 : i + 1
        //trovo il primo giorno della settimana di indice i
        const idx = dieta.days.slice().reverse().findIndex(c => moment(c.data, "DD/MM/YYYY").days() == ricercato)
        if (idx !== -1) {
          days[i] = dieta.days.slice().reverse()[idx]
        }
      }
    })
    return <div style={{ display: "none", }} >
      <div className={"dietaToPrint"} ref={el => this.props.setComponentToPrint(el)} style={{ padding: '500px', paddingLeft: "5%", paddingRight: '5%', paddingTop: '5%', }}>
        <div style={{ textAlign: 'right' }}>
        </div>
        <div className="flexIt">
          <h2>
            {nutrizionista.nome} {nutrizionista.cognome}
          </h2>
          <h3>
            {moment().format('LL')}
          </h3>
        </div>
        <div>
          <h3>Paziente: {paziente.nome} {paziente.cognome}</h3>
        </div>
        <div>
          <h2>
            Dieta
          </h2>
        </div>
        <div className={"flexIt"} style={{ borderWidth: 1, borderColor: 'red', backgroundColor: '#f6f6f6', }}>
          <div style={{ flex: 3, fontWeight: 'bold' }}>
            Giorno della settimana
          </div>
          <div style={{ flex: 9, }} className={"flexIt"}>
            <div style={{ flex: 2, fontWeight: 'bold', }} >Pasto</div>
            <div style={{ flex: 8, fontWeight: 'bold', }} >Contenuto</div>
            <div style={{ flex: 1, fontWeight: 'bold', }} >Orario</div>
          </div>
        </div>
        <div>
          {
            days.filter(d => d !== null).map((d, k) => (
              <div className={"flexIt"} key={k} style={{ borderBottomWidth: 1, borderBottomColor: '#cecece', borderBottomStyle: 'solid', paddingTop: 10, paddingBottom: 10, alignItems: 'flex-start' }}>
                <div style={{ flex: 3, verticalAlign: 'top' }}>
                  {this.giorniSettimana[moment(d.data, "DD/MM/YYYY").days()]}
                </div>
                <div style={{ flex: 9 }}>
                  {
                    d.pasti.map((p, j) => <div className={"flexIt"} style={{ alignItems: 'start' }} key={j}>
                      <div style={{ flex: 2, }}>{p.pasto}</div>
                      <div style={{ flex: 8, paddingRight: 10 }}>
                        {p.testo == undefined ?
                          ""
                          :
                          p.testo.split("\n").map(e => <>{e}<br /></>)}
                      </div>
                      <div style={{ flex: 1, }}>{p.ora}:{p.minuti}</div>
                    </div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  }
}

export default StampaDieta;