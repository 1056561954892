import React, { Component } from 'react';
import Controller from './Controller';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert, Spinner, Button, Card, CardBody, Input, Row, Col, Label, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import PastiDieta from './PastiDieta'
import { withRouter } from '../../helper';


class View extends Component {

  state = {
    activeTab: 0,
    startDate: "",
    endDate: ""
  }

  toggle = activeTab => {
    this.setState({ activeTab });
  }

  submit = () => {
    this.props.submit()
  }

  render() {
    const { error, loading, dieta } = this.props.state
    const { activeTab } = this.state
    const settimana = [
      {
        name: "Lunedì", pasti: dieta.lun, giornata: "lun"
      },
      {
        name: "Martedì", pasti: dieta.mar, giornata: "mar"
      },
      {
        name: "Mercoledì", pasti: dieta.mer, giornata: "mer"
      },
      {
        name: "Giovedì", pasti: dieta.gio, giornata: "gio"
      },
      {
        name: "Venerdì", pasti: dieta.ven, giornata: "ven"
      },
      {
        name: "Sabato", pasti: dieta.sab, giornata: "sab"
      },
      {
        name: "Domenica", pasti: dieta.dom, giornata: "dom"
      },
    ]

    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col md={8}>
                <FormGroup row>
                  <Label style={{ fontWeight: 'bold' }} sm={3}>Nome dieta</Label>
                  <Col sm={9}>
                    <Input placeholder={"Inserisci il nome della dieta"} type="text" value={dieta.nome} onChange={(evt) => this.props.cambiaNomeDieta(evt.target.value)}></Input>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={4}>
                <span style={{ float: 'right' }}>
                  <Button color="success" onClick={this.submit}>Salva</Button>&nbsp;
                  <Button color="primary" outline onClick={() => this.props.router.navigate("/diete")}>Indietro</Button>&nbsp;
                  <Button color="danger" disabled={this.props.router.params.id === "add"} onClick={this.props.elimina}>Elimina</Button>
                </span>
              </Col>
            </Row>
            <hr />
            <Nav tabs horizontal={"end"}>
              {
                settimana.map((item, k) =>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === k })}
                      onClick={() => { this.toggle(k); }}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>
            <TabContent activeTab={activeTab}>

              {
                settimana.map(({ pasti, giornata }, k) =>
                  <TabPane tabId={k}>
                    <h5>Pasti</h5>
                    <PastiDieta pasti={pasti} giornata={giornata}
                      rearrangePasto={this.props.rearrangePasto}
                      addPasto={this.props.addPasto}
                      changeTipoPasto={this.props.changeTipoPasto}
                      changeTestoPasto={this.props.changeTestoPasto}
                      changeHourPasto={this.props.changeHourPasto}
                      changeMinutePasto={this.props.changeMinutePasto}
                      removePasto={this.props.removePasto} />
                  </TabPane>
                )
              }
            </TabContent>
          </CardBody>
        </Card>


      </div >
    )
  }
}

export default withRouter(Controller(View));