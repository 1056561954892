import React, { useState, useEffect } from 'react'
import ApiClient from '../../../ApiClient'
import { Spinner, Card, CardBody, Button, Alert, Row, Col, Modal, ModalHeader, ModalBody, Input, ModalFooter, FormGroup } from 'reactstrap'
import Table from '../../../components/Table'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSave } from '@fortawesome/free-solid-svg-icons'

const apiClient = new ApiClient()

const initData = () => {
  return {
    pazienti: [],
    isError: false,
    isLoading: true
  }
}

// Implement startWith instead of contain 
const customMatchFunc = (pazienti, searchText) => {
  if (searchText === "" || searchText == undefined || searchText == null) {
    return pazienti
  }
  searchText = searchText.toLowerCase().trim()
  return pazienti.filter(p => (
    p.cognome + " " + p.nome).toLowerCase().includes(searchText) ||
    (p.nome + " " + p.cognome).toLowerCase().includes(searchText) ||
    (p.nome + p.cognome).toLowerCase().includes(searchText) ||
    (p.cognome + p.nome).toLowerCase().includes(searchText)
  )
}

const Pazienti = (props) => {
  const [data, setData] = useState(initData())
  const [showDropdownCambioPassword, setShowDropdownCambioPassword] = useState(false)
  const [cambioPasswordSelectedId, setCambioPasswordSelectedId] = useState(-1)
  const [newPassword, setNewPassword] = useState("")
  const [hideEliminati, setHideEliminati] = useState(true)
  useEffect(() => {
    getPazienti()
  }, [])


  const getPazienti = async () => {
    setData({
      ...data, isLoading: true
    })
    try {
      const results = await apiClient.getPazienti()
      setData({
        pazienti: results.data, isError: false, isLoading: false
      })
    } catch (err) {
      setData({
        ...data,
        isError: true
      })
    }
  }


  const submitCambioPasswordPaziente = async () => {
    let msg = "Stai per cambiare la password dell'account del paziente, sei sicuro di voler procedere?"
    if (window.confirm(msg)) {
      try {
        const paziente = data.pazienti.find(p => p.id === cambioPasswordSelectedId)
        await apiClient.pazienteChangePassword(paziente.email, paziente.username, newPassword)
        //reset dello stato
        setCambioPasswordSelectedId(-1)
        setNewPassword("")
        setShowDropdownCambioPassword(false)
        window.alert("Fatto!")
      } catch (err) {
        window.alert("Errore, impossibile eseguire l'operazione")
      }
    }
  }


  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      width: "5rem"
    },
    {
      name: 'Cognome',
      selector: row => row.cognome
    },
    {
      name: 'Nome',
      selector: row => row.nome
    },
    {
      name: 'Username',
      selector: row => row.username
    },
    {
      name: 'Eliminato',
      selector: row => row.deleted == "y" ? "Si" : "No"
    },
    {
      name: 'Email',
      width: "15rem",
      selector: row => row.email
    },
    {
      name: 'Azioni',
      width: "12rem",
      selector: row => {
        return <>
          <Button color="danger" className="mr-10" onClick={() => {
            setCambioPasswordSelectedId(row.id)
            setShowDropdownCambioPassword(true)
          }}>Cambia Password</Button>
        </>
      }
    },
  ]

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => setShowDropdownCambioPassword(false)}
    >
      <span aria-hidden="true">×</span>
    </button>
  );

  if (data.isLoading) {
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  } else if (data.isError) {
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  } else return <div className="animated fadeIn">
    <Modal size="sm" isOpen={showDropdownCambioPassword} toggle={setShowDropdownCambioPassword}>
      <ModalHeader close={closeBtn}>Cambio password Paziente</ModalHeader>
      <ModalBody>
        <small>Stai per cambiare la password dell'account del Paziente. Inseriscila di seguito se sei sicuro.</small>
        <Input type="text" value={newPassword} onChange={(evt) => setNewPassword(evt.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} outline color="primary" onClick={submitCambioPasswordPaziente} >
          <FontAwesomeIcon icon={faSave} />&nbsp;Salva
        </Button>
        <Button size={"sm"} color="primary" outline onClick={() => setShowDropdownCambioPassword(false)}>Chiudi</Button>
      </ModalFooter>
    </Modal>
    <Card>
      <CardBody>
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <h4 style={{ flex: 1 }}>Pazienti</h4>
            <div className="d-flex justify-content-between align-items-center">
              <FormGroup check className={"mr-10"}>
                <Input type="checkbox" checked={hideEliminati} onChange={(evt) => setHideEliminati(evt.target.checked)} />{' '}
                Nascondi eliminati
              </FormGroup>
            </div>
          </Col>
        </Row>
        <hr />
        <Table
          data={hideEliminati ? data.pazienti.filter(p => p.deleted == 'n') : data.pazienti}
          columns={columns}
          customMatchFunc={customMatchFunc}  />
      </CardBody>
    </Card>
  </div>
}
Pazienti.defaultProps = {

}
export default Pazienti