import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faBuilding,
  faCalendarCheck,
  faClock,
  faUser,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import ApiClient from "../ApiClient";
import MOTIVI_APPUNTAMENTO from "../state/appuntamenti/motivi";
import AlertMessage from "react-s-alert";
import { useAppuntamenti } from "../state/appuntamenti";
import Select from "react-select";
import { validateAppuntamento } from "../validators/appuntamento";
import SelectorSedi from "./SelectorSedi";

const apiClient = new ApiClient();

interface Props {
  appuntamento: any;
  setAppuntamento: any;
  pazienteSelectDisabled: any;
  onAppuntamentoSalvato(): void;
  setToggleModal(isOpen: boolean): void;
  onAppuntamentoEliminato(): void;
  toggleModal: boolean;
}

export default ({
  appuntamento,
  setAppuntamento,
  pazienteSelectDisabled,
  onAppuntamentoSalvato,
  setToggleModal,
  onAppuntamentoEliminato,
  toggleModal,
}: Props) => {
  const {
    addAppuntamenti,
    removeAppuntamento,
    updateAppuntamento,
    appuntamenti,
  }: any = useAppuntamenti();
  const [focused, setFocused] = useState(false);
  const [occupato, setOccupato] = useState(false);

  const [pazienti, setPazienti] = useState([]);
  //paziente selezionato
  const [paziente, setPaziente] = useState({ value: null, label: "" });

  const fetchPazienti = async () => {
    try {
      let response = await apiClient.getAllPazienti();
      //preparo per select
      response.data.map((p: any): any => {
        p.value = p.id;
        p.label = p.cognome + " " + p.nome;
        return p;
      });
      setPazienti(response.data);
      let paziente = null;
      if (appuntamento.id !== null) {
        if (appuntamento.paziente == null) {
          //non ho appuntamento completo, sono in calendario
          let appuntamentoCompleto = appuntamenti.find(
            (a: any) => a.id == appuntamento.id
          );
          paziente = response.data.find(
            (p: any) => p.id == appuntamentoCompleto.paziente
          );
        } else {
          // appuntamento completo, avrò id paziente in appuntamento.paziente
          paziente = response.data.find(
            (p: any) => p.id == appuntamento.paziente
          );
        }
      } else {
        //caso di Profilo -> Apuntamenti -> Nuovo
        if (appuntamento.paziente != null) {
          paziente = response.data.find(
            (p: any) => p.id == appuntamento.paziente
          );
        }
      }
      if (paziente !== null) {
        setPaziente({
          value: paziente.id,
          label: paziente.cognome + " " + paziente.nome,
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPazienti();
  }, [appuntamento]);

  useEffect(() => {
    checkIfOccupato(appuntamento);
  }, [appuntamento]);

  //controlla se in quell'orario c'è già un altro appuntamento
  const checkIfOccupato = async (appuntamento: any) => {
    try {
      let response = await apiClient.checkIfOccupato({
        data: appuntamento.data,
        data_end: appuntamento.data_end,
        appuntamento_id: appuntamento.id,
      });
      setOccupato(response.data.result);
    } catch (err: any) {
      console.log(err);
    }
  };

  const printTimePicker = (data_field: any) => {
    return (
      <>
        <Input
          className="form-control mr-10"
          type="select"
          value={moment(appuntamento[data_field]).hours()}
          onChange={(evt) => {
            let newAppuntamento = { ...appuntamento };
            // mi assicuro che i campi data siano moment
            newAppuntamento["data"] = moment(newAppuntamento["data"]);
            newAppuntamento["data_end"] = moment(newAppuntamento["data_end"]);
            //se il field è "data", quando cambio faccio modificare in accordo anche data_end, automaticamente
            let diff = 0;
            if (data_field === "data") {
              diff = moment(newAppuntamento["data_end"]).diff(
                moment(newAppuntamento["data"])
              );
            }
            newAppuntamento[data_field] = moment(
              newAppuntamento[data_field]
            ).hours(parseInt(evt.target.value));
            if (data_field === "data") {
              newAppuntamento["data_end"] = moment(newAppuntamento["data"]).add(
                diff
              );
            }
            //controllo che end non sia prima di start
            if (newAppuntamento["data"] <= newAppuntamento["data_end"]) {
              setAppuntamento({ ...newAppuntamento });
            }
          }}
        >
          {Array(24)
            .fill(0)
            .map((item, k) => (
              <option key={data_field + k} value={k}>
                {k}
              </option>
            ))}
        </Input>
        <Input
          className="form-control"
          type="select"
          value={moment(appuntamento[data_field]).minutes()}
          onChange={(evt) => {
            let newAppuntamento = { ...appuntamento };
            //se il field è "data", quando cambio faccio modificare in accordo anche data_end, automaticamente
            let diff = 0;
            if (data_field === "data") {
              diff = moment(newAppuntamento["data_end"]).diff(
                moment(newAppuntamento["data"])
              );
            }
            newAppuntamento[data_field] = moment(
              newAppuntamento[data_field]
            ).minutes(parseInt(evt.target.value));
            if (data_field === "data") {
              newAppuntamento["data_end"] = moment(newAppuntamento["data"]).add(
                diff
              );
            }
            //controllo che end non sia prima di start
            if (newAppuntamento["data"] <= newAppuntamento["data_end"]) {
              setAppuntamento({ ...newAppuntamento });
            }
          }}
        >
          <option value={0}>00</option>
          <option value={5}>05</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={30}>30</option>
          <option value={35}>35</option>
          <option value={40}>40</option>
          <option value={45}>45</option>
          <option value={50}>50</option>
          <option value={55}>55</option>
        </Input>
      </>
    );
  };

  const _submit = async () => {
    //imposto il paziente selezionato
    appuntamento.paziente = paziente.value;
    setAppuntamento(appuntamento);
    //validazione
    const validate = validateAppuntamento(appuntamento);
    if (validate.isError) AlertMessage.warning(validate.error);
    else {
      if (appuntamento.id == null) {
        //creo!
        try {
          const response = await apiClient.creaAppuntamentoPaziente(
            appuntamento.paziente,
            appuntamento
          );
          appuntamento = response.data;
          addAppuntamenti(appuntamento);
          AlertMessage.success("Appuntamento salvato!");
          if (onAppuntamentoSalvato !== undefined) onAppuntamentoSalvato();
          setToggleModal(false);
        } catch (err: any) {
          AlertMessage.error("Errore, impossibile salvare l'appuntamento.");
        }
      } else {
        //aggiorno!
        try {
          const response = await apiClient.updateAppuntamentoPaziente(
            appuntamento.paziente,
            appuntamento
          );
          AlertMessage.success("Appuntamento modificato!");
          updateAppuntamento(response.data);
          if (onAppuntamentoSalvato !== undefined) onAppuntamentoSalvato();
          setToggleModal(false);
        } catch (err: any) {
          console.log(err);
          AlertMessage.error("Errore, impossibile aggiornare l'appuntamento.");
        }
      }
    }
  };

  const _delete = async () => {
    if (window.confirm("Sei sicuro di voler eliminare l'appuntamento?")) {
      //elimino!
      try {
        await apiClient.eliminaAppuntamentoPaziente(
          appuntamento.paziente,
          appuntamento.id
        );
        AlertMessage.success("Appuntamento eliminato!");
        removeAppuntamento(appuntamento);
        setToggleModal(false);
        if (onAppuntamentoEliminato !== undefined) onAppuntamentoEliminato();
      } catch (err: any) {
        console.log(err);
        AlertMessage.error("Errore, impossibile eliminare l'appuntamento.");
      }
    }
  };

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => setToggleModal(!toggleModal)}
    >
      <span aria-hidden="true">×</span>
    </button>
  );

  return (
    <Modal
      isOpen={toggleModal}
      toggle={() => setToggleModal(!toggleModal)}
      backdrop={false}
    >
      <ModalHeader toggle={() => setToggleModal(!toggleModal)} close={closeBtn}>
        {appuntamento.id === null
          ? "Nuovo appuntamento"
          : "Modifica appuntamento"}
      </ModalHeader>
      <ModalBody>
        {occupato && (
          <Row>
            <Col md={12}>
              <Alert color="warning">
                Attenzione, hai già un altro appuntamento in quell'orario
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Label className={"text-bold"}>Paziente</Label>
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center mb-20"
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faUser}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Select
              className="width-100p"
              placeholder="Seleziona paziente"
              value={paziente}
              isDisabled={pazienteSelectDisabled}
              onChange={(selectedOption: any) => {
                setPaziente(selectedOption);
              }}
              options={pazienti}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Motivo</Label>
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center mb-20"
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faCalendarCheck}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              className="form-control"
              type="select"
              value={appuntamento.motivo}
              onChange={(evt) =>
                setAppuntamento({ ...appuntamento, motivo: evt.target.value })
              }
            >
              {MOTIVI_APPUNTAMENTO.map((m, i) => (
                <option key={m.value} value={m.value}>
                  {m.label}
                </option>
              ))}
            </Input>
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Data</Label>
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center  mb-20"
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faClock}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <SingleDatePicker
              id="date_input"
              enableOutsideDays
              readOnly
              small
              numberOfMonths={1}
              date={moment(appuntamento.data)} // momentPropTypes.momentObj or null
              onDateChange={(data: any) => {
                let newAppuntamento = { ...appuntamento };
                //cambio il giorno di data e data_end
                newAppuntamento.data = moment(newAppuntamento.data).years(
                  moment(data).years()
                );
                newAppuntamento.data_end = moment(
                  newAppuntamento.data_end
                ).years(moment(data).years());
                newAppuntamento.data = moment(newAppuntamento.data).months(
                  moment(data).months()
                );
                newAppuntamento.data_end = moment(
                  newAppuntamento.data_end
                ).months(moment(data).months());
                newAppuntamento.data = moment(newAppuntamento.data).dates(
                  moment(data).dates()
                );
                newAppuntamento.data_end = moment(
                  newAppuntamento.data_end
                ).dates(moment(data).dates());
                setAppuntamento({ ...newAppuntamento });
              }} // PropTypes.func.isRequired*/
              focused={focused} // PropTypes.bool
              onFocusChange={(evt) => {
                let focused: any = evt.focused;
                setFocused(focused);
              }} // PropTypes.func.isRequired
            />
            &nbsp;
            {printTimePicker("data")}&nbsp;-&nbsp;{printTimePicker("data_end")}
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Sede</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-20"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faBuilding}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <SelectorSedi
              hideContainer={true}
              hideLabel={true}
              selected={appuntamento.sede}
              onChange={(value: any) =>
                setAppuntamento({ ...appuntamento, sede: value })
              }
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Link Videoconferenza</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-20"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faVideo}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              className={"width-100p"}
              type="text"
              placeholder="Link videoconferenza"
              onChange={(evt) => {
                setAppuntamento({ ...appuntamento, linkvc: evt.target.value });
              }}
              value={appuntamento.linkvc}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Note</Label>
          </Col>
          <Col md={12} className="mb-20">
            <Input
              className={"width-100p"}
              type="textarea"
              placeholder="Note appuntamento"
              rows={5}
              onChange={(evt) => {
                setAppuntamento({ ...appuntamento, note: evt.target.value });
              }}
              value={appuntamento.note}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} outline color="primary" onClick={_submit}>
          <FontAwesomeIcon icon={faSave} />
          &nbsp;Salva
        </Button>
        <Button
          size={"sm"}
          outline
          color="danger"
          onClick={_delete}
          disabled={appuntamento.id === null}
        >
          <FontAwesomeIcon icon={faTimes} />
          &nbsp;Elimina
        </Button>
        <Button
          size={"sm"}
          color="primary"
          outline
          onClick={() => setToggleModal(false)}
        >
          Annulla
        </Button>
      </ModalFooter>
    </Modal>
  );
};
