import axios, { Axios } from "axios";
import IDocumento from "./types/IDocumento";
import ITerapia from "./types/ITerapia";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

class ApiClient {
  //token dell'utente
  token: string | null = null;
  api: Axios;

  constructor() {
    this.token = window.localStorage.getItem("token");

    this.api = axios.create({
      baseURL: backendUrl,
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
        "auth-token": this.token,
      },
    });
  }

  // elenco endpoints. non serve token
  endpoints = {
    system: {
      maintenance: "/maintenance",
    },
    user: {
      login: `/api/platform/users/login`,
      logout: `/api/platform/users/logout`,
      info: `/api/platform/users/`,
      accettaTos: `/api/platform/users/accettatos`,
      nutrizionista: `/api/platform/users/nutrizionista`,
      logoNutrizionista: `/api/platform/users/nutrizionista/logo`,
      dashboard: `/api/platform/users/dashboard`,
      appuntamentiByDay: (year: string, month: string, day: string) =>
        `/api/platform/users/appuntamenti/${year}/${month}/${day}`,
      appuntamentiByMonth: (year: string, month: string) =>
        `/api/platform/users/appuntamenti/${year}/${month}`,
      appuntamentiByYear: (year: string) =>
        `/api/platform/users/appuntamenti/${year}`,
      appuntamentiByRange: (from: string, to: string) =>
        `/api/platform/users/appuntamenti/range?from=${from}&to=${to}`,
      checkIfOccupato: `/api/platform/users/appuntamenti/occupato`,
      sedi: `/api/platform/users/nutrizionista/sedi`,
      updateSede: (idsede: number) =>
        `/api/platform/users/nutrizionista/sedi/${idsede}`,
      deleteSede: (idsede: number) =>
        `/api/platform/users/nutrizionista/sedi/${idsede}`,
      changePassword: `/api/platform/users/change-password`,
      askResetPassword: `/api/platform/users/ask-reset-password`,
      resetPassword: `/api/platform/users/reset-password`,
    },
    pazienti: {
      all: `/api/platform/pazienti/all`,
      getByid: (pid: number) => `/api/platform/pazienti/${pid}`,
      createAppAccount: (pid: number) =>
        `/api/platform/pazienti/${pid}/create-app-account`,
      update: (pid: number) => `/api/platform/pazienti/${pid}`,
      delete: (pid: number) => `/api/platform/pazienti/${pid}`,
      create: `/api/platform/pazienti/`,
      getProgressi: (pid: number) => `/api/platform/pazienti/${pid}/progressi`,
      getDiete: (pid: number) => `/api/platform/pazienti/${pid}/diete`,
      creaDieta: (pid: number) => `/api/platform/pazienti/${pid}/diete`,
      getDietaById: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}`,
      updateDieta: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}`,
      updateDateDieta: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}/date`,
      riassegnaDieta: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}/riassegna`,
      setDietaVisibile: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}/visibile`,
      deleteDieta: (pid: number, iddieta: number) =>
        `/api/platform/pazienti/${pid}/diete/${iddieta}`,
      getAppuntamenti: (pid: number) =>
        `/api/platform/pazienti/${pid}/appuntamenti`,
      creaAppuntamento: (pid: number) =>
        `/api/platform/pazienti/${pid}/appuntamenti`,
      updateAppuntamento: (pid: number, idappuntamento: number) =>
        `/api/platform/pazienti/${pid}/appuntamenti/${idappuntamento}`,
      segnaArrivatoAppuntamento: (pid: number, idappuntamento: number) =>
        `/api/platform/pazienti/${pid}/appuntamenti/${idappuntamento}/arrivato`,
      deleteAppuntamento: (pid: number, idappuntamento: number) =>
        `/api/platform/pazienti/${pid}/appuntamenti/${idappuntamento}`,

      getVisite: (pid: number) => `/api/platform/pazienti/${pid}/visite`,
      getVisitaById: (pid: number, idvisita: number) =>
        `/api/platform/pazienti/${pid}/visite/${idvisita}`,
      creaVisita: (pid: number) => `/api/platform/pazienti/${pid}/visite`,
      updateVisita: (pid: number, idvisita: number) =>
        `/api/platform/pazienti/${pid}/visite/${idvisita}`,
      haPagatoVisita: (pid: number, idvisita: number) =>
        `/api/platform/pazienti/${pid}/visite/${idvisita}/pagato`,
      deleteVisita: (pid: number, idvisita: number) =>
        `/api/platform/pazienti/${pid}/visite/${idvisita}`,
      sendNotifica: (pid: number) => `/api/platform/pazienti/${pid}/notifica`,
      updateConsigliNutrizionali: (pid: number) =>
        `/api/platform/pazienti/${pid}/consiglinutrizionali`,
      updateQuestionario: (pid: number) =>
        `/api/platform/pazienti/${pid}/questionario`,
      getDocumenti: (pid: number) => `/api/platform/pazienti/${pid}/documenti`,
      getDocumentoById: (pid: number, did: number) =>
        `/api/platform/pazienti/${pid}/documenti/${did}`,
      getTerapie: (pid: number) => `/api/platform/pazienti/${pid}/terapie`,
      creaTerapia: (pid: number) => `/api/platform/pazienti/${pid}/terapie`,
      getTerapiaById: (pid: number, idterapia: number) =>
        `/api/platform/pazienti/${pid}/terapie/${idterapia}`,
      updateTerapia: (pid: number, idterapia: number) =>
        `/api/platform/pazienti/${pid}/terapie/${idterapia}`,
      deleteTerapia: (pid: number, idterapia: number) =>
        `/api/platform/pazienti/${pid}/terapie/${idterapia}`,
    },
    //template!
    diete: {
      create: `/api/platform/diete/`,
      all: `/api/platform/diete/all`,
      getById: (id: number) => `/api/platform/diete/${id}`,
      update: (id: number) => `/api/platform/diete/${id}`,
      delete: (id: number) => `/api/platform/diete/${id}`,
      createFromDieta: `/api/platform/diete/fromDieta`,
    },
    admin: {
      login: "/api/admin/login",
      logout: "/api/admin/logout",
      stats: "/api/admin/stats",
      logsHttp: "/api/admin/logs/http",
      logsApp: "/api/admin/logs/app",
      statsViews: "/api/admin/stats/views",
      statsPazienti: "/api/admin/stats/pazienti",
      statsNotifiche: "/api/admin/stats/notifiche",
      nutrizionisti: "/api/admin/nutrizionisti",
      usersNutrizionisti: (nid: string) =>
        `/api/admin/nutrizionisti/${nid}/users`,
      pazienti: "/api/admin/pazienti",
      nutrizionistiChangePassword: "/api/admin/nutrizionisti/change-password",
      pazientiChangePassword: "/api/admin/pazienti/change-password",
      addPazientiRimanenti: (nid: number) =>
        `/api/admin/nutrizionisti/${nid}/pazientirimanenti`,
      disabilitaNutrizionista: (nid: number) =>
        `/api/admin/nutrizionisti/${nid}/disabilita`,
    },
  };

  getPazienteById = async (pid: number) => {
    const response = await this.api.get(this.endpoints.pazienti.getByid(pid));
    return response.data;
  };

  //login con username e password passati in body json (POST)
  login = async (usr: string, pwd: string) => {
    const response = await this.api.post(this.endpoints.user.login, {
      username: usr,
      password: pwd,
    });
    return response.data;
  };

  //il backend è in manutenzione?
  isMaintenanceActive = async () => {
    const response = await this.api.get(this.endpoints.system.maintenance);
    return response.data;
  };

  //login per admin
  loginAdmin = async (usr: string, pwd: string) => {
    const response = await this.api.post(this.endpoints.admin.login, {
      username: usr,
      password: pwd,
    });
    return response.data;
  };

  //cambio password con username e password vecchia
  changePassword = async (usr: string, pwd: string, newPassword: string) => {
    const response = await this.api.post(this.endpoints.user.changePassword, {
      username: usr,
      password: pwd,
      newPassword: newPassword,
    });
    return response.data;
  };
  //richiesta reset password con username
  askResetPassword = async (usr: string) => {
    const response = await this.api.post(this.endpoints.user.askResetPassword, {
      username: usr,
    });
    return response.data;
  };
  //esecuzione reset password con token
  resetPassword = async (token: string, password: string) => {
    const response = await this.api.post(this.endpoints.user.resetPassword, {
      token,
      password,
    });
    return response.data;
  };

  //info sull'utente loggato
  getUserInfo = async () => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(this.endpoints.user.info);
      return response.data;
    } else return false;
  };
  //modifica nome, cognome o email dell'utente loggato
  updateUserInfo = async (body: any) => {
    const response = await this.api.post(this.endpoints.user.info, body);
    return response.data;
  };
  //accetta tos
  accettaTos = async () => {
    const response = await this.api.post(this.endpoints.user.accettaTos);
    return response.data;
  };
  //info per sedi del nutrizionista
  getDashboardInfo = async () => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(this.endpoints.user.dashboard);
      return response.data;
    } else return false;
  };
  //info per appuntamenti di una certa giornata
  getAppuntamentiByDay = async (year: string, month: string, day: string) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(
        this.endpoints.user.appuntamentiByDay(year, month, day)
      );
      return response.data;
    } else return false;
  };
  //info per appuntamenti di un certo mese
  getAppuntamentiByMonth = async (year: string, month: string) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(
        this.endpoints.user.appuntamentiByMonth(year, month)
      );
      return response.data;
    } else return false;
  };
  //info per appuntamenti per range
  getAppuntamentiByRange = async (from: string, to: string) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(
        this.endpoints.user.appuntamentiByRange(from, to)
      );
      return response.data;
    } else return false;
  };
  //controlla se il nutrizionista è occupato in un periodo data/data_end passato per parametro
  checkIfOccupato = async (body: any) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.post(
        this.endpoints.user.checkIfOccupato,
        body
      );
      return response.data;
    } else return false;
  };
  //info per appuntamenti di una certo anno
  getAppuntamentiByYear = async (year: string) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(
        this.endpoints.user.appuntamentiByYear(year)
      );
      return response.data;
    } else return false;
  };
  //info per dashboard (stats pazienti)
  getSediNutrizionista = async () => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.get(this.endpoints.user.sedi);
      return response.data;
    } else return false;
  };
  //crea una nuova sede
  createSedeNutrizionista = async (sede: any) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.post(this.endpoints.user.sedi, sede);
      return response.data;
    } else return false;
  };
  //update una sede
  updateSedeNutrizionista = async (sede: any) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.put(
        this.endpoints.user.updateSede(sede.id),
        sede
      );
      return response.data;
    } else return false;
  };
  //elimina una nuova sede
  deleteSedeNutrizionista = async (idsede: number) => {
    //fix di un glitch che faceva partire questa call in fase di logout!
    if (this.token != null) {
      const response = await this.api.delete(
        this.endpoints.user.deleteSede(idsede)
      );
      return response.data;
    } else return false;
  };

  //logout dell'utente
  logout = async () => {
    try {
      await this.api.post(this.endpoints.user.logout);
    } catch (err) {}
    return true;
  };
  //logout dell'utente admin
  logoutAdmin = async () => {
    try {
      await this.api.post(this.endpoints.admin.logout);
    } catch (err) {}
    return true;
  };
  //restituisce tutti i pazienti dell'utente
  getAllPazienti = async () => {
    const response = await this.api.get(this.endpoints.pazienti.all);
    return response.data;
  };
  //crea un paziente
  createPaziente = async (body: any) => {
    const response = await this.api.post(this.endpoints.pazienti.create, body);
    return response.data;
  };
  //aggiorna un paziente
  updatePaziente = async (body: any) => {
    const response = await this.api.put(
      this.endpoints.pazienti.update(body.id),
      body
    );
    return response.data;
  };
  //crea un account app per un paziente
  createAppAccountPaziente = async (body: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.createAppAccount(body.id),
      body
    );
    return response.data;
  };
  //elimina un paziente
  deletePaziente = async (idPaziente: number) => {
    const response = await this.api.delete(
      this.endpoints.pazienti.delete(idPaziente)
    );
    return response.data;
  };
  //crea una dieta template
  createDietaTemplate = async (body: any) => {
    const response = await this.api.post(this.endpoints.diete.create, body);
    return response.data;
  };
  //crea una dieta template a partire da una dieta già esistente, passata per id
  createDietaTemplateFromDieta = async (pid: number, iddieta: number) => {
    const response = await this.api.post(this.endpoints.diete.createFromDieta, {
      pid,
      iddieta,
    });
    return response.data;
  };
  //restituisce tutte le diete template
  getAllDieteTemplate = async () => {
    const response = await this.api.get(this.endpoints.diete.all);
    return response.data;
  };
  //restituisce tutte le diete template
  getDietaTemplateById = async (id: number) => {
    const response = await this.api.get(this.endpoints.diete.getById(id));
    return response.data;
  };
  //aggiorna una dieta template
  updateDietaTemplate = async (body: any) => {
    const response = await this.api.post(
      this.endpoints.diete.update(body.id),
      body
    );
    return response.data;
  };
  //elimina una dieta template
  deleteDietaTemplate = async (id: number) => {
    const response = await this.api.delete(this.endpoints.diete.delete(id));
    return response.data;
  };
  //restituisce tutti i dati del nutrizionista
  getNutrizionista = async () => {
    const response = await this.api.get(this.endpoints.user.nutrizionista);
    return response.data;
  };
  //aggiorna i dati del nutrizionista
  updateNutrizionista = async (body: any) => {
    const response = await this.api.post(
      this.endpoints.user.nutrizionista,
      body
    );
    return response.data;
  };
  //aggiorna il file logo del nutrizionista
  updateLogoNutrizionista = async (file: any) => {
    var formData = new FormData();
    formData.append("logo", file);
    const response = await axios.put(
      backendUrl + this.endpoints.user.logoNutrizionista,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": this.token,
        },
      }
    );
    return response.data;
  };

  //scarica le diete di un certo paziente
  getDietePaziente = async (pid: number) => {
    const response = await this.api.get(this.endpoints.pazienti.getDiete(pid));
    return response.data;
  };
  //scarica i progressi di un certo paziente
  getProgressiPaziente = async (pid: number) => {
    const response = await this.api.get(
      this.endpoints.pazienti.getProgressi(pid)
    );
    return response.data;
  };

  //scarica una dieta paziente by id
  getDietaPazienteById = async (pid: number, iddieta: number) => {
    const response = await this.api.get(
      this.endpoints.pazienti.getDietaById(pid, iddieta)
    );
    return response.data.data;
  };

  //crea una dieta per un certo paziente
  creaDietaPaziente = async (pid: number, dieta: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.creaDieta(pid),
      dieta
    );
    return response.data;
  };

  //aggiorna una dieta di un certo paziente
  updateDietaPaziente = async (pid: number, dieta: any) => {
    const response = await this.api.put(
      this.endpoints.pazienti.updateDieta(pid, dieta.id),
      dieta
    );
    return response.data;
  };
  //aggiorna le date di una dieta di un certo paziente
  updateDateDietaPaziente = async (
    pid: number,
    iddieta: number,
    nuovo_dal: string,
    nuovo_al: string
  ) => {
    const response = await this.api.put(
      this.endpoints.pazienti.updateDateDieta(pid, iddieta),
      { nuovo_dal, nuovo_al }
    );
    return response.data;
  };
  //riassegna una dieta di un certo paziente
  riassegnaDietaPaziente = async (
    pid: number,
    iddieta: number,
    nuovo_dal: string,
    nuovo_al: string
  ) => {
    const response = await this.api.put(
      this.endpoints.pazienti.riassegnaDieta(pid, iddieta),
      { nuovo_dal, nuovo_al }
    );
    return response.data;
  };

  //rende una dieta visibile per l'utente
  setDietaVisibile = async (pid: number, dieta: any) => {
    const response = await this.api.put(
      this.endpoints.pazienti.setDietaVisibile(pid, dieta.id),
      dieta
    );
    return response.data;
  };
  //elimina una dieta di un certo paziente
  deleteDietaPaziente = async (pid: number, dieta: any) => {
    const response = await this.api.delete(
      this.endpoints.pazienti.deleteDieta(pid, dieta.id),
      dieta
    );
    return response.data;
  };

  getAppuntamentiPaziente = async (pid: number) => {
    const response = await this.api.get(
      this.endpoints.pazienti.getAppuntamenti(pid)
    );
    return response.data.data;
  };
  //crea un appuntamento per un certo paziente
  creaAppuntamentoPaziente = async (pid: number, appuntamento: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.creaAppuntamento(pid),
      appuntamento
    );
    return response.data;
  };

  //aggiorna un certo appuntamento di un certo paziente
  updateAppuntamentoPaziente = async (pid: number, appuntamento: any) => {
    const response = await this.api.put(
      this.endpoints.pazienti.updateAppuntamento(pid, appuntamento.id),
      appuntamento
    );
    return response.data;
  };

  //segna un certo appuntamento come "arrivato"
  segnaArrivatoAppuntamento = async (pid: number, idappuntamento: number) => {
    const response = await this.api.put(
      this.endpoints.pazienti.segnaArrivatoAppuntamento(pid, idappuntamento)
    );
    return response.data;
  };
  //elimina un certo appuntamento di un certo paziente
  eliminaAppuntamentoPaziente = async (pid: number, idappuntamento: number) => {
    const response = await this.api.delete(
      this.endpoints.pazienti.deleteAppuntamento(pid, idappuntamento)
    );
    return response.data;
  };

  getVisitePaziente = async (pid: number) => {
    const response = await this.api.get(this.endpoints.pazienti.getVisite(pid));
    return response.data.data;
  };
  getVisitaPazienteById = async (pid: number, idvisita: number) => {
    const response = await this.api.get(
      this.endpoints.pazienti.getVisitaById(pid, idvisita)
    );
    return response.data.data;
  };
  //crea un visita per un certo paziente
  creaVisitaPaziente = async (pid: number, visita: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.creaVisita(pid),
      visita
    );
    return response.data;
  };

  //aggiorna un certo visita di un certo paziente
  updateVisitaPaziente = async (pid: number, visita: any) => {
    const response = await this.api.put(
      this.endpoints.pazienti.updateVisita(pid, visita.id),
      visita
    );
    return response.data;
  };

  //elimina un certo visita di un certo paziente
  eliminaVisitaPaziente = async (pid: number, idvisita: number) => {
    const response = await this.api.delete(
      this.endpoints.pazienti.deleteVisita(pid, idvisita)
    );
    return response.data;
  };
  //invia una notifica su app ad un paziente
  /* 
    - pid:     paziente id
    - message: { message:""}
  */
  sendNotifica = async (pid: number, message: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.sendNotifica(pid),
      message
    );
    return response.data;
  };
  //crea un nuovo consiglio nutrizionale per l'utente
  updateConsigliNutrizionali = async (pid: number, body: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.updateConsigliNutrizionali(pid),
      body
    );
    return response.data;
  };

  //aggiorna il questionario per il paziente da parte del nustrizionista
  updateQuestionario = async (pid: number, body: any) => {
    const response = await this.api.post(
      this.endpoints.pazienti.updateQuestionario(pid),
      body
    );
    return response.data;
  };

  uploadDocumentoPaziente = async (
    pid: number,
    file: File
  ): Promise<IDocumento> => {
    var formData = new FormData();
    formData.append("documento", file);
    const response = await this.api.post(
      this.endpoints.pazienti.getDocumenti(pid),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "content-length": `${file.size}`,
        },
      }
    );
    return response.data.data;
  };

  getDocumentiPaziente = async (pid: number): Promise<Array<IDocumento>> => {
    const response = await this.api.get(
      this.endpoints.pazienti.getDocumenti(pid)
    );
    return response.data.data;
  };

  getDocumentoPazienteById = async (pid: number, did: number): Promise<any> => {
    const response = await this.api.get(
      this.endpoints.pazienti.getDocumentoById(pid, did),
      {
        responseType: "blob",
      }
    );
    return response;
  };

  deleteDocumentoPazienteById = async (
    pid: number,
    did: number
  ): Promise<any> => {
    const response = await this.api.delete(
      this.endpoints.pazienti.getDocumentoById(pid, did)
    );
    return response;
  };

  createNutrizionista = async (body: any) => {
    const response = await this.api.post(
      this.endpoints.admin.nutrizionisti,
      body
    );
    return response.data;
  };

  createUsersNutrizionista = async (nid: string, body: any) => {
    const response = await this.api.post(
      this.endpoints.admin.usersNutrizionisti(nid),
      body
    );
    return response.data;
  };

  getNutrizionisti = async (body: any) => {
    const response = await this.api.get(
      this.endpoints.admin.nutrizionisti,
      body
    );
    return response.data;
  };

  getPazienti = async () => {
    const response = await this.api.get(this.endpoints.admin.pazienti);
    return response.data;
  };

  addPazientiRimanenti = async (nid: number, body: any) => {
    const response = await this.api.put(
      this.endpoints.admin.addPazientiRimanenti(nid),
      body
    );
    return response.data;
  };

  disabilitaNutrizionista = async (nid: number, body: any) => {
    const response = await this.api.put(
      this.endpoints.admin.disabilitaNutrizionista(nid),
      body
    );
    return response.data;
  };

  getAdminStats = async (start: string, end: string) => {
    const response = await this.api.get(this.endpoints.admin.stats, {
      params: {
        start,
        end,
      },
    });
    return response.data;
  };

  getAdminStatsViews = async (start: string, end: string) => {
    const response = await this.api.get(this.endpoints.admin.statsViews, {
      params: {
        start,
        end,
      },
    });
    return response.data;
  };

  getAdminStatsPazienti = async (start: string, end: string) => {
    const response = await this.api.get(this.endpoints.admin.statsPazienti, {
      params: {
        start,
        end,
      },
    });
    return response.data;
  };

  getAdminStatsNotifiche = async (start: string, end: string) => {
    const response = await this.api.get(this.endpoints.admin.statsNotifiche, {
      params: {
        start,
        end,
      },
    });
    return response.data;
  };

  getLogsHttp = async (data: string, from: string, count: number) => {
    const response = await this.api.post(this.endpoints.admin.logsHttp, {
      data,
      from,
      count,
    });
    return response.data;
  };

  getLogsApp = async (data: string, from: string, count: number) => {
    const response = await this.api.post(this.endpoints.admin.logsApp, {
      data,
      from,
      count,
    });
    return response.data;
  };

  nutrizionistaChangePassword = async (email: string, password: string) => {
    const response = await this.api.post(
      this.endpoints.admin.nutrizionistiChangePassword,
      {
        username: email,
        newPassword: password,
      }
    );
    return response.data;
  };

  pazienteChangePassword = async (
    email: string,
    username: string,
    password: string
  ) => {
    const response = await this.api.post(
      this.endpoints.admin.pazientiChangePassword,
      {
        username,
        email,
        password,
      }
    );
    return response.data;
  };

  getTerapiePaziente = async (pid: number): Promise<ITerapia[]> => {
    const response = await this.api.get(
      this.endpoints.pazienti.getTerapie(pid)
    );
    return response.data.data;
  };

  getTerapiaPaziente = async (
    pid: number,
    terapiaid: number
  ): Promise<ITerapia> => {
    const response = await this.api.get(
      this.endpoints.pazienti.getTerapiaById(pid, terapiaid)
    );
    return response.data.data;
  };

  creaTerapia = async (
    pid: number,
    dettagli: string,
    dal: string,
    al: string
  ): Promise<Boolean> => {
    const response = await this.api.post(
      this.endpoints.pazienti.creaTerapia(pid),
      { dettagli, dal, al }
    );
    return response.data.data;
  };

  updateTerapia = async (
    pid: number,
    terapiaid: number,
    dettagli: string,
    dal: string,
    al: string
  ): Promise<Boolean> => {
    const response = await this.api.put(
      this.endpoints.pazienti.updateTerapia(pid, terapiaid),
      { dettagli, dal, al }
    );
    return response.data.data;
  };

  deleteTerapia = async (
    pid: number,
    terapiaid: number,
  ): Promise<Boolean> => {
    const response = await this.api.delete(
      this.endpoints.pazienti.deleteTerapia(pid, terapiaid)
    );
    return response.data.data;
  };
}

export default ApiClient;
