import React, { Component } from 'react';
import '../../style.css'
import { Navigate } from 'react-router-dom';
import ApiClient from '../../ApiClient'
import { Spinner } from 'reactstrap';
import { APP_NAME } from '../../constants';
const isLogged = window.localStorage.getItem("token") != null

class ResetPassword extends Component {

  apiClient = new ApiClient()
  state = {
    newPassword1: "",
    newPassword2: "",
    loading: false
  }

  resetPassword = async (evt) => {
    evt.preventDefault()
    this.setState({ loading: true })
    const { newPassword1, newPassword2 } = this.state
    const { tkn } = this.props.router.params
    if (newPassword1 !== newPassword2) {
      window.alert("Le due password non coincidono!")
      return;
    }
    let response = null
    try {
      //controllo formato password
      if (!/^(?=.*?[a-zA-Z])(?=.*?[0-9])[\w@#$%^?~-]{8,16}$/.test(newPassword1)) {
        window.alert(`Attenzione: la password deve essere lunga tra 8 e 16 caratteri, e contenere almeno un numero.`)
      } else {
        //api di reset
        response = await this.apiClient.resetPassword(tkn, newPassword1)
        if (response.success) {
          window.alert("Password aggiornata, puoi effettuare il login.")
          window.location.href = "/"
        }
        else {
          throw new Error("error")
        }
      }
    } catch (err) {//dati errati!
      window.alert("Operazione non riuscita.")
    }
    this.setState({ loading: false })
  }



  render() {
    const { newPassword1, newPassword2, loading } = this.state
    if (isLogged)
      return <Navigate to={{ pathname: '/', }} />
    else return <div className={"flexIt"} style={{ backgroundColor: '#fff' }}>
      <div className="homepage">
        <div className={"ghirigoro"} />
        <div className={"img-logo-container"}>
          <img src="/logo.png" className={"logo-img"} />
        </div>
      </div>
      <div className="login-container">
        <form onSubmit={this.resetPassword} className="login-box">
          <img src="/logo.png" className={"logo-img"} />
          <h1>{APP_NAME}</h1>
          <small style={{ textAlign: 'center' }}>Aggiorna ora la tua password. <br />
            Ricorda che deve essere lunga tra gli 8 e i 16 caratteri<br />
            e contenere almeno un numero.</small>
          <div className={"input-container"}>
            <input className="text-input" type="password" onChange={(evt) => this.setState({ newPassword1: evt.target.value })} value={newPassword1} placeholder="Nuova password" />
            <input className="text-input" type="password" onChange={(evt) => this.setState({ newPassword2: evt.target.value })} value={newPassword2} placeholder="Ripeti password" />
            <input className="submit-btn login-btn" type="submit" value="CAMBIA PASSWORD" />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Spinner style={{ opacity: loading ? 1 : 0 }} animation="border" className="spinner mb-10 mbt-10" />
          </div>
        </form>
      </div>
    </div>
  }
}

export default ResetPassword;

