import React, { useEffect, useState } from "react";
import { Alert, Spinner, Card, Row, Col, CardBody } from "reactstrap";
import ApiClient from "../../../ApiClient";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LineChart,
  Line,
} from "recharts";
import moment from "moment";
import CustomCard from "../../../components/CustomCard";

interface IByDays {
  data: string;
  n?: string;
  count?: number;
}

interface IData {
  today: number;
  lastSevenDays: number;
  lastThirtyDays: number;
  byDays: IByDays[];
}
const initData = (): IData => {
  return {
    today: 0,
    lastSevenDays: 0,
    lastThirtyDays: 0,
    byDays: [],
  };
};

const MyBarChart = ({
  data,
  xDataKey,
  yDataKey,
  width,
  max,
}: {
  data: IByDays[];
  xDataKey: string;
  yDataKey: string;
  width: string;
  max: number;
}) => {
  return (
    <ResponsiveContainer width={width}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} />
        <YAxis domain={[0, max]} />
        <Tooltip />
        <Line type="monotone" dataKey={yDataKey} fill="#768eb2" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ({
  title,
  api,
  startDate,
  endDate,
}: {
  title: string;
  api: (start: string, end: string) => Promise<{ data: IData }>;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(initData());
  //uso per renderizzare il grafico sul massimo numero nell'array
  const [max, setMax] = useState(50);
  useEffect(() => {
    if (startDate != null && endDate != null) loadData(startDate, endDate);
  }, [startDate, endDate]);

  const loadData = async (startDate: moment.Moment, endDate: moment.Moment) => {
    let data = initData();
    try {
      const response = await api(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      data = response.data;
      //riempio le giornate mancanti e formatto in DD/MM/YYYY
      const { byDays } = data;
      let formatted = [];
      let start = moment(byDays[0]?.data);
      let end = moment(byDays[byDays.length > 0 ? byDays.length - 1 : 0]?.data);
      while (start <= end) {
        const d = byDays.find(
          (d) =>
            moment(d.data).format("DD/MM/YYYY") ===
            moment(start).format("DD/MM/YYYY")
        );
        formatted.push({
          data: start.format("DD/MM/YYYY"),
          count: parseInt(d === undefined || d.n == undefined ? "0" : d.n),
        });
        start.add(1, "days");
      }
      data.byDays = formatted;
      //cerco massimo numero nell'array
      let max: number | undefined = 0;
      if (data.byDays.length > 0) {
        max = data.byDays.reduce(
          (max, p) =>
            p.count != undefined && max != undefined && p.count > max
              ? p.count
              : max,
          data.byDays[0].count
        );
      }
      if (max != undefined) setMax(max + 5); //metto un offset
      setError(false);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
      setData(data);
    }
  };

  if (loading) {
    return (
      <div className="wrap-spinner-central mb-10 mt-10">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    );
  } else if (error) {
    return (
      <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più
        tardi.
      </Alert>
    );
  } else
    return (
      <CustomCard>
        <CardBody>
          <Row>
            <Col md={12}>
              <h5>{title}</h5>
              <hr />
            </Col>
          </Row>
          <div
            className="d-flex align-items-start justify-space-between"
            style={{ height: "30vh" }}
          >
            <MyBarChart
              data={data.byDays}
              width={"85%"}
              xDataKey={"data"}
              yDataKey={"count"}
              max={max}
            />
            <div
              className="d-flex-column justify-content-between align-items-start ml-20"
              style={{ flex: 1 }}
            >
              <div className="mb-20">
                <h5>OGGI</h5>
                <h4 className="text-bold primary-color">{data.today}</h4>
              </div>
              <div className="mb-20">
                <h5>ULTIMI 7 GIORNI</h5>
                <h4 className="text-bold primary-color">
                  {data.lastSevenDays}
                </h4>
              </div>
              <div className="mb-20">
                <h5>ULTIMI 30 GIORNI</h5>
                <h4 className="text-bold primary-color">
                  {data.lastThirtyDays}
                </h4>
              </div>
            </div>
          </div>
        </CardBody>
      </CustomCard>
    );
};
