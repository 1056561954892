import React, { Component } from 'react';
import { Alert, Spinner, Card, CardBody, Col, Row, Button, Input, Label, FormGroup } from 'reactstrap';
import Controller from './Controller';
import ElencoSedi from '../../components/ListSedi';
import { withRouter } from '../../helper';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class View extends Component {

  state = {
    file: null,
    nuovaPasswordFieldType: "password",
    ripetiPasswordFieldType: "password",
    vecchiaPasswordFieldType: "password",
  }

  changePasswordFieldType = (fieldName) => {
    if (this.state[fieldName] == "password") {
      this.setState({ [fieldName]: "text" })
    } else {
      this.setState({ [fieldName]: "password" })
    }
  }

  handleChange = (event) => {
    this.setState({
      file: event.target.files.length == 0 ? null : URL.createObjectURL(event.target.files[0])
    })
    this.props.changeLogo(event)
  }

  renderUser = () => {
    const { user, password, } = this.props.state
    const { vecchiaPasswordFieldType, nuovaPasswordFieldType, ripetiPasswordFieldType } = this.state
    return <Card>
      <CardBody>
        <Row>
          <Col md={12}>
            <h4>
              Dati Utente
            </h4>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input type="text" name="nome" onChange={(evt) => this.props.setUserField("nome", evt.target.value)} value={user.nome} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="cognome">Cognome</Label>
              <Input type="text" name="cognome" onChange={(evt) => this.props.setUserField("cognome", evt.target.value)} value={user.cognome} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input type="text" name="username" disabled value={user.username} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="text" name="email" onChange={(evt) => this.props.setUserField("email", evt.target.value)} value={user.email} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <Button color="primary" onClick={this.props.submitUser} style={{ float: 'right' }}>Salva</Button>{' '}
          </Col>
          <Col md={12}>
            <hr />
          </Col>
          <Col md={12}>
            <h5>Reset Password</h5>
          </Col>
          <Col md={12}>
            <small>Inserisci la tua vecchia password e la nuova. Ricorda: la password deve essere lunga minimo 8 caratteri e contenere almeno un numero.</small>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="vecchia">Vecchia Password</Label>
              <div className={"d-flex align-items-center justify-content-center"}>
                <Input type={vecchiaPasswordFieldType} name="vecchia" onChange={(evt) => this.props.setPasswordField("vecchia", evt.target.value)} value={password.vecchia} />
                {
                  vecchiaPasswordFieldType == "password" ?
                    <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("vecchiaPasswordFieldType")} />
                    :
                    <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("vecchiaPasswordFieldType")} />
                }
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="nuova">Nuova Password</Label>
              <div className={"d-flex align-items-center justify-content-center"}>
                <Input type={nuovaPasswordFieldType} name="nuova" onChange={(evt) => this.props.setPasswordField("nuova", evt.target.value)} value={password.nuova} />
                {
                  nuovaPasswordFieldType == "password" ?
                    <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("nuovaPasswordFieldType")} />
                    :
                    <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("nuovaPasswordFieldType")} />
                }
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="ripetiNuova">Ripeti Nuova Password</Label>
              <div className={"d-flex align-items-center justify-content-center"}>
                <Input type={ripetiPasswordFieldType} name="ripetiNuova" onChange={(evt) => this.props.setPasswordField("ripetiNuova", evt.target.value)} value={password.ripetiNuova} />
                {
                  ripetiPasswordFieldType == "password" ?
                    <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("ripetiPasswordFieldType")} />
                    :
                    <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("ripetiPasswordFieldType")} />
                }
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label style={{ opacity: 0 }}>VUOTO</Label>
              <Button className={"form-control"} color="danger" onClick={this.props.submitPassword} style={{ float: 'right' }}>Modifica Password</Button>{' '}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  }
  renderNutrizionista = () => {
    const { nutrizionista } = this.props.state
    return <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <h4>
                Dati Nutrizionista
              </h4>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="ragsociale">Ragione Sociale</Label>
                <Input type="text" name="ragsociale" onChange={(evt) => this.props.setNutrizionistaField("ragsociale", evt.target.value)} value={nutrizionista.ragsociale} />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="nome">Nome</Label>
                <Input type="text" name="nome" onChange={(evt) => this.props.setNutrizionistaField("nome", evt.target.value)} value={nutrizionista.nome} />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="cognome">Cognome</Label>
                <Input type="text" name="cognome" onChange={(evt) => this.props.setNutrizionistaField("cognome", evt.target.value)} value={nutrizionista.cognome} />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="email">e-mail</Label>
                <Input type="text" name="email" onChange={(evt) => this.props.setNutrizionistaField("email", evt.target.value)} value={nutrizionista.email} />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="rescheduleorari">Reschedule orari</Label>
                <Input type="select" className="form-control" name="select" id="rescheduleorari" onChange={(evt) => this.props.setNutrizionistaField("rescheduleorari", evt.target.value)} value={nutrizionista.rescheduleorari} >
                  <option value="y">Si</option>
                  <option value="n">No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={12}>
              <Button color="primary" onClick={this.props.submitNutrizionista} style={{ float: 'right' }}>Salva</Button>{' '}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <h4>
                Logo Nutrizionista
              </h4>
            </Col>
            <Col md={6}>
              <div style={{ width: '80%', height: '15vh', borderWidth: 1, borderColor: 'black', justifyContent: 'center' }} className={"flexIt"}>
                <img
                  src={this.state.file == null ? `${process.env.REACT_APP_BACKEND_URL}/${nutrizionista.cover}` : this.state.file}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}></img>
              </div>
            </Col>
            <Col md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <FormGroup>
                <Input type="file" accept={"image/*"} onChange={this.handleChange} />
                <small>Dimensioni massime: 500x150 (300kb)</small>
              </FormGroup>
            </Col>
            <Col md={12}>
              <Button color="primary" onClick={this.props.submitLogoNutrizionista} style={{ float: 'right' }}>Salva</Button>{' '}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  }

  render() {
    const { error, loading, } = this.props.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Row>
          <Col md={12}>
            {this.renderNutrizionista()}
          </Col>
          <Col md={12}>
            {this.renderUser()}
          </Col>
          <Col md={12}>
            <ElencoSedi />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(Controller(View));