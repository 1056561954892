import React, { Component } from 'react';
import '../../style.css'
import { Navigate } from 'react-router-dom';
import ApiClient from '../../ApiClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd, faLock, faEye, faEyeSlash, } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'reactstrap';
import { APP_NAME } from '../../constants';
import jwtDecode from 'jwt-decode';
//is the user logged in?
const isLogged = window.localStorage.getItem("token") != null

class Login extends Component {

  apiClient = new ApiClient()
  state = {
    username: "",
    password: "",
    newPassword1: "",
    newPassword2: "",
    passwordFieldType: "password",
    ripetiPasswordFieldType: "password",
    aggiornaPasswordFieldType: "password",
    /*gestisce la UI da mostrare: base, temp_password, reset_password*/
    ui: "base",
  }

  login = async (evt) => {
    evt.preventDefault()
    this.setState({ loading: true })
    const { username, password } = this.state
    let response = null
    try {
      response = await this.apiClient.login(username, password)
      if (response.success) {
        //has the user to change the password?
        const tkn = jwtDecode(response.token)
        if (tkn.tp) {
          this.setState({
            ui: "temp_password"
          })
        }
        else {
          window.localStorage.setItem("token", response.token)
          window.location.href = "/"
        }
      }
      else {
        throw new Error("error")
      }
    } catch (err) {//dati errati!
      window.alert("Dati errati!")
    }
    this.setState({ loading: false })
  }

  changePasswordFieldType = (fieldName) => {
    if (this.state[fieldName] == "password") {
      this.setState({ [fieldName]: "text" })
    } else {
      this.setState({ [fieldName]: "password" })
    }
  }

  //procedura che prende in input l'username indicato nel form e richiede il reset della password
  resetPassword = async (evt) => {
    evt.preventDefault()
    this.setState({ loading: true })
    const { username, } = this.state
    if (username == "") {
      window.alert("Inserisci il tuo username nell'apposito campo e premi 'Reset Password'.")
    } else {
      let response = null
      try {
        response = await this.apiClient.askResetPassword(username)
        if (response.success) {
          window.alert("Ti abbiamo mandato una mail con le istruzioni per il reset della password.")
          this.setState({
            ui: "base"
          })
        }
      } catch (err) {//dati errati!
        window.alert("Operazione non riuscita.")
      }
    }
    this.setState({ loading: false })
  }

  //procedura che porta al cambio password a seguito di login e temp_password=true
  changePassword = async (evt) => {
    evt.preventDefault()
    this.setState({ loading: true })
    const { username, password, newPassword1, newPassword2 } = this.state
    if (newPassword1 !== newPassword2) {
      window.alert("Le due password non coincidono!")
      return;
    }
    //controllo lunghezza e presenza di almeno 1 numero
    if (!/^(?=.*?[a-zA-Z])(?=.*?[0-9])[\w@#$%^?~-]{8,16}$/.test(newPassword1)) {
      window.alert("Attenzione: la password deve essere lunga tra 8 e 16 caratteri, e contenere almeno un numero.")
    } else {
      let response = null
      try {
        response = await this.apiClient.changePassword(username, password, newPassword1)
        if (response.success) {
          window.alert("Password aggiornata, puoi effettuare il login.")
          window.location.href = "/"
        }
        else {
          throw new Error("error")
        }
      } catch (err) {//dati errati!
        window.alert("Dati errati!")
      }
    }
    this.setState({ loading: false })
  }

  render() {
    const { username, password, ui, newPassword1, newPassword2, loading, passwordFieldType, ripetiPasswordFieldType, aggiornaPasswordFieldType } = this.state
    let LoginUI = null
    if (isLogged)
      return <Navigate to={{ pathname: '/', }} />
    if (ui === "base") {
      LoginUI = <div className="login-container">
        <form onSubmit={this.login} className="login-box">
          <img src="/logo.png" className={"logo-img"} />
          <h1>{APP_NAME}</h1>
          <small>Inserisci di seguito i tuoi dati di accesso.</small>
          <div className={"input-container"}>
            <div className={"input-label"}>
              USERNAME / E-MAIL
            </div>
            <div className={"input-element-container"}>
              <FontAwesomeIcon icon={faUserMd} size={"lg"} color={"#cecece"} />
              <input className="input-element" type="input" onChange={(evt) => this.setState({ username: evt.target.value })} value={username} placeholder="Inserisci il tuo username o la tua e-mail" />
            </div>
          </div>
          <div className={"input-container"}>
            <div className={"input-label"}>
              PASSWORD
            </div>
            <div className={"input-element-container"}>
              <FontAwesomeIcon icon={faLock} size={"lg"} color={"#cecece"} />
              <input className="input-element" type={passwordFieldType} onChange={(evt) => this.setState({ password: evt.target.value })} value={password} placeholder="Password" />
              {
                passwordFieldType == "password" ?
                  <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("passwordFieldType")} />
                  :
                  <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("passwordFieldType")} />
              }
            </div>
          </div>
          <input className="submit-btn login-btn" type="submit" value="LOGIN" />
          <Spinner style={{ opacity: loading ? 1 : 0 }} animation="border" className="spinner mb-10 mbt-10" />
          <span onClick={() => this.setState({ ui: "reset_password" })} className="password-dimenticata-btn" > Password dimenticata?</span>
        </form>
      </div >
    } else if (ui === "temp_password") {
      LoginUI = <div className="login-container">
        <form onSubmit={this.changePassword} className="login-box">
          <h1>{APP_NAME}</h1>
          <small>Devi aggiornare la password.</small>
          <div className={"input-container"}>
            <div className={"input-label"}>
              PASSWORD
            </div>
            <div className={"input-element-container"}>
              <FontAwesomeIcon icon={faLock} size={"lg"} color={"#cecece"} />
              <input className="input-element" type={aggiornaPasswordFieldType} onChange={(evt) => this.setState({ newPassword1: evt.target.value })} value={newPassword1} placeholder="Nuova password" />
              {
                aggiornaPasswordFieldType == "password" ?
                  <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("aggiornaPasswordFieldType")} />
                  :
                  <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("aggiornaPasswordFieldType")} />
              }
            </div>
          </div>
          <div className={"input-container"}>
            <div className={"input-label"}>
              RIPETI PASSWORD
            </div>
            <div className={"input-element-container"}>
              <FontAwesomeIcon icon={faLock} size={"lg"} color={"#cecece"} />
              <input className="input-element" type={ripetiPasswordFieldType} onChange={(evt) => this.setState({ newPassword2: evt.target.value })} value={newPassword2} placeholder="Ripeti password" />
              {
                ripetiPasswordFieldType == "password" ?
                  <FontAwesomeIcon icon={faEye} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("ripetiPasswordFieldType")} />
                  :
                  <FontAwesomeIcon icon={faEyeSlash} size={"lg"} className='cursorPointer' style={{ minWidth: "20px" }} color={"#768eb2"} onClick={() => this.changePasswordFieldType("ripetiPasswordFieldType")} />
              }
            </div>
          </div>
          <input className="submit-btn login-btn" type="submit" value="CAMBIA PASSWORD" />
        </form>
      </div>
    } else if (ui === "reset_password") {
      LoginUI = <div className="login-container">
        <form onSubmit={this.resetPassword} className="login-box">
          <h1>{APP_NAME}</h1>
          <small>Inserisci di seguito il tuo username o la tua e-mail.</small>
          <div className={"input-container"}>
            <div className={"input-label"}>
              USERNAME / E-MAIL
            </div>
            <div className={"input-element-container"}>
              <FontAwesomeIcon icon={faUserMd} size={"lg"} color={"#cecece"} />
              <input className="input-element" type="input" onChange={(evt) => this.setState({ username: evt.target.value })} value={username} placeholder="Username" />
            </div>
          </div>
          <input className="submit-btn login-btn" type="submit" value="RESET PASSWORD" />
          <Spinner style={{ opacity: loading ? 1 : 0 }} animation="border" className="spinner mb-10 mbt-10" />
          <span onClick={() => this.setState({ ui: "base" })} className="password-dimenticata-btn" > Ritorna al Login</span>
        </form>
      </div >
    }
    return <div className={"flexIt"} style={{ backgroundColor: '#fff' }}>
      <div className="homepage">
        <div className={"ghirigoro"} />
        <div className={"img-logo-container"}>
          <img src="/logo.png" className={"logo-img"} />
        </div>
      </div>
      {LoginUI}
    </div>
  }
}

export default Login;

