import React, { useState, } from 'react';
import './style.css'
import Table from '../Table';
import { useSedi } from '../../state/sedi'
import { Button, Card, CardBody, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Alert from 'react-s-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ModalSede from '../ModalSede';

const initSede = () => {
  return {
    id: null,
    nome: "",
    via: "",
    citta: "",
    telefono1: "",
    telefono2: ""
  }
}
export default (props) => {

  const { sedi, isLoading, isError, removeSede } = useSedi()
  const [isOpen, toggle] = useState(false)

  const [hideDisabilitate, setHideDisabilitate] = useState(true)
  //passata al modal di creazione/edit
  let [sede, setSede] = useState(initSede())

  const deleteSede = async (sede) => {
    if (window.confirm("Sei sicuro di voler disabilitare questa sede?")) {
      const result = await removeSede(sede)
      if (result)
        Alert.success("Sede disabilitata con successo!")
      else
        Alert.error("Errore, impossibile eseguire l'operazione.")
    }
  }

  //apre il modal con l'oggetto sede passato
  const openModal = (s) => {
    setSede(s)
    toggle(true)
  }

  const columns = [
    {
      name: 'Nome',
      selector: row => row.nome,
    },
    {
      name: 'Indirizzo',
      selector: row => row.via,
    },
    {
      name: 'Contatti',
      selector: row => `${row.telefono1}, ${row.telefono2}`
    },
    {
      name: 'Azioni',
      selector: row => {
        if (row.disabilitata == 'y')
          return "Disabilitata"
        else return <>
          <Button color="primary" className="mr-10" onClick={() => openModal(row)}>Modifica</Button>
          <Button color="danger" onClick={() => deleteSede(row)}>Disabilita</Button>
        </>
      }
    },
  ]

  return <Card>
    <CardBody>
      <ModalSede isOpen={isOpen} toggle={toggle} sede={sede} setSede={setSede} />
      <Row>
        <Col md={12} className={"d-flex align-items-start justify-content-between"}>
          <h4>
            Sedi
          </h4>
          <div class="d-flex align-items-center justify-content-center">
            <FormGroup check className={"mr-10"}>
              <Label check>
                <Input type="checkbox" checked={hideDisabilitate} onChange={(evt) => setHideDisabilitate(evt.target.checked)} />{' '}
                Nascondi sedi disabilitate
              </Label>
            </FormGroup>
            <Button
              size="sm"
              outline
              color="primary"
              onClick={() => {
                setSede(initSede())
                toggle(true)
              }}><FontAwesomeIcon icon={faPlus} />&nbsp;Aggiungi</Button>

          </div>
        </Col>
      </Row>
      <Table
        data={hideDisabilitate ? sedi.filter(s => s.disabilitata == 'n') : sedi}
        columns={columns} />
    </CardBody>
  </Card>
}