import React from 'react';
import ApiClient from '../../ApiClient'
import moment from 'moment';
import Alert from 'react-s-alert';

//controlla se uno dei valori registrati nella visita è null, stringa vuota o un numero valido
const isNumber = (value) => {
  return value === "" || value == null || (typeof value === 'number' && isFinite(value));
}

// This function takes a component...
export default function Controller(WrappedComponent,) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()


    state = {
      loading: true,
      error: false,
      paziente: {
        nome: "",
        cognome: "",
      },
      visita: {
        id: null,
        data: moment(),
        note: "",
        altezza: null,
        bmi: null,
        peso: null,
        polso: null,
        polsosx: null,
        avambracciosx: null,
        bracciosx: null,
        radicecosciasx: null,
        cosciasx: null,
        avambraccio: null,
        braccio: null,
        vita: null,
        ombelicale: null,
        fianchi: null,
        radicecoscia: null,
        coscia: null,
        massagrassa: null,
        massamagra: null,
        massamagraperc: null,
        massagrassaperc: null,
        metabolismobasale: null,
        pesoforma: null,
        plicatricipitale: null,
        plicasottoscapolare: null,
        pressioneminima: null,
        pressionemassima: null,
        rapportovitafianchi: null,
        tbw: null,
        icw: null,
        ecw: null,
        bmr: null,
        bcm: null,
        ecm: null,
        rapportoecmbcm: null,
        bcmi: null,
        sm: null,
        smi: null,
        rapportosodiopotassio: null,
        rapportopotassiopeso: null,
        rapportovitafianchi: null,
      },
      bmiSuggerito: 0,
      massamagraPercentuale: null,
      massagrassaPercentuale: null,
    }

    componentDidMount() {
      this.loadPaziente()
      //se è una visualizzazione, scarico dati altezza
      if (this.props.router.params.idvisita !== "add") {
        this.loadVisita()
      }
      else {//se è una nuova visita, scarico ultima altezza conosciuta
        this.loadLastAltezza()
      }
    }


    loadPaziente = async () => {
      try {
        const pid = this.props.router.params.id
        const paziente = await this.apiClient.getPazienteById(pid)
        const p = {
          nome: paziente.data.nome,
          cognome: paziente.data.cognome,
        }
        this.setState({
          paziente: p
        })
      } catch (err) {
        this.setState({
          paziente: { nome: "", cognome: "" }
        })
      }
    }

    loadVisita = async (idvisita = null) => {
      const pid = this.props.router.params.id
      //se provengo da un "add", stesso in submit passo nuovo id a loadVisita
      //se provengo da "Visualizza" visita già esistente, lo prendo da URL
      if (idvisita == null) {
        idvisita = this.props.router.params.idvisita
      }
      try {
        const response = await this.apiClient.getVisitaPazienteById(pid, idvisita)
        response.data = moment(response.data)
        this.setState({
          visita: response,
          loading: false
        })
      } catch (err) {
        this.setState({
          error: true,
          loading: false
        })
      }
    }

    //quando creo una visita, autoinserisco l'ultima altezza conosciuta!
    loadLastAltezza = async () => {
      const pid = this.props.router.params.id
      const { visita } = this.state
      let { altezza } = visita

      try {
        const data = await this.apiClient.getVisitePaziente(pid)
        //preparo i dati delle ultime 20 visite
        for (let i = 0; i < data.length; i++) {
          if (data[i].altezza != null) {
            altezza = data[i].altezza;
            break;
          }
        }
      } catch (err) { }//niente alteza, fa nulla
      visita.altezza = altezza
      this.setState({
        visita,
        loading: false,
      })
    }

    elimina = async (id) => {
      const pid = this.props.router.params.id
      const idvisita = this.props.router.params.idvisita
      if (window.confirm("Sei sicuro di voler eliminare la visita?")) {
        try {
          const response = await this.apiClient.eliminaVisitaPaziente(pid, idvisita)
          Alert.success("Visita eliminata!")
          this.props.router.navigate(`/users/${this.props.router.params.id}`)
        } catch (err) {
          Alert.error("Errore, impossibile eliminare la visita.")
        }
      }
    }

    //quando si aggiorna altezza o peso, si ricalcola automaticamente il BMi
    onBlur = (field) => {
      if (field == "altezza" || field == "peso") {
        const { altezza, peso } = this.state.visita
        if (altezza == null && peso == null) {
          this.setState({ bmiSuggerito: 0 })
        } else {
          try {
            //altezza è in cm, la porto in metri
            let bmi = peso / ((altezza / 100) * (altezza / 100))
            //round a 2 cifre decimali
            bmi = parseFloat(Math.round(bmi * 100) / 100)
            if (bmi == Infinity || bmi == NaN || !(typeof bmi === 'number' && isFinite(bmi)))
              this.setState({ bmiSuggerito: 0 })
            else
              this.setState({ bmiSuggerito: bmi })
          } catch (err) {//errori di calcolo
            console.log(err)
            this.setState({ bmiSuggerito: 0 })
          }
        }
      }
      else if (field == "massamagra") {
        let { massamagraPercentuale, visita } = this.state
        try {
          massamagraPercentuale = visita.massamagra * 100 / visita.peso
          if (massamagraPercentuale == Infinity || massamagraPercentuale == 0 || massamagraPercentuale == NaN || !(typeof massamagraPercentuale === 'number' && isFinite(massamagraPercentuale)))
            this.setState({ massamagraPercentuale: null })
          else
            this.setState({ massamagraPercentuale: parseFloat(massamagraPercentuale.toFixed(2)) })
        } catch (err) {
          this.setState({ massamagraPercentuale: null })
        }
      }
      else if (field == "massagrassa") {
        let { massagrassaPercentuale, visita } = this.state
        try {
          massagrassaPercentuale = visita.massagrassa * 100 / visita.peso
          if (massagrassaPercentuale == Infinity || massagrassaPercentuale == 0 || massagrassaPercentuale == NaN || !(typeof massagrassaPercentuale === 'number' && isFinite(massagrassaPercentuale)))
            this.setState({ massagrassaPercentuale: null })
          else
            this.setState({ massagrassaPercentuale: parseFloat(massagrassaPercentuale.toFixed(2)) })
        } catch (err) {
          this.setState({ massagrassaPercentuale: null })
        }
      }
      else if (field == "massagrassaperc") {
        let { massagrassa, visita } = this.state
        try {
          massagrassa = visita.massagrassaperc / 100 * visita.peso
          if (massagrassa == Infinity || massagrassa == NaN || massagrassa == 0 || !(typeof massagrassa === 'number' && isFinite(massagrassa)))
            this.setState({ massagrassa: null })
          else
            this.setState({ massagrassa: parseFloat(massagrassa.toFixed(2)) })
        } catch (err) {
          this.setState({ massagrassa: null })
        }
      }
      else if (field == "massamagraperc") {
        let { massamagra, visita } = this.state
        try {
          massamagra = visita.massamagraperc / 100 * visita.peso
          if (massamagra == Infinity || massamagra == NaN || massamagra == 0 || !(typeof massamagra === 'number' && isFinite(massamagra)))
            this.setState({ massamagra: null })
          else
            this.setState({ massamagra: parseFloat(massamagra.toFixed(2)) })
        } catch (err) {
          this.setState({ massamagra: null })
        }
      }
    }

    setBMISuggerito = () => {
      const { visita, bmiSuggerito } = this.state
      visita.bmi = bmiSuggerito
      this.setState({ visita })
    }

    setMassamagrapercSuggerito = () => {
      const { visita, massamagraPercentuale } = this.state
      visita.massamagraperc = massamagraPercentuale
      this.setState({ visita })
    }

    setMassagrassapercSuggerito = () => {
      const { visita, massagrassaPercentuale } = this.state
      visita.massagrassaperc = massagrassaPercentuale
      this.setState({ visita })
    }

    setMassamagraSuggerito = () => {
      const { visita, massamagra } = this.state
      visita.massamagra = massamagra
      this.setState({ visita })
    }

    setMassagrassaSuggerito = () => {
      const { visita, massagrassa } = this.state
      visita.massagrassa = massagrassa
      this.setState({ visita })
    }

    submit = async () => {
      const pid = this.props.router.params.id
      const { visita } = this.state
      const success = this.validate(visita)
      if (success) {
        if (this.props.router.params.idvisita === "add") {//creo!
          try {
            const result = await this.apiClient.creaVisitaPaziente(pid, visita)
            Alert.success("Visita creata!")
            this.props.router.navigate(`/users/${this.props.router.params.id}/visite/${result.data}`)
            this.loadVisita(result.data)
          } catch (err) {
            Alert.error("Operazione fallita.")
          }
        } else {//aggiorno!
          try {
            await this.apiClient.updateVisitaPaziente(pid, visita)
            Alert.success("Visita aggiornata!")
          } catch (err) {
            Alert.error("Operazione fallita.")
          }
        }
      } else {
        Alert.error("Assicurarsi che i campi inseriti siano numerici e che la data sia valida.")
      }
    }

    //gestisce i valori dell'oggetto dieta che non siano le misurazioni
    changeFormValue = (fieldName, val) => {
      const { visita } = this.state
      visita[fieldName] = val
      this.setState({
        visita
      })
    }

    //gestisce i valori delle misurazioni della visita
    changeFormNumberValue = (fieldName, val) => {
      const { visita } = this.state
      if (val === "") {
        visita[fieldName] = null
      } else {
        //verifico sia un numero valido
        const value = parseFloat(val)
        if (isNumber(value)) {
          visita[fieldName] = value
        }
      }
      this.setState({
        visita
      })
    }

    validate = (visita) => {
      if (!moment(visita.data).isValid() ||
        !isNumber(visita.altezza) ||
        !isNumber(visita.bmi) ||
        !isNumber(visita.peso) ||
        !isNumber(visita.polso) ||
        !isNumber(visita.polsosx) ||
        !isNumber(visita.avambracciosx) ||
        !isNumber(visita.bracciosx) ||
        !isNumber(visita.cosciasx) ||
        !isNumber(visita.radicecosciasx) ||
        !isNumber(visita.avambraccio) ||
        !isNumber(visita.braccio) ||
        !isNumber(visita.vita) ||
        !isNumber(visita.ombelicale) ||
        !isNumber(visita.fianchi) ||
        !isNumber(visita.radicecoscia) ||
        !isNumber(visita.coscia) ||
        !isNumber(visita.massagrassa) ||
        !isNumber(visita.massamagra) ||
        !isNumber(visita.massamagraperc) ||
        !isNumber(visita.massagrassaperc) ||
        !isNumber(visita.grassoviscerale) ||
        !isNumber(visita.metabolismobasale) ||
        !isNumber(visita.pesoforma) ||
        !isNumber(visita.plicatricipitale) ||
        !isNumber(visita.plicasottoscapolare) ||
        !isNumber(visita.pressioneminima) ||
        !isNumber(visita.pressionemassima) ||
        !isNumber(visita.rapportovitafianchi) ||
        !isNumber(visita.tbw) ||
        !isNumber(visita.icw) ||
        !isNumber(visita.ecw) ||
        !isNumber(visita.bmr) ||
        !isNumber(visita.bcm) ||
        !isNumber(visita.ecm) ||
        !isNumber(visita.rapportoecmbcm) ||
        !isNumber(visita.bcmi) ||
        !isNumber(visita.sm) ||
        !isNumber(visita.smi) ||
        !isNumber(visita.rapportosodiopotassio) ||
        !isNumber(visita.rapportopotassiopeso) ||
        !isNumber(visita.rapportovitafianchi)) {
        return false
      } else return true

    }


    render() {
      return <WrappedComponent
        {...this.props}
        state={this.state}
        submit={this.submit}
        onBlur={this.onBlur}
        elimina={this.elimina}
        segnaHaPagato={this.segnaHaPagato}
        changeFormValue={this.changeFormValue}
        changeFormNumberValue={this.changeFormNumberValue}
        setBMISuggerito={this.setBMISuggerito}
        setMassamagrapercSuggerito={this.setMassamagrapercSuggerito}
        setMassagrassapercSuggerito={this.setMassagrassapercSuggerito}
        setMassagrassaSuggerito={this.setMassagrassaSuggerito}
        setMassamagraSuggerito={this.setMassamagraSuggerito}
      />
    }
  }
}
