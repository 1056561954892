import React from "react";
import { Alert } from "reactstrap";

const ALERT_BG_COLOR: any = {
  success: "success",
  error: "danger",
  warning: "warning",
  info: "info",
};
const MyCustomContentTemplate = (props: any) => {
  return (
    <Alert
      style={{ ...props.styles, right: 15 }}
      className="my-s-alert-box s-alert-bottom-right s-alert-is-effect s-alert-effect-slide s-alert-show"
      color={ALERT_BG_COLOR[props.condition]}
      id={props.id}
    >
      <div className="s-alert-box-inner">{props.message}</div>
    </Alert>
  );
};

export default MyCustomContentTemplate;
