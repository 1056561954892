import React, { useState } from "react";
import StatsCounter from "./StatsCounter";
import CardsStats from "./CardsStats";
import ApiClient from "../../../ApiClient";
import { Col, Row } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    moment().subtract(30, "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
  const [isStartDateFocus, setIsStartDateFocus] = useState<boolean>(false);
  const [isEndDateFocus, setIsEndDateFocus] = useState<boolean>(false);

  return (
    <>
      <CardsStats />
      &nbsp;&nbsp;&nbsp;&nbsp;Dal&nbsp;&nbsp;
      <SingleDatePicker
        isOutsideRange={() => false}
        numberOfMonths={1}
        showDefaultInputIcon
        date={startDate}
        onDateChange={setStartDate} // PropTypes.func.isRequired
        focused={isStartDateFocus} // PropTypes.bool
        onFocusChange={({ focused }) => setIsStartDateFocus(focused)} // PropTypes.func.isRequired
        id="start>Date"
        small
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Al&nbsp;&nbsp;
      <SingleDatePicker
        isOutsideRange={() => false}
        numberOfMonths={1}
        showDefaultInputIcon
        date={endDate}
        onDateChange={setEndDate} // PropTypes.func.isRequired
        focused={isEndDateFocus} // PropTypes.bool
        onFocusChange={({ focused }) => setIsEndDateFocus(focused)} // PropTypes.func.isRequired
        id="start>Date"
        small
      />
      <br />
      <br />
      <StatsCounter
        title={"Visualizzazioni"}
        startDate={startDate}
        endDate={endDate}
        api={new ApiClient().getAdminStatsViews}
      />
      <StatsCounter
        title={"Nuovi Pazienti"}
        startDate={startDate}
        endDate={endDate}
        api={new ApiClient().getAdminStatsPazienti}
      />
      <StatsCounter
        title={"Notifiche Generate"}
        startDate={startDate}
        endDate={endDate}
        api={new ApiClient().getAdminStatsNotifiche}
      />
    </>
  );
};

export default AdminDashboard;
