import React, { Component } from 'react';
import { CardBody, Col, Row } from 'reactstrap'
import {
  XAxis, YAxis, CartesianGrid, LineChart, Line, Tooltip, ResponsiveContainer,
} from 'recharts';
import ApiClient from '../../ApiClient'
import CustomCard from '../../components/CustomCard';

//custom tooltip per i grafici delle misurazioni
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p><strong>Data: </strong>{label}</p>
        <p><strong>Valore: </strong>{payload[0].value}</p>
        <p><strong>Differenza: </strong>{payload[0].payload.diff}</p>
      </div>
    );
  }
}

const mappingCharts = {
  andamentoPeso: "Peso",
  andamentobmi: "BMI",
  andamentograssoviscerale: "Grasso Viscerale",
  andamentopolso: "Polso Dx",
  andamentoavambraccio: "Avambraccio Dx",
  andamentobraccio: "Braccio Dx",
  andamentovita: "Vita",
  andamentoombelicale: "Ombelicale",
  andamentofianchi: "Fianchi",
  andamentoradicecoscia: "Radice Coscia Dx",
  andamentocoscia: "Coscia Dx",
  andamentomassamagra: "Massa Magra",
  andamentomassagrassa: "Massa Grassa",
  andamentorapportovitafianchi: "Rapporto Vita Fianchi",
  andamentopolsosx: "Polso Sx",
  andamentoavambracciosx: "Avambraccio Sx",
  andamentobracciosx: "Braccio Sx",
  andamentocosciasx: "Coscia Sx",
  andamentoradicecosciasx: "Radice Coscia Sx",
}

class Progressi extends Component {
  state = {
    visite: [],
    loading: true,
    error: false,
    progressi: {
      andamentoPeso: [],
      andamentobmi: [],
      andamentopolso: [],
      andamentoavambraccio: [],
      andamentobraccio: [],
      andamentovita: [],
      andamentoombelicale: [],
      andamentofianchi: [],
      andamentoradicecoscia: [],
      andamentocoscia: [],
      andamentomassamagra: [],
      andamentomassagrassa: [],
      andamentorapportovitafianchi: [],
      andamentopolsosx: [],
      andamentoavambracciosx: [],
      andamentobracciosx: [],
      andamentocosciasx: [],
      andamentoradicecosciasx: [],
      andamentograssoviscerale: [],
    }
  }

  apiClient = new ApiClient()

  componentDidMount() {
    this.loadProgressi()
  }

  loadProgressi = async () => {
    const pid = this.props.pid
    let { progressi } = this.state
    try {
      const progressi = await this.apiClient.getProgressiPaziente(pid)
      this.setState({
        progressi: progressi.data,
        loading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({
        error: true,
        loading: false,
        progressi
      })
    }
  }

  //renderizza un grafico di linea con l'andamento di un parametro di visita. il nome del parametro è passato nella funzione
  renderChart = (label, parametro) => {
    const { progressi } = this.state
    let data = progressi[parametro]
    //pulisco da valori nulli o undefined
    data = data.filter(d => d.value !== null && d.value !== undefined && d.value !== "")
    //aggiungo differenza col valore precedente della serie
    data = data.map((d, idx) => {
      let diff = "-"
      if (idx > 0 && !isNaN(parseFloat(d.value)) && !isNaN(parseFloat(data[idx - 1].value))) {
        diff = parseFloat(d.value) - parseFloat(data[idx - 1].value)
        if (diff > 0) {
          diff = "+ " + Math.round(diff * 100) / 100
        } else if (diff < 0) {
          diff = "- " + Math.abs(Math.round(diff * 100) / 100)
        }
      }
      return { ...d, diff }
    })
    //trovo min e max per creare un dominio Y intorno a quei valori
    let min = -9999
    let max = 9999
    if (data != undefined && data.length > 0) {
      min = data.reduce((min, p) => p.value < min ? p.value : min, data[0].value);
      max = data.reduce((max, p) => p.value > max ? p.value : max, data[0].value);
    }
    return <CustomCard className="box-progressi-container">
      <CardBody style={{ width: "100%", height: "30vh" }}>
        <div className="box-progressi-label">{label}</div>
        {
          (data == undefined || data.length == 0) ?
            <div className="height-75p d-flex justify-content-center align-items-center">Non ci sono ancora dati</div>
            :
            <ResponsiveContainer>
              <LineChart
                data={data}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis domain={[min, max]} dataKey="data" />
                <Tooltip content={CustomTooltip} />
                <Line dataKey="value" fill="#fff" color="#1196d9" are type="monotone" />
              </LineChart>
            </ResponsiveContainer>
        }
      </CardBody>
    </CustomCard>
  }

  render() {
    return <div className="animated fadeIn">
      <Row>
        {
          Object.keys(mappingCharts).map(key => <Col md={12}>
            {this.renderChart(
              mappingCharts[key],
              key)}
          </Col>)
        }
      </Row>
    </div>
  }
}

export default Progressi;

