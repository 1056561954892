import React, { Component } from 'react';
import { Alert, Spinner, Card, CardBody, FormGroup, Input, Label, Col, Row, Button } from 'reactstrap';
import Controller from './Controller';
import './style.css'
import { withRouter } from '../../../helper';

class View extends Component {

  render() {
    const { data } = this.props.state
    const { nutrizionista, users } = data
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="flexIt">
                <h4>Creazione Account Nutrizionista</h4>
                <Button onClick={this.props.submit} color="primary">Salva</Button>
              </Col>
            </Row>
            <hr />
            <h5>Dati nutrizionista</h5>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="ragsociale">Ragione Sociale</Label>
                  <Input type="text" name="ragsociale" onChange={(evt) => this.props.setDatiNutrizionista("ragsociale", evt.target.value)} value={nutrizionista.ragsociale} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="nome">Nome</Label>
                  <Input type="text" name="nome" onChange={(evt) => this.props.setDatiNutrizionista("nome", evt.target.value)} value={nutrizionista.nome} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="cognome">Cognome</Label>
                  <Input type="text" name="cognome" onChange={(evt) => this.props.setDatiNutrizionista("cognome", evt.target.value)} value={nutrizionista.cognome} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="cognome">Email</Label>
                  <Input type="email" name="email" onChange={(evt) => this.props.setDatiNutrizionista("email", evt.target.value)} value={nutrizionista.email} />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="pazienti_rimanenti">Pacchetto pazienti</Label>
                  <Input type="select" className="form-control" name="pazienti_rimanenti" onChange={(evt) => this.props.setDatiNutrizionista("pazienti_rimanenti", evt.target.value)} value={nutrizionista.pazienti_rimanenti} >
                    <option value={10}>Trial - 10 pazienti</option>
                    <option value={20}>Standard - 20 pazienti</option>
                    <option value={50}>Premium - 50 pazienti</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <div className={"d-flex align-items-center justify-content-between"}>
              <h5>
                Dati utente
              </h5>
              <Button small color={"primary"} onClick={this.props.aggiungiUser}>Aggiungi Utente</Button>
            </div>
            {
              users.map((u, i) => <Row form className={"container-dati-utente"}>
                <Col md={4}>
                  <FormGroup>
                    <Label for="nome">Nome</Label>
                    <Input type="text" name="nome" onChange={(evt) => this.props.setDatiUsers(i, "nome", evt.target.value)} value={u.nome} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="cognome">Cognome</Label>
                    <Input type="text" name="cognome" onChange={(evt) => this.props.setDatiUsers(i, "cognome", evt.target.value)} value={u.cognome} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="email">E-mail</Label>
                    <Input type="email" name="email" onChange={(evt) => this.props.setDatiUsers(i, "email", evt.target.value)} value={u.email} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="nome">Username</Label>
                    <Input type="text" name="nome" onChange={(evt) => this.props.setDatiUsers(i, "username", evt.target.value)} value={u.username} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input type="text" name="password" onChange={(evt) => this.props.setDatiUsers(i, "password", evt.target.value)} value={u.password} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="repeat_password">Ripeti la Password</Label>
                    <Input type="text" name="repeat_password" onChange={(evt) => this.props.setDatiUsers(i, "repeat_password", evt.target.value)} value={u.repeat_password} />
                  </FormGroup>
                </Col>
                <Col md={12} className="d-flex justify-content-end">
                  <Button color="danger" onClick={() => this.props.rimuoviUser(i)}>Rimuovi</Button>
                </Col>
              </Row>
              )}
          </CardBody>
        </Card>

      </div >
    )
  }
}

export default withRouter(Controller(View));