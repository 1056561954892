import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

// This function takes a component...
export default function Controller(WrappedComponent,) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()
    state = {
      visite: [],
      loading: true,
      error: false,

    }

    componentDidMount() {
      this.loadVisite()
    }

    loadVisite = async () => {
      const pid = this.props.router.params.id
      try {
        const response = await this.apiClient.getVisitePaziente(pid)
        this.setState({
          visite: response,
          loading: false,
          error: false
        })
      } catch (err) {
        console.log(err)
        this.setState({
          error: true,
          loading: false,
        })
      }

    }


    elimina = async (idvisita) => {

      const pid = this.props.router.params.id
      if (window.confirm("Sei sicuro di voler eliminare la visita?")) {
        try {
          await this.apiClient.eliminaVisitaPaziente(pid, idvisita)
          Alert.success("Visita eliminata!")
          this.loadVisite()
        } catch (err) {
          Alert.error("Errore, impossibile eliminare la visita.")
        }
      }
    }

    render() {
      return <WrappedComponent
        {...this.props}
        elimina={this.elimina}
        state={this.state}
      />
    }
  }
}
