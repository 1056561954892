
import JoiBase from "@hapi/joi";
import JoiDate from "@hapi/joi-date";
const Joi = JoiBase.extend(JoiDate);

//la validation tra creazione e aggiornamento sarà diversa, 
//perchè nella prima ci sono anche username e password, mentre solo nella seconda ci sarà id
const baseSchema = Joi.object({
  nome: Joi.string()
    .trim()
    .max(255)
    .required()
    .messages({
      "string.base": `Il <b>campo</b> "nome" deve essere testuale.`,
      "string.empty": `Il campo "nome" è obbligatorio.`,
      "string.max": `Il campo "nome" non può contenere più di {#limit} caratteri.`,
      "any.required": `Il campo "nome" è obbligatorio.`
    }),
  cognome: Joi.string()
    .trim()
    .max(255)
    .required()
    .messages({
      "string.base": `Il campo "cognome" deve essere testuale.`,
      "string.empty": `Il campo "cognome" è obbligatorio.`,
      "string.max": `Il campo "cognome" non può contenere più di {#limit} caratteri.`,
      "any.required": `Il campo "cognome" è obbligatorio.`
    }),
  sesso: Joi.string()
    .trim()
    .max(20)
    .required()
    .valid('Uomo', 'Donna')
    .messages({
      "string.base": `Il campo "sesso" non è valido.`,
      "any.only": `Il campo "sesso" non è stato compilato.`,
      "any.required": `Il campo "sesso" è obbligatorio.`
    }),
  has_app_account: Joi.boolean()
    .required(),
  email: Joi.string()
    .trim()
    .max(255)
    .regex(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    .required()
    .messages({
      "string.base": `Il campo "email" deve essere testuale.`,
      "string.empty": `Il campo "email" è obbligatorio.`,
      "string.max": `Il campo "email" non può contenere più di {#limit} caratteri.`,
      "any.required": `Il campo "email" è obbligatorio.`,
      "string.pattern.base": `Il campo "email" inserito non è valido.`,
    }),
  telefono1: Joi.string()
    .max(255)
    .regex(/^[0-9+]*$/)
    .trim()
    .messages({
      "string.base": `Il campo "telefono" deve essere testuale.`,
      "string.empty": `Il campo "telefono" è obbligatorio.`,
      "string.max": `Il campo "telefono" non può contenere più di {#limit} caratteri.`,
      "string.pattern.base": `Il campo "telefono" può contenere solo numeri e il carattere '+'.`,
    }),
  datanascita: Joi.date()
    .format('DD/MM/YYYY')
    .required()
    .messages({
      "date.format": `La deve essere nel formato DD/MM/YYYY`,
    }),
  cittaresidenza: Joi.string()
    .trim()
    .max(255)
    .allow('')
    .messages({
      "string.base": `Il campo "città di residenza" deve essere testuale.`,
      "string.max": `Il campo "città di residenza" non può contenere più di {#limit} caratteri.`,
    }),
  viaresidenza: Joi.string()
    .trim()
    .max(255)
    .allow('')
    .messages({
      "string.base": `Il campo "via di residenza" deve essere testuale.`,
      "string.max": `Il campo "via di residenza" non può contenere più di {#limit} caratteri.`,
    }),
  questionario_nutrizionista: Joi.any()
})
const updateSchema = baseSchema.keys({
  id: Joi
    .number()
    .integer()
    .required()
    .messages({
      "number.base": `id non valido.`,
      "number.integer": `id non valido.`,
      "any.required": `id non valido.`
    }),

})
const createSchema = baseSchema;

const appAccountValidation = {
  username: Joi.string()
    .trim()
    .min(6)
    .max(16)
    //reference https://stackoverflow.com/questions/46453307/the-ideal-username-and-password-regex-validation
    /* assicura che:
        - ^ is line start
        - (?=.*?[a-zA-Z]) is a positive lookahead that will make sure there is atleast one alphabet
        - (?=.*?[0-9]) is a positive lookahead that will make sure there is atleast one digit
        - [\w@#$%^?~-]{5,30} is using character class for 5 to 30 characters specified inside square brackets
        - $ is line end
      NB: bisogna usare \\ prima dei chars per l'escaping della stringa
    */
    .regex(/^(?=.*?[a-zA-Z])(?=.*?[0-9])[\w@#$%^?~-]{6,16}$/)
    .required()
    .messages({
      "string.base": `Il campo "username" deve essere testuale.`,
      "string.empty": `Il campo "username" è obbligatorio.`,
      "string.max": `Il campo "username" non può contenere più di {#limit} caratteri.`,
      "string.min": `Il campo "username" deve contenere più di {#limit} caratteri.`,
      "any.required": `Il campo "username" è obbligatorio.`,
      "string.pattern.base": `Il campo "username" deve contenere almeno un numero e nessun carattere speciale.`,
    }),
  password: Joi.string()
    .trim()
    .min(8)
    .max(16)
    /* assicura che:
        - 8-16 caratteri
        - almeno un numero
    */
    .regex(/^(?=.*?[a-zA-Z])(?=.*?[0-9])[\w@#$%^?~-]{8,16}$/)
    .required()
    .messages({
      "string.base": `Il campo "password" deve essere testuale.`,
      "string.empty": `Il campo "password" è obbligatorio.`,
      "string.max": `Il campo "password" non può contenere più di {#limit} caratteri.`,
      "string.min": `Il campo "password" deve contenere più di {#limit} caratteri.`,
      "any.required": `Il campo "password" è obbligatorio.`,
      "string.pattern.base": `Il campo "password" deve contenere almeno un numero.`,
    }),
}
const createSchemaWithAppAccount = baseSchema.keys(appAccountValidation)
const createAppAccount = Joi.object(appAccountValidation)

export { createSchema, createSchemaWithAppAccount, updateSchema, createAppAccount }