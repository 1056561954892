import React, { Component } from 'react';
import ApiClient from '../../../ApiClient';
import { Alert, Spinner, Card, CardBody, Row, Col, Input } from 'reactstrap';
import CustomCard from '../../../components/CustomCard';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

class Logs extends Component {
  apiClient = new ApiClient()
  state = {
    lines: [],
    error: false,
    loading: true,
    data: moment(),
    isFocus: false,
    type: "HTTP"
  }
  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    let error = false, lines = []
    const { data, type } = this.state
    try {
      let results = null
      if (type === "HTTP") {
        results = await this.apiClient.getLogsHttp(moment(data).format("YYYYMMDD"), 0, 1000)
      } else {
        results = await this.apiClient.getLogsApp(moment(data).format("YYYYMMDD"), 0, 1000)
      }
      lines = results.data
      error = false
    } catch (err) {
      console.log(err)
      error = true
    } finally {
      this.setState({
        lines, error, loading: false
      })
    }
  }

  render() {
    const { lines, loading, error, data, isFocus } = this.state
    if (loading) {
      return <div className="wrap-spinner-central mb-10 mt-10"><Spinner size="sm" color="info" />&nbsp; Carico...</div>
    } else if (error) {
      return <Alert color="danger">Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.</Alert>
    } else return <CustomCard>
      <CardBody>
        <Row>
          <Col md={12} className="d-flex align-items-center justify-content-between w-100p">
            <h5> Logs</h5>
            <div className="d-flex align-items-center justify-content-between w-100p">
              <Input type="select" className="form-control mr-10" style={{ width: 'auto' }} onChange={(evt) => this.setState({ type: evt.target.value }, this.loadData)}>
                <option>HTTP</option>
                <option>App</option>
              </Input>
              <SingleDatePicker
                isOutsideRange={() => false}
                numberOfMonths={1}
                showDefaultInputIcon
                date={data} // momentPropTypes.momentObj or null
                onDateChange={data => this.setState({ data }, this.loadData)} // PropTypes.func.isRequired
                focused={isFocus} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ isFocus: focused })} // PropTypes.func.isRequired
                id="dataLog"
                small
              />
            </div>
          </Col>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        {lines.length == 0 || (lines.length == 1 && lines[0] == "") ?
          <div>Non ci sono log per questa giornata</div>
          :
          lines.map(l => <div>{l}</div>)
        }
      </CardBody>
    </CustomCard>
  }
}
Logs.defaultProps = {
}
export default Logs;

