import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from '../helper';
import { Link, matchPath } from 'react-router-dom';

let routes;

const getPaths = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const findRouteName = (url) => {
  const aroute = routes.find(route => matchPath({ path: route.path }, url) != null);
  return (aroute && aroute.name) ? aroute.name : null
};

const BreadcrumbsItem = ({ path }) => {
  const routeName = findRouteName(path);
  if (routeName) {
    return (
      // eslint-disable-next-line react/prop-types
      matchPath({ path }, window.location.hash.replace("#", "")) ?
        <BreadcrumbItem active>{routeName}</BreadcrumbItem>
        :
        <BreadcrumbItem>
          <Link to={path || ''}>
            {routeName}
          </Link>
        </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = (args) => {
  const paths = getPaths(args.location.pathname);
  const items = paths.map((path, i) => <BreadcrumbsItem key={"b" + i.toString()} path={path} />)
  return (
    <Breadcrumb>
      {items}
    </Breadcrumb>
  );
};

class AppBreadcrumb extends Component {
  constructor(props) {
    super(props);

    this.state = { routes: props.appRoutes };
    routes = this.state.routes;
  }

  render() {
    const { className, ...attributes } = this.props;
    delete attributes.children
    delete attributes.appRoutes
    delete attributes.router
    return (
      <Breadcrumbs {...this.props.router} {...attributes} />
    );
  }
}

export default withRouter(AppBreadcrumb);
