import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

// This function takes a component...
export default function Controller(WrappedComponent,) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()


    state = {
      users: [],
      loading: true,
      error: false,
      paziente: {
        nome: "",
        cognome: "",
      },

    }

    componentDidMount() {
      this.loadPaziente()
    }

    loadPaziente = async () => {
      try {
        const { id } = this.props.router.params
        const paziente = await this.apiClient.getPazienteById(id)
        this.setState({
          paziente: paziente.data,
          loading: false
        })
      } catch (err) {
        this.setState({
          loading: false,
          error: true
        })
      }
    }

    eliminaPaziente = async () => {
      try {
        const { id } = this.props.router.params
        await this.apiClient.deletePaziente(id)
        Alert.success("Paziente eliminato!")
        this.props.router.navigate(`/users/`)
      } catch (err) {
        Alert.error("Errore, impossibile eseguire l'operazione.")
      }
    }

    render() {
      return <WrappedComponent
        {...this.props}
        state={this.state}
        loadPaziente={this.loadPaziente}
        eliminaPaziente={this.eliminaPaziente}
      />
    }
  }
}
