export default [
  {//non specificato 
    value: "",
    label: "Non specificato",
    color: "",
  },
  {
    value: "Controllo peso autonomo",
    label: "Controllo peso autonomo",
    color: "#16a085",
  },
  {
    value: "Controllo",
    label: "Controllo",
    color: "#8e44ad",
  },
  {
    value: "Ritiro dieta",
    label: "Ritiro dieta",
    color: "#e67e22",
  }
]