import React, { Component } from 'react';
import Controller from './Controller';
import { Alert, Spinner, Button, FormGroup, Input, Row, Col, Card, CardBody, CardFooter, Label } from 'reactstrap';
import PastiDieta from '../Dieta/PastiDieta'
import { DateRangePicker, } from 'react-dates';
import moment from 'moment'
import AlertMessage from 'react-s-alert';
import StampaDieta from './StampaDieta';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from '../../helper';

class View extends Component {

  constructor(props) {
    super(props)
    this.pdfRef = React.createRef();
  }

  state = {
    startDate: "",
    endDate: "",
    template: -1,
  }

  giorniSettimana = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato",]


  submit = () => {
    this.props.submit()
  }

  delete = () => {
    this.props.delete()
  }

  //cambia giorno visualizzato tramite la select in pagina
  changeDay = (index) => {
    this.props.changeActiveIndex(index)
  }

  //setta il riferimento all'oggetto che stampa la dieta di oggi
  setComponentToPrint = (el) => {
    this.componentToPrint = el
  }



  initDieta = () => {
    const { startDate, endDate, template } = this.state
    if (startDate === "" || endDate === "" || startDate === null || endDate === null)
      AlertMessage.warning("Selezionare prima il periodo della dieta!")
    else
      this.props.initDieta(startDate, endDate, template)
  }

  render() {
    const { error, activeIndex, loading, dieta, dieteTemplate, copiaAutomatica, paziente } = this.props.state
    const { focusedInput, startDate, endDate, template } = this.state
    //trovo la dieta template selezionata, cosi da metterla in un oggetto e stamparla come anteprima
    const dietaTemplateSelezionata = dieteTemplate.find(d => d.id == this.state.template)
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else if (dieta.dal === "" || dieta.al === "") {//bisogna ancora indicare i giorni di dieta
      return <Card>
        <CardBody>
          <span class="flexIt">
            <strong style={{ flex: 1 }}>
              Profilo di <strong>{paziente.nome + " " + paziente.cognome}</strong>
            </strong>
          </span>
          <hr />
          <div>In questa sezione potrai creare una dieta per il paziente. Decidi se partire da un template che hai preparato precedentemente, o creare una dieta partendo da zero.</div>
          <hr></hr>
          <div>
            <div>
              <div>Indica il periodo della dieta:</div>
              <DateRangePicker
                startDatePlaceholderText="Dal"
                endDatePlaceholderText="al"
                enableOutsideDays={false}
                small={true}
                readOnly
                minimumNights={0}
                showClearDates={true}
                startDate={startDate}
                endDate={endDate}
                onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
              />
            </div>
            <br />
            <div>
              Parti da un template e personalizzalo:
              <Input type="select" className="form-control" value={template} onChange={(evt) => this.setState({ template: evt.target.value })}>
                <option value={-1}>Nuovo</option>
                <option value={-2}>PDF</option>
                {
                  dieteTemplate.map((dt, k) => <option value={dt.id}>{dt.nome}</option>)
                }
              </Input>
            </div>
          </div>
          <br />
          <div style={{ height: '40vh', overflowY: 'scroll' }}>
            <h5>Anteprima Template</h5>
            {template == -1 || template == -2 ?
              <div>Nessun template selezionato.</div>
              :
              <Row>
                <Col md={3} style={{ fontWeight: 'bold', backgroundColor: '#f6f6f6', }}>
                  Giorno della settimana
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={3} style={{ fontWeight: 'bold', backgroundColor: '#f6f6f6' }}>Pasto</Col>
                    <Col md={6} style={{ fontWeight: 'bold', backgroundColor: '#f6f6f6' }}>Contenuto</Col>
                    <Col md={3} style={{ fontWeight: 'bold', backgroundColor: '#f6f6f6' }}>Orario</Col>
                  </Row>
                </Col>
                {
                  Array("lun", "mar", "mer", "gio", "ven", "sab", "dom").map((g, k) => (
                    <React.Fragment key={k}>
                      <Col md={3} >
                        {g}
                      </Col>
                      <Col md={9}>
                        {
                          dietaTemplateSelezionata[g].map((p, j) => <Row key={j}>
                            <Col md={3}>{p.pasto}</Col>

                            <Col md={6}>
                              {p.testo == undefined ?
                                ""
                                :
                                p.testo.split("\n").map(e => <>{e}<br /></>)}
                            </Col>


                            <Col md={3}>{p.ora}:{p.minuti}</Col>
                          </Row>
                          )
                        }
                        <br />
                      </Col>
                    </React.Fragment>
                  ))
                }
              </Row>
            }
          </div>
        </CardBody>
        <CardFooter style={{ justifyContent: 'flex-end' }}>
          <Button color="success" onClick={this.initDieta}>Continua</Button>&nbsp;
          <Button color="primary" outline onClick={() => this.props.router.navigate(`/users/${this.props.router.params.id}#diete`)}>Indietro</Button>&nbsp;
        </CardFooter>
      </Card>

    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <span class="flexIt">
              <strong style={{ flex: 1 }}>
                Profilo di <strong>{paziente.nome + " " + paziente.cognome}</strong>
              </strong>
            </span>
            <hr />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ flex: 1 }}>
                &nbsp;
                <Button
                  onClick={() => this.props.copiaGiornataOvunque()}
                  color="primary" outline>Copia ovunque</Button>
                &nbsp;
                <ReactToPrint
                  documentTitle={`SìDiet_${paziente.cognome}_${paziente.nome}.pdf`}
                  trigger={() => <Button outline color={"primary"}><FontAwesomeIcon icon={faPrint} /> Stampa </Button>}
                  content={() => this.componentToPrint}
                  copyStyles={true}
                  pageStyle={"@page { size: auto; margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }"}
                />
                &nbsp;
                <Button
                  onClick={() => this.props.creaTemplate()}
                  color="primary" disabled={dieta.id === null} outline>Crea Template</Button>
                &nbsp;
                <Button
                  onClick={this.props.downloadPdf}
                  color="primary" outline disabled={dieta.pdf === null}>Visualizza PDF</Button>
                &nbsp;
                <Button
                  onClick={() => this.pdfRef.current.click()}
                  color="primary" outline>Carica PDF</Button>
                <input
                  type="file"
                  ref={this.pdfRef}
                  accept='application/pdf'
                  onChange={this.props.setPdf}
                  style={{ display: 'none' }}
                />
                &nbsp;
                <Button
                  onClick={() => this.props.setPdf()}
                  color="danger" outline disabled={dieta.pdf === null}>Rimuovi PDF</Button>
                &nbsp;
                {dieta.pdf !== null && <Label className="cursorPointer" type="text" onClick={() => {
                  window.open("").document.write(
                    "<iframe width='100%' height='100%' src='" +
                    encodeURI(dieta.pdf) + "'></iframe>"
                  )
                }}>Clicca qui per vedere il PDF caricato sulla dieta.</Label>}
              </span>
              <span>
                <Button color="success" onClick={this.submit}>Salva</Button>&nbsp;
                <Button
                  color="primary"
                  outline
                  onClick={() => this.props.router.navigate(`/users/${this.props.router.params.id}#diete`)}>Indietro</Button>&nbsp;
                <Button color="danger" disabled={dieta.id == null} onClick={this.delete}>Elimina</Button>&nbsp;
              </span>
            </div>
            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormGroup switch>
                <Input
                  type="switch" role="switch"
                  id="controlloCopia"
                  name="controlloCopia"
                  onClick={this.props.toggleCopiaAutomatica}
                  checked={copiaAutomatica} />
                <Label check>
                  Modifica automaticamente ogni {this.giorniSettimana[moment(dieta.days[activeIndex].data, 'DD/MM/YYYY').day()]}
                </Label>
              </FormGroup>
              <h5>Questa dieta è: {dieta.visibile ? "VISIBILE" : "NON VISIBILE"}</h5>
            </div>
            <hr />

            <div className="flexIt" style={{ flexDirection: 'column', alignItems: 'start' }}>
              <h5>Nome Dieta</h5>
              <Input type="text" onChange={(evt) => this.props.changeNome(evt.target.value)} value={dieta.nome} placeholder="Inserisci il nome della dieta" />
              <hr />
              <h5>Data &nbsp;&nbsp;&nbsp;</h5>
              <div className="d-flex align-items-center justify-content-between width-100p">
                <Button className="mr-10" size="lg" disabled={activeIndex === 0} onClick={() => this.changeDay(parseInt(activeIndex) - 1)} outline color={"primary"}><FontAwesomeIcon icon={faAngleLeft} /> </Button>
                <Input type="select" className="width-100p form-control" value={activeIndex} onChange={(evt) => this.changeDay(evt.target.value)} style={{ fontSize: 22 }}>
                  {
                    dieta.days.map((d, j) => <option key={j} value={j} style={{ fontSize: 22 }}>
                      {this.giorniSettimana[moment(d.data, 'DD/MM/YYYY').day()]} {moment(d.data, 'DD/MM/YYYY').format('LL')}
                    </option>)
                  }
                </Input>
                <Button className="mr-10 ml-10" size="lg" disabled={activeIndex === dieta.days.length - 1} onClick={() => this.changeDay(parseInt(activeIndex) + 1)} outline color={"primary"}><FontAwesomeIcon icon={faAngleRight} /> </Button>
                <Button
                  color="primary"
                  outline
                  className="ml-10"
                  onClick={this.props.toToday}
                  disabled={moment().isAfter(moment(dieta.al, "DD/MM/YYYY")) || moment(dieta.dal, "DD/MM/YYYY").isAfter(moment())}>Oggi</Button>
              </div>
            </div>
            <br />
            <h5>Pasti</h5>
            <PastiDieta
              pasti={dieta.days[activeIndex].pasti}
              giornata={""}//non serve, ma non posso rimuoverlo! servirebbe un refactor...
              disabled={moment().isAfter(moment(dieta.days[activeIndex].data, "DD/MM/YYYY"), 'day')}
              rearrangePasto={this.props.rearrangePasto}
              addPasto={this.props.addPasto}
              changeTipoPasto={this.props.changeTipoPasto}
              changeTestoPasto={this.props.changeTestoPasto}
              changeHourPasto={this.props.changeHourPasto}
              changeMinutePasto={this.props.changeMinutePasto}
              removePasto={this.props.removePasto} />
            <hr />
            <h5>Note</h5>
            <Input type="textarea" rows={5} onChange={(evt) => this.props.changeNote(evt.target.value)} style={{ width: '100%' }} value={dieta.note} />
          </CardBody>
        </Card>
        <StampaDieta dieta={dieta} {...this.props} setComponentToPrint={this.setComponentToPrint} />

      </div >
    )
  }
}

export default withRouter(Controller(View));