import React, { Component } from 'react';
import { Col, Row, Button, Alert, Spinner, } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import ApiClient from '../../ApiClient'
import moment from 'moment'
import AlertMessage from 'react-s-alert';
import Appuntamento from '../../components/appuntamento';
import Table from '../../components/Table/Table';
import { withRouter } from '../../helper';

const initAppuntamento = (pid) => {
  return {
    id: null,
    data: moment().hours(9).minutes(30),
    data_end: moment().hours(9).minutes(45),
    note: "",
    paziente: pid,
    sede: null,
    motivo: "",
    linkvc: "",
  }
}

class Appuntamenti extends Component {

  apiClient = new ApiClient()

  state = {
    appuntamenti: [],
    loading: true,
    error: false,
    appuntamento: initAppuntamento(this.props.router.params.id),
    modalAppuntamento: false
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {

    await this.loadAppuntamenti()
    this.setState({ loading: false })
  }

  toggleModalAppuntamento = () => this.setState({ modalAppuntamento: !this.state.modalAppuntamento })

  loadAppuntamenti = async () => {
    try {
      const pid = this.props.router.params.id
      const appuntamenti = await this.apiClient.getAppuntamentiPaziente(pid)
      //trasformo date in oggetti moment
      appuntamenti.map(a => a.data = moment(a.data))
      const collapse = Array(appuntamenti.length).fill(false)
      const focused = Array(appuntamenti.length).fill(false)
      this.setState({ appuntamenti, collapse, focused, })
    } catch (err) {
      console.log(err)
      this.setState({ error: true })
    }
  }

  deleteAppuntamento = async (aid) => {
    let { appuntamenti } = this.state
    const a = appuntamenti.find(a => a.id === aid)
    const pid = this.props.router.params.id
    if (window.confirm("Sei sicuro di voler eliminare l'appuntamento?")) {//elimino!
      try {
        const response = await this.apiClient.eliminaAppuntamentoPaziente(pid, a.id)
        appuntamenti = appuntamenti.filter(a => a.id !== aid)
        this.setState({ appuntamenti })
        AlertMessage.success('Appuntamento eliminato!')
      } catch (err) {
        console.log(err)
        AlertMessage.error('Errore, impossibile eliminare l\'appuntamento.')
      }
    }
  }

  openAppuntamento = (appuntamento) => {
    this.setState({ appuntamento }, this.toggleModalAppuntamento)
  }

  columns = () => [
    {
      name: 'Data',
      selector: row => moment(row.data).format('L'),
      width: "110px",
      sortable: true
    },
    {
      name: 'Orario',
      width: "110px",
      selector: row => moment(row.data).format('HH:mm') + " - " + moment(row.data_end).format('HH:mm'),
    },
    {
      name: 'Motivo',
      selector: row => row.motivo,
      format: row => <span style={{ whiteSpace: "unset" }}>{row.motivo}</span>
    },
    {
      name: 'Azioni',
      width: "8rem",
      selector: row => <div className={"d-flex justify-content-start"} key={row.id}>
        <Button outline color="primary" onClick={() => this.openAppuntamento(row)}><FontAwesomeIcon icon={faEye} /></Button>
        &nbsp;&nbsp;
        <Button onClick={() => this.deleteAppuntamento(row.id)} outline color="danger"><FontAwesomeIcon icon={faTrash} /></Button>
      </div>,
    },
  ]


  render() {
    const { appuntamenti, loading, error, appuntamento, modalAppuntamento, } = this.state
    const columns = this.columns()
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Appuntamento
          appuntamento={appuntamento}
          setAppuntamento={(appuntamento) => this.setState({ appuntamento })}
          toggleModal={modalAppuntamento}
          setToggleModal={this.toggleModalAppuntamento}
          pazienteSelectDisabled={true}
          onAppuntamentoSalvato={this.loadAppuntamenti}
          onAppuntamentoEliminato={this.loadAppuntamenti} {...this.props}></Appuntamento>
        <Row>
          <Col md={9}>
            <h4>Appuntamenti</h4>
          </Col>
          <Col md={3}>
            <Button style={{ float: 'right' }} outline color="primary" onClick={() => {
              this.setState({ appuntamento: initAppuntamento(this.props.router.params.id), }, this.toggleModalAppuntamento)
            }}><FontAwesomeIcon icon={faCalendarPlus} />&nbsp;Nuovo</Button>
          </Col>
        </Row>
        <hr />
        <Table data={appuntamenti} columns={columns} />
      </div >
    )
  }
}

Appuntamenti.defaultProps = {
  newAppuntamento: true
}

export default withRouter(Appuntamenti);

