import React from 'react';
import './style.css'
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="searchInputField">
    <TextField
      id="search"
      type="text"
      placeholder="Cerca..."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button><FontAwesomeIcon onClick={onClear} icon={faTimesCircle} size='sm' /></button>
  </div>
);

const basicCustomStyles = {
}

export default (props) => {
  let { columns, data, selectableRows, onSelectedRowsChange } = props
  selectableRows = selectableRows != undefined && onSelectedRowsChange != undefined
  let { customMatchFunc, customStyles } = props
  let tableStyle = basicCustomStyles
  if (customStyles != null || customStyles != undefined) {
    tableStyle = { ...tableStyle, ...customStyles }
  }
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  let filteredItems = customMatchFunc == undefined ? data : customMatchFunc(data, filterText)
  const subHeader = customMatchFunc != undefined
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  return <div className="table-responsive">
    <DataTable
      columns={columns}
      data={filteredItems}
      customStyles={tableStyle}
      subHeader={subHeader}
      selectableRows={selectableRows}
      onSelectedRowsChange={onSelectedRowsChange}
      subHeaderComponent={subHeaderComponentMemo}
      noDataComponent={<div>Nessun dato da mostrare</div>}
      pagination />
  </div>
}