import React, { useState, useEffect } from 'react'
import ApiClient from '../../../ApiClient'
import { Spinner, Card, CardBody, Button, Alert, Row, Col, Modal, ModalHeader, UncontrolledPopover, PopoverBody, ModalBody, Input, ModalFooter } from 'reactstrap'
import Table from '../../../components/Table'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSave, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { logout } from '../../../coreui/HeaderDropdown'

const apiClient = new ApiClient()

const initData = () => {
  return {
    nutrizionisti: [],
    isError: false,
    isLoading: true
  }
}

const AGGIUNGI_PAZIENTI_PACCHETTI = [10, 20, 50, 100, 500]

const Dashboard = () => {
  const [data, setData] = useState(initData())
  const [showDropdownCambioPassword, setShowDropdownCambioPassword] = useState(false)
  const [cambioPasswordSelectedEmail, setCambioPasswordSelectedEmail] = useState("")
  const [newPassword, setNewPassword] = useState("")

  useEffect(() => {
    getNutrizionisti()
  }, [])


  const getNutrizionisti = async () => {
    setData({
      ...data, isLoading: true
    })
    try {
      const results = await apiClient.getNutrizionisti()
      setData({
        nutrizionisti: results.data, isError: false, isLoading: false
      })
    } catch (err) {
      //token non valido, deve riloggare
      if (err.response.status == 401) {
        window.alert("Sessione scaduta o non valida. Devi rieffettuare l'accesso.")
        logout();
        return;
      }
      console.log(err.message)
      setData({
        ...data,
        isError: true
      })
    }
  }

  const addPazientiRimanenti = async (nid, pazienti_rimanenti) => {
    if (window.confirm(`Stai per aggiungere a questo nutrizionista ${pazienti_rimanenti} account pazienti. Sei sicuro di voler procedere?`)) {
      try {
        await apiClient.addPazientiRimanenti(nid, { pazienti_rimanenti })
        window.alert("Operazione eseguita!")
        //aggiungo pazienti_rimanenti al nutrizionista
        const { nutrizionisti } = { ...data }
        const nutrizionista = nutrizionisti.find(n => n.id == nid)
        nutrizionista.pazienti_rimanenti += pazienti_rimanenti
        setData({ ...data, nutrizionisti: nutrizionisti })
      } catch (err) {
        console.log(err)
        window.alert("Errore, impossibile eseguire l'operazione")
      }
    }
  }

  const disabilitaNutrizionista = async (nid, deleted) => {
    let msg = "Stai per disabilitare l'account del nutrizionista, sei sicuro di voler procedere?"
    if (deleted == 'n')
      msg = "Stai per abilitare l'account del nutrizionista, sei sicuro di voler procedere?"
    if (window.confirm(msg)) {
      try {
        await apiClient.disabilitaNutrizionista(nid, { deleted })
        //aggiorno nuutrizionista
        const { nutrizionisti } = { ...data }
        const nutrizionista = nutrizionisti.find(n => n.id == nid)
        nutrizionista.deleted = deleted
        setData({ ...data, nutrizionisti: nutrizionisti })
        window.alert("Fatto!")
      } catch (err) {
        window.alert("Errore, impossibile eseguire l'operazione")
      }
    }
  }

  const submitCambioPasswordNutrizionista = async () => {
    let msg = "Stai per cambiare la password dell'account del nutrizionista, sei sicuro di voler procedere?"
    if (window.confirm(msg)) {
      try {
        await apiClient.nutrizionistaChangePassword(cambioPasswordSelectedEmail, newPassword)
        //reset dello stato
        setCambioPasswordSelectedEmail(-1)
        setNewPassword("")
        setShowDropdownCambioPassword(false)
        window.alert("Fatto!")
      } catch (err) {
        window.alert("Errore, impossibile eseguire l'operazione")
      }
    }
  }

  const columns = [
    {
      name: 'Nutrizionista',
      selector: row => row.ragsociale
    },
    {
      name: 'Pazienti Registrati',
      selector: row => row.pazienti,
    },
    {
      name: 'Pazienti rimanenti',
      selector: row => row.pazienti_rimanenti,
    },
    {
      name: 'Azioni',
      width: "15rem",
      selector: row => {
        return <>
          <Button
            id={"UncontrolledPopover" + row.id}
            type="button"
            color="primary"
            className="mr-10"
            onClick={(e) => e.target.focus()}
          >
            Aggiungi Pazienti
          </Button>
          <UncontrolledPopover
            placement="left"
            target={"UncontrolledPopover" + row.id}
            trigger="focus"
          >
            <PopoverBody className="d-flex-column justify-content-center align-items-center">
              &nbsp;
              {
                AGGIUNGI_PAZIENTI_PACCHETTI.map(n => <>
                  <Button className='width-100p' onClick={() => addPazientiRimanenti(row.id, n)} outline color="primary">&nbsp;{n} pazienti</Button>
                  &nbsp;
                </>)
              }
            </PopoverBody>
          </UncontrolledPopover>
          <Button
            id={"ActionsUncontrolledPopover" + row.id}
            type="button"
            outline color="primary"
            onClick={(e) => e.target.focus()}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
          <UncontrolledPopover
            placement="left"
            target={"ActionsUncontrolledPopover" + row.id}
            trigger="focus"
          >
            <PopoverBody className="d-flex-column justify-content-center align-items-center">
              &nbsp;
              <Link to={`/nutrizionisti/${row.id}/users`} className="width-100p">
                <Button outline color="primary" className="width-100p">&nbsp;Aggiungi Utenti</Button>
              </Link>
              &nbsp;
              <Button color="primary" outline className="width-100p" onClick={() => {
                setCambioPasswordSelectedEmail(row.email)
                setShowDropdownCambioPassword(true)
              }}>Cambia Password</Button>
              &nbsp;
              {
                row.deleted == 'n' ?
                  <Button color="danger" className="width-100p" onClick={() => disabilitaNutrizionista(row.id, 'y')}>Disabilita</Button>
                  :
                  <Button color="danger" className="width-100p" onClick={() => disabilitaNutrizionista(row.id, 'n')}>Abilita</Button>
              }
              &nbsp;
            </PopoverBody>
          </UncontrolledPopover>
        </>
      }
    },
  ]

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => setShowDropdownCambioPassword(false)}
    >
      <span aria-hidden="true">×</span>
    </button>
  );

  if (data.isLoading) {
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  } else if (data.isError) {
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  } else return <div className="animated fadeIn">
    <Modal size="sm" isOpen={showDropdownCambioPassword} toggle={setShowDropdownCambioPassword}>
      <ModalHeader close={closeBtn}>Cambio password Nutrizionista</ModalHeader>
      <ModalBody>
        <small>Stai per cambiare la password dell'account del Nutrizionista. Inseriscila di seguito se sei sicuro.</small>
        <Input type="text" value={newPassword} onChange={(evt) => setNewPassword(evt.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} outline color="primary" onClick={submitCambioPasswordNutrizionista} >
          <FontAwesomeIcon icon={faSave} />&nbsp;Salva
        </Button>
        <Button size={"sm"} color="primary" outline onClick={() => setShowDropdownCambioPassword(false)}>Chiudi</Button>
      </ModalFooter>
    </Modal>
    <Card>
      <CardBody>
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <h4>Nutrizionisti</h4>
            <Link to={`/nutrizionista`}><Button style={{ float: 'right' }} outline color="primary"><FontAwesomeIcon icon={faUser} />&nbsp;Nuovo</Button></Link>
          </Col>
        </Row>
        <hr />
        <Table
          data={data.nutrizionisti}
          columns={columns} />
      </CardBody>
    </Card>
  </div>
}
Dashboard.defaultProps = {

}
export default Dashboard