import React, { Component } from 'react';
import '../../style.css'
import { Navigate } from 'react-router-dom';
import ApiClient from '../../ApiClient'
import { APP_NAME } from '../../constants';
//is the user logged in?
const isLogged = window.localStorage.getItem("token") != null

class AdminLogin extends Component {

  apiClient = new ApiClient()
  state = {
    username: "",
    password: "",
  }

  login = async (evt) => {
    evt.preventDefault()
    const { username, password } = this.state
    let response = null
    try {
      response = await this.apiClient.loginAdmin(username, password)
      if (response.success) {
        window.localStorage.setItem("token", response.token)
        window.location.href = "/"
      }
      else {
        throw new Error("error")
      }
    } catch (err) {//dati errati!
      window.alert("Dati errati!")
    }
  }

  render() {
    const { username, password, } = this.state
    if (isLogged)
      return <Navigate to={{ pathname: '/', }} />
    else return <div className={"flexIt"} style={{ backgroundColor: '#fff' }}>
      <div className="homepage">
        <div className={"ghirigoro"} />
        <div className={"img-logo-container"}>
          <img alt="logo" src="/logo.png" className={"logo-img"} />
        </div>
      </div>
      <div className="login-container">
        <form onSubmit={this.login} className="login-box">
          <img alt="logo" src="/logo.png" className={"logo-img"} />
          <h1>{APP_NAME}</h1>
          <small>Inserisci di seguito i tuoi dati di accesso.</small>
          <input className="text-input" type="input" onChange={(evt) => this.setState({ username: evt.target.value })} value={username} placeholder="Username" />
          <input className="text-input" type="password" onChange={(evt) => this.setState({ password: evt.target.value })} value={password} placeholder="Password" />
          <input className="submit-btn login-btn" type="submit" value="LOGIN" />
        </form>
      </div>
    </div>
  }
}

export default AdminLogin;

