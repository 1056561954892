import React from "react";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import { validateSede } from "../../validators/sede";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCity,
  faHome,
  faPhone,
  faRoad,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { useSedi } from "../../state/sedi";
import { Sede } from "../../types/sede.interface";

interface Props {
  sede: Sede;
  setSede: any;
  isOpen: boolean;
  toggle: any;
}

export default ({ sede, setSede, isOpen, toggle }: Props) => {
  const { addSede, updateSede }: any = useSedi();

  const _submit: any = async () => {
    //validazione
    if (!validateSede(sede)) {
      Alert.warning(
        "Attenzione, è necessario compilare tutti i campi obbligatori."
      );
    } else {
      //creazione o aggiornamento?
      if (sede.id == null) {
        //creazione
        const result = await addSede(sede);
        if (result) {
          Alert.success("Sede aggiunta con successo.");
          toggle(false);
        } else {
          Alert.error("Errore, impossibile eseguire l'operazione.");
        }
      } else {
        //aggiornamento
        const result = await updateSede(sede);
        if (result) {
          Alert.success("Sede aggiornata con successo.");
          toggle(false);
        } else {
          Alert.error("Errore, impossibile eseguire l'operazione.");
        }
      }
    }
  };

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => toggle(false)}
    >
      <span aria-hidden="true">×</span>
    </button>
  );
  
  return (
    <Modal isOpen={isOpen} toggle={() => toggle(false)}>
      <ModalHeader toggle={() => toggle(false)} close={closeBtn}>
        {sede.id === null ? "Nuova Sede" : "Modifica Sede"}
      </ModalHeader>
      <ModalBody>
        <Row form>
          <Col md={12}>
            <Label className={"text-bold"}>Nome *</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-10"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faHome}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              type="text"
              name="nome"
              placeholder="Nome per indicare la sede"
              onChange={(evt) => setSede({ ...sede, nome: evt.target.value })}
              value={sede.nome}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Città *</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-10"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faCity}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              type="text"
              name="citta"
              placeholder="Città in cui si trova la sede"
              onChange={(evt) => setSede({ ...sede, citta: evt.target.value })}
              value={sede.citta}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Via *</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-10"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faRoad}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              type="text"
              name="via"
              placeholder="Via in cui si trova la sede"
              onChange={(evt) => setSede({ ...sede, via: evt.target.value })}
              value={sede.via}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Numero di telefono 1 *</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-10"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faPhone}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              type="text"
              name="telefono1"
              placeholder="Numero a cui puoi essere contattato"
              onChange={(evt) =>
                setSede({ ...sede, telefono1: evt.target.value })
              }
              value={sede.telefono1}
            />
          </Col>
          <Col md={12}>
            <Label className={"text-bold"}>Numero di telefono 2</Label>
          </Col>
          <Col
            md={12}
            className={"d-flex justify-content-center align-items-center mb-10"}
          >
            <FontAwesomeIcon
              className="mr-10"
              icon={faPhone}
              color={"#cecece"}
            ></FontAwesomeIcon>
            <Input
              type="text"
              name="telefono2"
              placeholder="Ulteriore numero a cui puoi essere contattato"
              onChange={(evt) =>
                setSede({ ...sede, telefono2: evt.target.value })
              }
              value={sede.telefono2}
            />
          </Col>
          <Col md={12}>
            <small>* campi obbligatori</small>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} outline color="primary" onClick={_submit}>
          <FontAwesomeIcon icon={faSave} />
          &nbsp; Salva
        </Button>{" "}
        <Button
          size={"sm"}
          color="primary"
          outline
          onClick={() => toggle(false)}
        >
          Annulla
        </Button>
      </ModalFooter>
    </Modal>
  );
};
