import React, { Component } from 'react';
import { Alert, Spinner, Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { DateRangePicker, } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faListUl, faTimes, faCalendar, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import ApiClient from '../../ApiClient'
import moment from 'moment'
import AlertMessage from 'react-s-alert';
import Table from '../../components/Table/Table';
import { withRouter } from '../../helper';

class View extends Component {

  apiClient = new ApiClient()

  state = {
    loading: true,
    error: false,
    elencoDiete: [],
    selectedDietaIdPerModificaDate: null,
    modalModificaDate: false,
    nuovo_dal: null,
    nuovo_al: null,
    nuovo_dal_riassegnaDieta: null,
    nuovo_al_riassegnaDieta: null,
    focusedInput: false,
    focusedInput_riassegnaDieta: false,
    modalRiassegnaDieta: false,
    selectedDietaIdPerRiassegnaDieta: null,
  }

  componentDidMount() {
    this.loadDiete()
  }

  //rende una dieta visibile al paziente.
  rendiVisibile = async (idDieta) => {
    if (window.confirm("Stai per rendere la dieta visibile al paziente. Sei sicuro?")) {
      const { id } = this.props.router.params
      try {
        const response = await this.apiClient.setDietaVisibile(id, { id: idDieta })
        this.loadDiete()
        AlertMessage.success("Dieta visibile al paziente.")
      } catch (err) {
        console.log(err)
        AlertMessage.error("Errore, operazione non riuscita.")
      }
    }
  }

  elimina = async (iddieta) => {
    if (window.confirm("Sei sicuro di voler eliminare la dieta?")) {
      try {
        await this.apiClient.deleteDietaPaziente(this.props.router.params.id, { id: iddieta })
        AlertMessage.success("Dieta eliminata!")
        this.loadDiete()
      } catch (err) {
        AlertMessage.error("Errore, impossibile eliminare la dieta.")
      }
    }
  }

  toggleModalModificaDate = () => {
    this.setState({
      modalModificaDate: !this.state.modalModificaDate
    })
  }

  toggleModalRiassegnaDieta = () => {
    this.setState({
      modalRiassegnaDieta: !this.state.modalRiassegnaDieta
    })
  }



  renderModalModificaDate = () => {
    const { modalModificaDate, nuovo_al, nuovo_dal, focusedInput } = this.state
    const closeBtn = (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => this.toggleModalModificaDate(false)}
      >
        <span aria-hidden="true">×</span>
      </button>
    );
    return <Modal size='sm' isOpen={modalModificaDate} toggle={this.toggleModalModificaDate}>
      <ModalHeader toggle={this.toggleModalModificaDate} close={closeBtn}>Modifica date dieta</ModalHeader>
      <ModalBody>
        <Row form>
          <Col md={12}>
            Inserisci il nuovo periodo della dieta
          </Col>
          <Col md={12}>
            <DateRangePicker
              startDatePlaceholderText="Dal"
              endDatePlaceholderText="al"
              small={true}
              minimumNights={0}
              showClearDates={true}
              startDate={nuovo_dal}
              endDate={nuovo_al}
              onDatesChange={({ startDate, endDate }) => { this.setState({ nuovo_dal: startDate, nuovo_al: endDate }) }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
              isOutsideRange={() => { return null }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.submitDate}>Modifica</Button>{' '}
        <Button color="secondary" onClick={this.toggleModalModificaDate}>Annulla</Button>
      </ModalFooter>
    </Modal >
  }

  renderModalRiassegna = () => {
    const { modalRiassegnaDieta, nuovo_al_riassegnaDieta, nuovo_dal_riassegnaDieta, focusedInput_riassegnaDieta } = this.state
    const closeBtn = (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => this.toggleModalRiassegnaDieta(false)}
      >
        <span aria-hidden="true">×</span>
      </button>
    );
    return <Modal size='sm' isOpen={modalRiassegnaDieta} toggle={this.toggleModalRiassegnaDieta}>
      <ModalHeader toggle={this.toggleModalRiassegnaDieta} close={closeBtn}>Riassegna dieta</ModalHeader>
      <ModalBody>
        <Row form>
          <Col md={12}>
            Inserisci il periodo per cui vuoi riassegnare la dieta
          </Col>
          <Col md={12}>
            <DateRangePicker
              startDatePlaceholderText="Dal"
              endDatePlaceholderText="al"
              small={true}
              minimumNights={0}
              showClearDates={true}
              startDate={nuovo_dal_riassegnaDieta}
              endDate={nuovo_al_riassegnaDieta}
              onDatesChange={({ startDate, endDate }) => { this.setState({ nuovo_dal_riassegnaDieta: startDate, nuovo_al_riassegnaDieta: endDate }) }}
              focusedInput={focusedInput_riassegnaDieta}
              onFocusChange={(focusedInput) => { this.setState({ focusedInput_riassegnaDieta: focusedInput }) }}
              isOutsideRange={() => { return null }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.submitRiassegna}>Assegna</Button>{' '}
        <Button color="secondary" onClick={this.toggleModalRiassegnaDieta}>Annulla</Button>
      </ModalFooter>
    </Modal >
  }

  //prende l'id dieta selezionato, nuovo_dal e nuovo_al, e chiede al backend di modificare le date di una dieta
  submitDate = async () => {
    const { selectedDietaIdPerModificaDate, nuovo_al, nuovo_dal } = this.state
    if (nuovo_al == null || nuovo_dal == null) {
      AlertMessage.warning("Attenzione, per poter procedere è necessario indicare una data di inizio e fine.")
    } else {
      try {
        const response = await this.apiClient.updateDateDietaPaziente(this.props.router.params.id, selectedDietaIdPerModificaDate, nuovo_dal, nuovo_al)
        AlertMessage.success("Date aggiornate!")
        this.loadDiete()
      } catch (err) {
        AlertMessage.error("Errore, impossibile modificare le date della dieta.")
      } finally {
        this.setState({
          selectedDietaIdPerModificaDate: null,
          nuovo_dal: null,
          nuovo_al: null,
          modalModificaDate: false
        })
      }
    }
  }


  //prende l'id dieta selezionato, nuovo_dal e nuovo_al, e chiede al backend di riassegnare la dieta su nuove date
  submitRiassegna = async () => {
    const { selectedDietaIdPerRiassegnaDieta, nuovo_al_riassegnaDieta, nuovo_dal_riassegnaDieta } = this.state
    if (nuovo_al_riassegnaDieta == null || nuovo_dal_riassegnaDieta == null) {
      AlertMessage.warning("Attenzione, per poter procedere è necessario indicare una data di inizio e fine.")
    } else {
      try {
        const response = await this.apiClient.riassegnaDietaPaziente(this.props.router.params.id, selectedDietaIdPerRiassegnaDieta, nuovo_dal_riassegnaDieta, nuovo_al_riassegnaDieta)
        AlertMessage.success("Dieta riassegnata!")
        this.loadDiete()
      } catch (err) {
        AlertMessage.error("Errore, impossibile riassegnare la dieta.")
      } finally {
        this.setState({
          selectedDietaIdPerRiassegnaDieta: null,
          nuovo_dal_riassegnaDieta: null,
          nuovo_al_riassegnaDieta: null,
          modalRiassegnaDieta: false
        })
      }
    }
  }

  //al click sul tasto "Modifica date", a questa funzione viene passato l'id della dieta.
  //con questo id setto le variabili di stato per mostrare il modal e i datepicker, con "dal" e "al" null
  //cosi il datepicker è vuoto quando si apre
  setSelectedDieta = (id) => {
    this.setState({
      selectedDietaIdPerModificaDate: id,
      modalModificaDate: true,
      nuovo_al: null,
      nuovo_dal: null
    })
  }
  //al click sul tasto "Riassegna dieta", a questa funzione viene passato l'id della dieta.
  //da questo id prendo la dieta, e setto le variabili di stato per mostrare il modal e i datepicker, con "dal" e "al" null
  //cosi il datepicker è vuoto quando si apre
  setSelectedRiassegnaDieta = (id) => {
    this.setState({
      selectedDietaIdPerRiassegnaDieta: id,
      modalRiassegnaDieta: true,
      nuovo_al_riassegnaDieta: null,
      nuovo_dal_riassegnaDieta: null
    })
  }

  columns = () => {
    return [
      {
        name: 'Dal',
        selector: row => moment(row.dal).format('L'),
        width: "110px",
        sortable: true,
      },
      {
        name: 'Al',
        selector: row => moment(row.al).format('L'),
        width: "110px",
      },
      {
        name: 'Nome',
        width:"auto",
        selector: row => row.nome,
        format: row => <span style={{ whiteSpace: "unset" }}>{row.nome}</span>
      },
      {
        name: 'Note',
        selector: row => row.note,
        format: row => <span style={{ whiteSpace: "unset" }}>{row.note}</span>
      },
      {
        name: 'Azioni',
        width: "8rem",
        selector: row => <span className={"flexIt"} style={{ justifyContent: 'flex-start' }} key={row.id + "" + row.popover}>
          <Link to={`/users/${this.props.router.params.id}/diete/${row.id}`}><Button outline color="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>
          &nbsp;&nbsp;
          <Button
            id={"UncontrolledPopover" + row.id}
            type="button"
            outline color="primary"
            onClick={(e) => e.target.focus()}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
          <UncontrolledPopover
            placement="left"
            target={"UncontrolledPopover" + row.id}
            trigger="focus"
          >
            <PopoverBody className="d-flex-column justify-content-center align-items-center">
              <Button className='width-100p' onClick={() => this.elimina(row.id)} outline color="danger"><FontAwesomeIcon icon={faTimes} />&nbsp;Elimina</Button>
              &nbsp;
              <Button className='width-100p' onClick={() => this.setSelectedDieta(row.id)} outline color="primary">
                <FontAwesomeIcon icon={faCalendar} />&nbsp;Modifica date
              </Button>
              &nbsp;
              <Button className='width-100p' onClick={() => this.setSelectedRiassegnaDieta(row.id)} outline color="primary">
                <FontAwesomeIcon icon={faCalendar} />&nbsp;Riassegna
              </Button>
              {
                !row.visibile ?
                  <React.Fragment>
                    &nbsp;
                    <Button className='width-100p' color="success" onClick={() => this.rendiVisibile(row.id)}><FontAwesomeIcon icon={faEye} />&nbsp;Rendi visibile</Button>
                  </React.Fragment>
                  :
                  null
              }            </PopoverBody>
          </UncontrolledPopover>

        </span>
      },
    ]
  }

  loadDiete = async () => {
    try {
      const { id } = this.props.router.params
      const elencoDiete = await this.apiClient.getDietePaziente(id)
      for (let i = 0; i < elencoDiete.data.length; i++) {
        elencoDiete.data[i].popover = false
      }
      this.setState({
        loading: false,
        elencoDiete: elencoDiete.data,
      })
    } catch (err) {
      this.setState({
        error: true
      })
    }
  }

  togglePopoverDiete = (id) => {
    const { elencoDiete } = this.state
    let dieta = elencoDiete.find(d => d.id == id)
    if (dieta != undefined) {
      dieta.popover = !dieta.popover
      this.setState({
        elencoDiete
      })
    }
  }

  render() {
    const { error, loading, elencoDiete } = this.state
    const columns = this.columns()
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        {this.renderModalModificaDate()}
        {this.renderModalRiassegna()}
        <Row>
          <Col md={9}>
            <h4>Diete</h4>
          </Col>
          <Col md={3}>
            <Link to={`/users/${this.props.router.params.id}/diete/add`}>
              <Button style={{ float: 'right' }} outline color="primary"><FontAwesomeIcon icon={faListUl} />&nbsp;Nuova</Button>
            </Link>
          </Col>
        </Row>
        <hr />
        <Table data={elencoDiete} columns={columns} />
      </div>
    )
  }
}

export default withRouter(View);