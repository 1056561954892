import React, { Component } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
// Import Flag Icons Set
// import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
// import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'
// import '../node_modules/@coreui/styles/scss/_dropdown-menu-right.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// Containers
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import AdminDefaultLayout from './containers/DefaultLayout/AdminDefaultLayout';
import Login from './views/Login/Login'
import ResetPassword from './views/Login/ResetPassword'
import AdminLogin from './views/Login/AdminLogin'
import ApiClient from './ApiClient';
import Maintenance from './views/Maintenance';

import moment from 'moment'
import 'moment/min/moment-with-locales'
import 'moment/locale/it';

//contexts
import { AppuntamentiProvider } from "./state/appuntamenti"
import { SediProvider } from "./state/sedi"
import { StatsProvider } from './state/stats';
import { UserProvider } from './state/user';
import { Spinner } from 'reactstrap';
import { APP_NAME } from './constants';
// sidebar nav config
import navigation from './_nav.js';
// routes config
import routes from './routes.js';

//is the user logged in?
import jwtDecode from 'jwt-decode';

//imposto moment su locale italiano
moment.locale('it')
moment().format('L');
const isLogged = window.localStorage.getItem("token") != null
const isAdmin = isLogged && jwtDecode(window.localStorage.getItem("token")).a
class App extends Component {
  state = {
    isMaintenance: false,
    isLoading: true
  }
  apiClient = new ApiClient()

  componentDidMount() {
    this.checkIsMaintenance()
  }

  //il backend è in manutenzione?
  checkIsMaintenance = async () => {
    const s = { ...this.state }
    try {
      const result = await this.apiClient.isMaintenanceActive()
      s.isMaintenance = result.maintenance
    } catch (err) {
      console.log(err)
    } finally {
      s.isLoading = false
      this.setState(s)
    }
  }

  render() {
    const { isMaintenance, isLoading } = this.state
    if (isLoading) {
      return <div className={"d-flex align-items-center justify-content-center master-loading-container"} >
        <div className="wrap-spinner-central master-loading" style={{ borderWidth: 0 }}>
          <h4>{APP_NAME}</h4>
          <img src="/logo192.png" width={80} className="mb-10" />
          <div><Spinner size="sm" color="info" /> &nbsp; Benvenuto </div>
        </div>
      </div>
    }
    if (isMaintenance)
      return <Maintenance />
    return (
      <HashRouter>
        <Routes>
          <Route path="/login" name="Login" element={<Login />} />
          <Route path="/reset-password/:tkn" name="Login" element={<ResetPassword />} />
          <Route path="/login-admin" name="Login" element={<AdminLogin />} />
          {!isLogged ?
            <Route path='*' element={<Navigate to={{ pathname: '/login', }} />} />
            :
            isAdmin ?
              <Route path="/*" name="Home" element={<AdminDefaultLayout {...this.props} approutes={routes.admin} appnavigation={navigation.admin} />} />
              :
              <Route path="/*" name="Home" element={
                <UserProvider>
                  <SediProvider>
                    <AppuntamentiProvider>
                      <StatsProvider>
                        <DefaultLayout {...this.props} approutes={routes.nutrizionista} appnavigation={navigation.nutrizionista} />
                      </StatsProvider>
                    </AppuntamentiProvider>
                  </SediProvider>
                </UserProvider>
              }
              />
          }
        </Routes>
      </HashRouter>
    );
  }
}

export default App;
