import React from "react";
import "./style.css";
import { Card } from "reactstrap";

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
export default ({ children, className, style }: Props) => {
  return (
    <Card
      className={`custom-card mr-10 ml-10 ${className}`}
      style={{ width: "auto", ...style }}
    >
      {children}
    </Card>
  );
};
