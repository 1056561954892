import React, { Component } from 'react';
import { Alert, Spinner, Card, CardBody, Button, Col, Row } from 'reactstrap';
import Controller from './Controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Table from '../../components/Table/Table';
import './style.css'
import { withRouter } from '../../helper';

// cerca in diete template 
const customMatchFunc = (diete, searchText) => {
  if (searchText === "" || searchText == undefined || searchText == null) {
    return diete
  }
  searchText = searchText.toLowerCase().trim()
  return diete.filter(d => (d.nome).toLowerCase().includes(searchText))
}

class View extends Component {

  columns = [
    {
      name: 'Nome',
      selector: row => row.nome,
    },
    {
      name: 'Azioni',
      width: "8rem",
      selector: row => {
        return <span className={"flexIt"} style={{ justifyContent: 'flex-start' }}>
          <Link to={`/diete/${row.id}`}>
            <Button outline color="primary"> <FontAwesomeIcon icon={faEye} /></Button>
          </Link>
          &nbsp;
          <Button outline color="danger" onClick={() => this.props.elimina(row.id)}><FontAwesomeIcon icon={faTrash} /> </Button>
        </span>;
      }
    }
  ]

  render() {
    const { error, loading, diete } = this.props.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h4>Diete</h4>
                <Link to={'/diete/add'}><Button outline color="primary"><FontAwesomeIcon icon={faListUl} />&nbsp;Nuovo</Button></Link>
              </Col>
            </Row>
            <hr />
            <Table data={diete} columns={this.columns} customMatchFunc={customMatchFunc} />
          </CardBody>
        </Card>

      </div>
    )
  }
}

export default withRouter(Controller(View));