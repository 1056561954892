import React, { Component } from 'react';
import { CardBody, Col, Row } from 'reactstrap'
import {
  XAxis, YAxis, CartesianGrid, LineChart, Line, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Legend, ReferenceLine, Brush, Bar
} from 'recharts';
import ApiClient from '../../ApiClient'
import moment from 'moment';
import WidgetStatSecco from '../../components/WidgetStatSecco/WidgetStatSecco';
import CustomCard from '../../components/CustomCard';

//custom tooltip per i grafici delle misurazioni
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p><strong>Data: </strong>{label}</p>
        <p><strong>Valore: </strong>{payload[0].value}</p>
        <p><strong>Differenza: </strong>{payload[0].payload.diff}</p>
      </div>
    );
  }
}

class Riepilogo extends Component {
  state = {
    visite: [],
    loading: true,
    error: false,
    bmi: [],
    progressi: {
      numeroVisite: 0,
      numeroVisitePagate: 0,
      numeroAppuntamenti: 0,
      numeroDiete: 0,
      dataPrimaVisita: "-",
      dataUltimaVisita: "-",
      ultimoPeso: "-",
      ultimoBMI: "-",
      ultimaAltezza: "-",
      pasti: {
        pastiFatti: 0,
        pastiTotali: 0,
        colazioniFatte: 0,
        spuntiniFatti: 0,
        pranziFatti: 0,
        ceneFatte: 0,
        colazioniTotali: 0,
        spuntiniTotali: 0,
        ceneTotali: 0,
        pranziTotali: 0,
        andamento: []
      }
    }
  }

  apiClient = new ApiClient()

  componentDidMount() {
    this.loadProgressi()
  }

  loadProgressi = async () => {
    const pid = this.props.pid
    let { progressi } = this.state
    try {
      const progressi = await this.apiClient.getProgressiPaziente(pid)
      this.setState({
        progressi: progressi.data,
        loading: false
      })
    } catch (err) {
      console.log(err)
      this.setState({
        error: true,
        loading: false,
        progressi
      })
    }
  }

  renderPieChart = (label, data, colors, cb = null) => {
    //vedo se ci sono dati
    const sum = data.reduce((acc, e) => acc += e.value, 0)
    return (
      <CustomCard className="box-progressi-container">
        <CardBody>
          <div className="box-progressi-label">{label}</div>
          {
            sum == 0 ?
              <div className="height-75p d-flex justify-content-center align-items-center">Non ci sono ancora dati</div>
              :
              <div style={{ width: '100%', height: 150 }} className="flexIt">
                <ResponsiveContainer style={{ flex: 1 }}>
                  <PieChart>
                    <Pie
                      data={data}
                      label={this.renderCustomizedLabel}
                      labelLine={false}
                      dataKey="value"
                    >
                      {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} />)
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                {cb != null && cb()}
              </div>
          }
        </CardBody>
      </CustomCard>
    );
  }

  renderSummary = () => {
    const { progressi } = this.state
    const { pasti } = progressi
    return <Row className="box-progressi-label">
      <Col md={6} className="box-progressi-label">Colazioni</Col>
      <Col md={6} className="box-progressi-label" style={{ fontWeight: 'bold', textAlign: 'right' }}>{pasti.colazioniFatte}/{pasti.colazioniTotali}</Col>
      <Col md={6} className="box-progressi-label">Spuntini</Col>
      <Col md={6} className="box-progressi-label" style={{ fontWeight: 'bold', textAlign: 'right' }}>{pasti.spuntiniFatti}/{pasti.spuntiniTotali}</Col>
      <Col md={6} className="box-progressi-label">Pranzi</Col>
      <Col md={6} className="box-progressi-label" style={{ fontWeight: 'bold', textAlign: 'right' }}>{pasti.pranziFatti}/{pasti.pranziTotali}</Col>
      <Col md={6} className="box-progressi-label">Cene</Col>
      <Col md={6} className="box-progressi-label" style={{ fontWeight: 'bold', textAlign: 'right' }}>{pasti.ceneFatte}/{pasti.ceneTotali}</Col>
      <Col md={6} className="box-progressi-label">Totale</Col>
      <Col md={6} className="box-progressi-label" style={{ fontWeight: 'bold', textAlign: 'right' }}>{pasti.pastiFatti}/{pasti.pastiTotali}</Col>
    </Row>
  }

  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        &nbsp;{`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderBarChart = (label, data) => {
    return <CustomCard className="box-progressi-container">
      <CardBody style={{ width: "100%" }}>
        <div className="box-progressi-label">{label}</div>
        {
          (data == undefined || data.length == 0) ?
            <div>Non ci sono ancora dati</div>
            :
            <ResponsiveContainer height={300}>
              <BarChart
                data={data}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="data" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                <ReferenceLine y={0} stroke="#000" />
                <Brush dataKey="data" height={30} stroke="#8884d8" />
                <Bar dataKey="Pasti fatti" fill="#3498db" />
                <Bar dataKey="Pasti non fatti" fill="#c0392b" />
              </BarChart>
            </ResponsiveContainer>
        }
      </CardBody>
    </CustomCard>
  }

  render() {
    const { progressi } = this.state
    return <div className="animated fadeIn">
      <Row>
        <Col md={12} className="box-progressi-label">
          <h4 style={{ textAlign: 'right' }}>Periodo di riferimento</h4>
        </Col>
        <Col md={12} className="box-progressi-value " style={{ textAlign: 'right', fontSize: '1.2rem', marginBottom: 10 }}>
          {
            progressi.dataPrimaVisita == "-" ? "-" :
              moment(progressi.dataPrimaVisita).format('MMM YYYY') +
              " - " +
              moment(progressi.dataUltimaVisita).format('MMM YYYY')
          }
        </Col>
        <Col md={12}>
          <h4>Dati paziente</h4>
        </Col>
        <Col md={12}>
          <div class="d-flex align-items-stretch justify-content-between">
            <WidgetStatSecco value={progressi.numeroVisite} label={"Visite"} />
            <WidgetStatSecco value={progressi.numeroAppuntamenti} label={"Appuntamenti"} />
            <WidgetStatSecco value={progressi.numeroDiete} label={"Diete"} />
            <WidgetStatSecco value={progressi.ultimoPeso == "-" ? "-" : progressi.ultimoPeso + " kg"} label={"Peso"} />
            <WidgetStatSecco value={progressi.ultimaAltezza == "-" ? "-" : progressi.ultimaAltezza + " cm"} label={"Altezza"} />
            <WidgetStatSecco value={progressi.ultimoBMI == "-" ? "-" : progressi.ultimoBMI} label={"BMI"} />
          </div>
        </Col>
        <Col md={12}>
          <h4>Pasti effettuati</h4>
        </Col>
        <Col md={2}>
          {this.renderPieChart(
            "Colazioni",
            [{ name: "Fatte", value: progressi.pasti.colazioniFatte, },
            { name: "Non fatte", value: progressi.pasti.colazioniTotali - progressi.pasti.colazioniFatte }],
            ["#3498db", "#c0392b"])}
        </Col>
        <Col md={2}>
          {this.renderPieChart(
            "Spuntini",
            [{ name: "Fatte", value: progressi.pasti.spuntiniFatti, },
            { name: "Non fatte", value: progressi.pasti.spuntiniTotali - progressi.pasti.spuntiniFatti }],
            ["#3498db", "#c0392b"])}
        </Col>
        <Col md={2}>
          {this.renderPieChart(
            "Pranzi",
            [{ name: "Fatte", value: progressi.pasti.pranziFatti, },
            { name: "Non fatte", value: progressi.pasti.pranziTotali - progressi.pasti.pranziFatti }],
            ["#3498db", "#c0392b"])}
        </Col>
        <Col md={2}>
          {this.renderPieChart(
            "Cene",
            [{ name: "Fatte", value: progressi.pasti.ceneFatte, },
            { name: "Non fatte", value: progressi.pasti.ceneTotali - progressi.pasti.ceneFatte }],
            ["#3498db", "#c0392b"])}
        </Col>
        <Col md={4}>
          {this.renderPieChart(
            "Totale",
            [{ name: "Fatte", value: progressi.pasti.pastiFatti, },
            { name: "Non fatte", value: progressi.pasti.pastiTotali - progressi.pasti.pastiFatti }],
            ["#3498db", "#c0392b"],
            () => this.renderSummary())}
        </Col>
        <Col md={12}>
          {this.renderBarChart(
            "Comportamento dieta",
            progressi.pasti.andamento)}
        </Col>
      </Row>
    </div>
  }
}

export default Riepilogo;

