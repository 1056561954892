import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

// This function takes a component...
export default function Controller(WrappedComponent, ) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()
    state = {
      users: [],
      loading: true,
      error: false,
      notificaText: ""

    }

    componentDidMount() {
      this.loadUsers()
    }

    loadUsers = async () => {
      try {
        const response = await this.apiClient.getAllPazienti()
        this.setState({ users: response.data, loading: false })
      } catch (err) {
        this.setState({ error: true, loading: false })
      }

    }


    sendNotifica = async () => {
      const { users, notificaText } = this.state
      for (let i = 0; i < users.length; i++) {
        const u = users[i]
        try {
          await this.apiClient.sendNotifica(u.id, { message: notificaText })
        } catch (err) { }
      }
      this.setState({ notificaText: "" })
      Alert.success("Notifiche inviate!")
    }

    updatePaziente = async (p) => {
      try {
        await this.apiClient.updatePaziente(p);
        Alert.success("Paziente aggiornato!")
      } catch (err) {
        Alert.error("Errore, impossibile aggiornare il paziente.")
      }
    }

    eliminaPaziente = async (id) => {
      if (window.confirm("Sei sicuro di voler eliminare il paziente?")) {
        try {
          await this.apiClient.deletePaziente(id)
          Alert.success("Paziente eliminato!")
          this.loadUsers()
        } catch (err) {
          Alert.error("Errore, impossibile eseguire l'operazione.")
        }
      }
    }

    changeTestoNotifica = (notificaText) => this.setState({ notificaText })

    render() {
      return <WrappedComponent
        {...this.props}
        updatePaziente={this.updatePaziente}
        eliminaPaziente={this.eliminaPaziente}
        sendNotifica={this.sendNotifica}
        changeTestoNotifica={this.changeTestoNotifica}
        state={this.state}
      />
    }
  }
}
