import React, { useContext, useReducer, createContext, useEffect } from 'react'
import { reducer, fetchAppuntamenti as fApp, addAppuntamenti as aApp, updateAppuntamenti as uApp, removeAppuntamenti as rApp, } from './reducers';
const AppuntamentiContext = createContext(null)

const AppuntamentiProvider = ({ children }) => {
  const [appuntamenti, dispatch] = useReducer(reducer, [])
  const fetchAppuntamenti = (data) => { fApp(dispatch, data) }//data: {year: ..., mmonth: ...}
  const addAppuntamenti = (data) => { aApp(dispatch, data); };
  const updateAppuntamento = (data) => { uApp(dispatch, data); };
  const removeAppuntamento = (data) => { rApp(dispatch, data); };

  return <AppuntamentiContext.Provider
    value={{ appuntamenti, dispatch, fetchAppuntamenti, addAppuntamenti, updateAppuntamento, removeAppuntamento }}>
    {children}
  </AppuntamentiContext.Provider>
}

// Helper function to get Context
const useAppuntamenti = () => {
  const context = useContext(AppuntamentiContext);
  if (!context) {
    throw new Error('useAppuntamenti must be used within a AppuntamentiProvider');
  }
  return context;
}

export { AppuntamentiProvider, useAppuntamenti };