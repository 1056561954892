import React, { Component } from 'react';
import ApiClient from '../../ApiClient'
import { Button, Input, Alert, Spinner, Col } from 'reactstrap';
import AlertMessage from 'react-s-alert';

export default class ConsigliNutrizionali extends Component {
  apiClient = new ApiClient()

  state = {
    paziente: {
      consiglinutrizionali: ""
    },
    error: false,
    loading: true,
  }

  componentDidMount() {
    //carico i consigli nutrizionali precedente, se ci sono
    this.loadPaziente()
  }

  loadPaziente = async () => {
    try {
      const { pid } = this.props
      const paziente = await this.apiClient.getPazienteById(pid)
      this.setState({
        paziente: paziente.data,
        loading: false,
        error: false
      })
    } catch (err) {
      this.setState({
        loading: false,
        error: true
      })
    }
  }

  changeMessage = (m) => {
    const { paziente } = this.state
    paziente.consiglinutrizionali = m
    this.setState({
      paziente
    })
  }

  sendConsigliNutrizionali = async () => {
    const { consiglinutrizionali } = this.state.paziente
    try {
      await this.apiClient.updateConsigliNutrizionali(this.props.pid, {
        consiglinutrizionali: consiglinutrizionali
      })
      AlertMessage.success("Consigli nutrizionali aggiornati!");
    } catch (err) {
      AlertMessage.error("Errore, operazione non riuscita.");
    }
  }

  render() {
    const { error, paziente, loading } = this.state
    const { has_app_account } = paziente
    if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
    </div>
    }
    else if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else return <div>
      {!has_app_account &&
        <div className="mb-10">
          <Alert color="warning"> Questa funzionalità è disponibile solo per gli utenti che hanno l'app mobile.</Alert>
        </div>
      }

      <div className="mb-10">
        Qui puoi indicare dei consigli nutrizionali al paziente, che potrà consultarli direttamente dall'app SìDiet.
      </div>
      <Input placeholder={"Inserisci i consigli nutrizionali"} disabled={!has_app_account} type="textarea" rows={10} style={{ marginBottom: 10 }} onChange={(evt) => this.changeMessage(evt.target.value)} value={paziente.consiglinutrizionali} />
      <div className={"flexIt"}>
        <Button color="primary" disabled={!has_app_account} outline onClick={this.sendConsigliNutrizionali}>Salva</Button>
      </div>
    </div>
  }
}