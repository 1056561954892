import React, { useContext, useReducer, createContext, useEffect } from 'react'
import AppReducer from './reducers';
import ApiClient from '../../ApiClient';

const apiClient = new ApiClient()

//init 
export const initialState = {
  data: {
    pazienti_rimanenti: 0,
    attivi: 0,
    registrati: 0,
    ricavi_30_gg: 0,
    visite_30_giorni: 0,
    appuntamenti_30_giorni: 0,
    appuntamenti: [],
    residenza: []
  },
  isLoading: false,
  isError: false
}

const StatsContext = createContext(null)

const StatsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  function fetchInit() {
    dispatch({
      type: "FETCH_INIT",
    });
  }
  function fetchFailure() {
    dispatch({
      type: "FETCH_FAILURE",
    });
  }
  function fetchSuccess(data) {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: data
    });
  }

  //inizializzazione 
  useEffect(() => {
    async function fetchData() {
      fetchInit()
      try {
        const response = await apiClient.getDashboardInfo()
        fetchSuccess(response.data.stats)
      } catch (err) {
        fetchFailure()
      }
    }
    fetchData()
  }, [])

  return <StatsContext.Provider
    value={{
      ...state,
      fetchFailure,
      fetchInit,
      fetchSuccess,
    }}>
    {children}
  </StatsContext.Provider>
}

// Helper function to get Context
const useStats = () => {
  const context = useContext(StatsContext);
  if (!context) {
    throw new Error('useStats must be used within a StatsProvider');
  }
  return context;
}

export { StatsProvider, useStats };