import React from 'react'
import { Col, Row, Button, Alert, Spinner, } from 'reactstrap'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchPlus, } from '@fortawesome/free-solid-svg-icons'
import { MAIL_CONTACT } from '../../constants'
import { useStats } from '../../state/stats';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const formattaCitta = citta => {
  try {
    return citta[0].toUpperCase() + citta.substring(1)
  } catch (err) {
    return "-"
  }
}
const WidgetPazienti = (props) => {
  const { data, isLoading, isError } = useStats()
  if (isError)
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  else if (isLoading)
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  else return <>
    <Row>
      <Col md={12}>
        <h4>Account Pazienti
          <hr /></h4>
      </Col>
    </Row>
    <div className={"table table-hover table-condensed"}>
      <Row>
        <Col md={12} className={"flexIt"}>
          <span>
            Registrati
          </span>
          <h4><strong>{data.registrati}</strong></h4>
        </Col>
        <Col md={12} className={"flexIt"}>
          <span>
            Attivi
          </span>
          <h4><strong>{data.attivi}</strong></h4>
        </Col>
        <Col md={12} className={"flexIt"}>
          <span>
            Rimanenti
          </span>
          <h4><strong>{data.pazienti_rimanenti}</strong></h4>
        </Col>
        {data.pazienti_rimanenti == 0 &&
          <Col md={12}>
            <div style={{ color: 'red', fontWeight: 'bold' }}>ATTENZIONE: non puoi più registrare account pazienti.
              Contattaci a <a href={`mailto:${MAIL_CONTACT}`}>{MAIL_CONTACT}</a> per acquistarne altri.</div>
          </Col>
        }
        {data.pazienti_rimanenti < 10 && data.pazienti_rimanenti > 0 &&
          <Col md={12}>
            <div style={{ color: 'red', fontWeight: 'bold' }}>ATTENZIONE: hai solo {data.pazienti_rimanenti} account pazienti rimanenti.
              Contattaci a <a href={`mailto:${MAIL_CONTACT}`}>{MAIL_CONTACT}</a> per acquistarne altri.</div>
          </Col>
        }
      </Row>
      <Row>
        <Col md={12}><hr /></Col>
      </Row>
      <Row>
        <Col md={12} className={"flexIt"}>
          <span><strong><b>Provenienza degli assistiti</b></strong></span>
        </Col>
        <div className="width-100p" style={{ maxHeight: "350px", overflowY: "scroll" }}>
          {
            data.residenza?.map(r => <Col md={12} className={"flexIt"}>
              <span>{formattaCitta(r.citta)}</span>
              <h5><strong>{r.n}</strong></h5></Col>)
          }
        </div>
      </Row>
      <Row>
        <Col md={12} className={"d-flex justify-content-end"}>
          <Link to={'/users'}><Button outline color="primary" size={"sm"}>
            <FontAwesomeIcon style={{ fontSize: 20 }} className={"cursorPointer"} icon={faSearchPlus} />&nbsp; Vedi tutti
          </Button>
          </Link>
        </Col>
      </Row>
    </div>
  </>
}

const ChartAppuntamenti = (props) => {
  const { data, isLoading, isError } = useStats()
  const { appuntamenti } = data
  if (isError)
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  else if (isLoading)
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  else return <>
    <h4>Appuntamenti e visite negli ultimi 30 giorni<hr /></h4>
    <Row>
      <Col md={9}>
        <div className="height-30vh">
          <ResponsiveContainer >
            <BarChart
              data={appuntamenti}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="giorno" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Numero Appuntamenti" fill="#8884d8" />
              <Bar dataKey="Numero Visite" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Col>
      <Col md={3}>
        <div className={"table table-hover table-condensed"}>
          <Row>
            <Col md={12} className={"flexIt"}>
              <span>
                Visite Effettuate
              </span>
              <h4><strong>{data.visite_30_giorni}</strong></h4>
            </Col>
            <Col md={12} className={"flexIt"}>
              <span>
                Appuntamenti presi
              </span>
              <h4><strong>{data.appuntamenti_30_giorni}</strong></h4>
            </Col>
            <Col md={12} className={"flexIt"}>
              <span>
                Ricavi
              </span>
              <h4><strong>€ {data.ricavi_30_gg}</strong></h4>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </>
}

export { WidgetPazienti, ChartAppuntamenti }