import { Sede } from "../types/sede.interface";

//validazione sede: nome, citta, via e telefono1 obbligatori!
const validateSede = (sede: Sede) => {
  if (sede.citta == "" || sede.citta == null || sede.citta == undefined)
    return false;
  if (sede.via == "" || sede.via == null || sede.via == undefined) return false;
  if (
    sede.telefono1 == "" ||
    sede.telefono1 == null ||
    sede.telefono1 == undefined
  )
    return false;
  if (sede.nome == "" || sede.nome == null || sede.nome == undefined)
    return false;
  return true;
};

export { validateSede };
