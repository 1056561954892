import React from 'react';
import ApiClient from '../../ApiClient'
import Alert from 'react-s-alert';

// This function takes a component...
export default function Controller(WrappedComponent, ) {
  // ...and returns another component...
  return class extends React.Component {
    apiClient = new ApiClient()

    state = {

      loading: true,
      error: false,
      user: {},
      //oggetto che conterrà i dati della password utente da modificare
      password: {
        vecchia: "",
        nuova: "",
        ripetiNuova: "",
      },
      nutrizionista: {},
      logo: null

    }
    componentDidMount() {
      this.loadData()

    }

    loadData = async () => {
      const promise2 = this.loadUser()
      const promise3 = this.loadNutrizionista()
      await Promise.all([promise2, promise3])
      this.setState({
        loading: false
      })
    }

    //scarica i dati del nutrizionista
    loadNutrizionista = async () => {
      let { nutrizionista } = this.state
      try {
        const response = await this.apiClient.getNutrizionista()
        nutrizionista = response.data
      } catch (err) {
        console.log(err)
        this.setState({ error: true })
      } finally {
        this.setState({ nutrizionista, })
      }
    }
    //scarica i dati dell'user loggato
    loadUser = async () => {
      let { user } = this.state
      try {
        const response = await this.apiClient.getUserInfo()
        user = response.data
      } catch (err) {
        console.log(err)
        this.setState({ error: true })
      } finally {
        this.setState({ user, })
      }
    }

    //modifica dati utente
    submitUser = async () => {
      const { user } = this.state
      try {
        await this.apiClient.updateUserInfo({
          nome: user.nome,
          cognome: user.cognome,
          email: user.email
        })
        //TODO: aggiornare context così si aggiorna l'header!
        Alert.success("Dati aggiornati con successo.")
        return true;
      } catch (err) {
        Alert.error("Errore, impossibile eseguire l'operazione.")
        return false;
      }
    }
    //modifica dati nutrizionista
    submitNutrizionista = async () => {
      const { nutrizionista } = this.state
      try {
        await this.apiClient.updateNutrizionista({
          ragsociale: nutrizionista.ragsociale,
          nome: nutrizionista.nome,
          cognome: nutrizionista.cognome,
          rescheduleorari: nutrizionista.rescheduleorari,
          email: nutrizionista.email,
        })
        Alert.success("Dati aggiornati con successo.")
        return true;
      } catch (err) {
        Alert.error("Errore, impossibile eseguire l'operazione.")
        return false;
      }
    }
    //API di aggiornamento immagine logo
    submitLogoNutrizionista = async () => {
      //se file in state non è null, aggiorno quello in backend
      const { logo } = this.state
      if (logo != null) {
        try {
          await this.apiClient.updateLogoNutrizionista(logo)
          Alert.success("Logo aggiornato con successo!")
        } catch (err) {
          Alert.error(err.response.data.error)
        }
      }
    }
    //modifica della pssword utente
    submitPassword = async () => {
      const { user, password } = this.state
      if (password.nuova != password.ripetiNuova) {
        Alert.error("Attenzione: hai indicato due password nuove differenti.")
      } else {
        if (window.confirm("Stai per cambiare password. Sei sicuro?")) {
          try {
            await this.apiClient.changePassword(user.username, password.vecchia, password.nuova)
            window.alert("Password aggiornata con successo. Ora dovrai effettuare di nuovo il login.")
            await window.localStorage.clear()
            window.location.href = "/"
          } catch (err) {
            Alert.error("Errore, impossibile eseguire l'operazione.")
            return false;
          }
        }
      }
    }

    setUserField = (field, value) => {
      const { user } = this.state
      user[field] = value
      this.setState({ user })
    }

    setNutrizionistaField = (field, value) => {
      const { nutrizionista } = this.state
      nutrizionista[field] = value
      this.setState({ nutrizionista })
    }

    setPasswordField = (field, value) => {
      const { password } = this.state
      password[field] = value
      this.setState({ password })
    }

    changeLogo = (evt) => {
      this.setState({
        logo: evt.target.files[0]
      })
    }


    render() {
      return <WrappedComponent
        {...this.props}
        state={this.state}
        submitUser={this.submitUser}
        submitPassword={this.submitPassword}
        submitNutrizionista={this.submitNutrizionista}
        submitLogoNutrizionista={this.submitLogoNutrizionista}
        setPasswordField={this.setPasswordField}
        setUserField={this.setUserField}
        setNutrizionistaField={this.setNutrizionistaField}
        changeLogo={this.changeLogo}
      />
    }
  }
}
