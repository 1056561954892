import React from 'react'
import { APP_NAME } from '../../constants'
import { Spinner } from 'reactstrap'

export default (props) => {
  return <div className={"width-100p height-100p d-flex justify-content-center align-items-center"} style={{ position: 'absolute' }}>
    <div className={"d-flex align-items-center justify-content-center master-loading-container"} >
      <div className="wrap-spinner-central master-loading" style={{ borderWidth: 0 }}>
        <h2 className={"d-flex align-items-center justify-content-center"}>
          <img src="/logo192.png" width={50} className="mb-10 mr-10" />
          {APP_NAME}
        </h2>
        <h4> &nbsp; Il sito è attualmente in manutenzione. </h4>
        <h5>Stiamo lavorando per migliorarlo e portarti nuove funzionalità. </h5>
        <h5>Presto sarà di nuovo online! </h5>
      </div>
    </div>
  </div>
}