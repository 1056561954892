import React, { useState, useEffect } from 'react'
import ApiClient from '../../../ApiClient'
import { Spinner, Alert, } from 'reactstrap'
import WidgetStatSecco from '../../../components/WidgetStatSecco/WidgetStatSecco'

const apiClient = new ApiClient()

const initStats = () => {
  return {
    nutrizionisti: 0,
    nutrizionisti_attivi: 0,
    pazienti: 0,
    pazienti_attivi: 0,
    diete: 0
  }
}
export default (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [stats, setStats] = useState(initStats())
  useEffect(() => {
    getStats()
  }, [])

  const getStats = async () => {
    let data = initStats()
    try {
      const results = await apiClient.getAdminStats()
      data = results.data
      setError(false)
    } catch (err) {
      console.log(err)
      setError(true)
    } finally {
      setStats(data)
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="wrap-spinner-central">
      <Spinner size="sm" color="info" />
      &nbsp; Carico...
    </div>
  } else if (error) {
    return <div className="d-flex justify-content-center align-items-center">
      <Alert color="danger"> Si è verificato un errore durante il caricamento dei dati, ricarica la pagina. </Alert>
    </div>
  } else return <div className="d-flex align-items-center justify-content-between">
    <WidgetStatSecco label={"Nutrizionisti"} value={stats.nutrizionisti} />
    <WidgetStatSecco label={"Nutrizionisti attivi"} value={stats.nutrizionisti_attivi} />
    <WidgetStatSecco label={"Pazienti"} value={stats.pazienti} />
    <WidgetStatSecco label={"Pazienti attivi"} value={stats.pazienti_attivi} />
    <WidgetStatSecco label={"Diete"} value={stats.diete} />
    <WidgetStatSecco label={"In app ultimi 30min"} value={stats.in_app_last_thirty_minutes} />
  </div>
}