import React from "react";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { useUser } from "../../state/user";

interface Props {}

export default ({}: Props) => {
  const userInfo = useUser();
  const { isError } = userInfo;
  return (
    <Modal size="lg" isOpen={true}>
      <ModalHeader>Benvenuto in SìDiet - Termini e Condizioni</ModalHeader>
      <ModalBody
        style={{ textAlign: "justify", height: "70vh", overflowY: "auto" }}
      >
        <h4>1. Premessa</h4>
        Si prega di leggere attentamente questi termini e condizioni (“Termini”)
        prima di decidere di accedere al portale o utilizzare i servizi resi
        disponibili dal fornitore. I presenti Termini costituiscono un accordo
        legale (“Accordo”) tra l’utente (persona fisica o giuridica, individuata
        come cliente, il “Cliente”) e Daplife che dà il diritto al Cliente di
        utilizzare gratuitamente, mediante accesso autenticato
        all’infrastruttura cloud messa a disposizione dal Fornitore, la
        piattaforma (o “portale”) SiDiet ospitata sull’infrastruttura
        tecnologica tecnica del Fornitore (situata interamente all'interno del
        territorio europeo). Il Cliente avrà diritto anche ad abilitare il
        servizio app ai propri clienti. Le premesse sono parte integrante del
        seguente accordo.
        <h4>2. NATURA ED APPLICABILITA’ DEI TERMINI</h4>I Servizi possono
        cambiare di volta in volta, solo al fine di migliorare la fornitura
        attuale a discrezione del fornitore. Il presente Accordo definisce i
        termini e le condizioni in base ai quali è consentito l'uso del portale
        e descrive il modo in cui sarà trattato l’account del Cliente registrato
        sulla piattaforma. In caso di domande su qualsiasi parte dell'accordo,
        scrivere all'indirizzo amministrazione@daplife.it.
        <h4>3. CONDIZIONI D’USO</h4>È necessario avere almeno 18 anni per
        utilizzare il portale e qualsiasi tipo di servizio ad esso correlato.
        Registrandosi, visitando e utilizzando il sito Web o accettando il
        presente Contratto, l'utente dichiara e garantisce al Fornitore di avere
        almeno 18 anni di età e di avere il diritto, l'autorità e la capacità di
        utilizzare il portale ed i servizi disponibili tramite il sito Web e
        l’applicazione. L’utente accetta e rispetta questo Accordo. Dichiara
        altresì di essere iscritto all’ordine dei medici e/o all’ordine dei
        biologi.  <h4>4. REGISTRAZIONE ED ATTIVAZIONE</h4>
        <p>
          4.1 Il Cliente può attivare tramite la piattaforma i suoi pazienti
          all’App SiDiet. L’app SiDiet rappresenta per il Cliente uno strumento
          per ottimizzare la comunicazione con i suoi pazienti e per tracciare
          le informazioni legate alla consulenza/servizio che eroga. <br />
          4.2 Il Cliente dovrà inserire le proprie credenziali per accedere al
          portale SiDiet. Tali credenziali sono costituite dal nome, cognome,
          uno username e da una password provvisoria (Chiavi di accesso).
          <br />
          4.3 Il Cliente si obbliga a custodire, conservare, utilizzare e
          mantenere segrete le Chiavi di Accesso con la massima cura e diligenza
          anche al fine di evitare l’utilizzo da parte di terzi non autorizzati.
          È fatto divieto al Cliente di cedere, a qualunque titolo, a terzi, le
          Chiavi di Accesso o consentire l’accesso attraverso i sistemi di
          autenticazione esterni previsti dall’Applicazione. La password
          provvisoria sarà aggiornata dal Cliente al primo accesso alla
          piattaforma.
          <br />
        </p>
        4.4 A tale proposito, il Cliente prende atto ed accetta:
        <ul className="pl-40">
          <li>
            che la conoscenza da parte di terzi delle Chiavi di Accesso potrebbe
            consentire a questi ultimi l’indebito utilizzo dell’Applicazione e
            dei Servizi Collegati;
          </li>
          <li>
            che il Fornitore non risponderà dei danni arrecati al Cliente e/o a
            terzi dalla conoscenza, ovvero dall'utilizzo, delle Chiavi d’Accesso
            (o accesso attraverso sistemi di autenticazione esterni) da parte di
            terzi, anche in dipendenza della mancata osservanza di quanto sopra
            prescritto;
          </li>
          <li>
            che qualsiasi attività realizzata utilizzando le Chiavi d’Accesso
            del Cliente si considererà effettuata dal Cliente al quale le
            relative Chiavi d’Accesso sono associate e il Cliente sarà ritenuto
            responsabile di tale utilizzo.
          </li>
        </ul>
        <p>
          4.5 Il Cliente si obbliga comunque a manlevare e mantenere il
          Fornitore indenne da ogni pretesa che possa essere avanzata nei suoi
          confronti a qualsiasi titolo per le violazioni delle previsioni di cui
          al presente articolo 4. 4.6 Il Cliente ha il diritto di attivare
          account al servizio app SiDiet ai propri pazienti.
        </p>
        <h4>5. MALFUNZIONAMENTI O GUASTI</h4>
        5.1 In caso di segnalazione di guasti o malfunzionamenti, il Cliente si
        impegna a fornire tutte le specifiche e le informazioni eventualmente
        richieste dal Fornitore.
        <br />
        5.2 Nelle ipotesi di cui al precedente punto ove il reclamo lamentato
        dal Cliente sia riferito a problemi relativi all’infrastruttura cloud,
        il Fornitore si impegna a richiedere prontamente l’intervento del
        fornitore dell’infrastruttura e dei servizi cloud (di seguito il “Cloud
        Service Provider”), fermo restando che il Cliente non potrà vantare
        alcun tipo di pretesa nei confronti del Fornitore per qualsiasi
        disservizio che sia imputabile al Cloud Service Provider.
        <br />
        <h4>6. ESECUZIONE DELLA PIATTAFORMA SiDiet</h4>
        6.1 Il Cliente prende atto ed accetta che SiDiet è fornito “così com’è”
        ed è caratterizzata da tecnologia in continua evoluzione; per questi
        motivi le caratteristiche tecniche di SiDiet potranno modificarsi nel
        tempo al solo scopo di migliorare il prodotto e renderlo funzionale in
        base all’evoluzione tecnologica.
        <br />
        6.2 Il Cliente fornisce sin d’ora la sua autorizzazione affinché il
        portale ed i Servizi Correlati possano venir erogati in tutto o in parte
        da un Cloud Service Provider ovvero da altro soggetto individuato dal
        Fornitore.
        <br />
        <h4>7. LIMITI ALL’UTILIZZO DELLA PIATTAFORMA E DELL’APP SiDiet</h4>
        7.1 Con l’attivazione di SiDiet, il Cliente è ritenuto unico ed
        esclusivo responsabile per l’utilizzo di SiDiet. Il Cliente riconosce di
        essere l’unico responsabile per i contenuti immessi, presenti,
        transitati e/o conservati sui server che ospitano SiDiet e si obbliga ad
        utilizzare SiDiet esclusivamente per scopi leciti e ammessi dalle
        disposizioni di legge di volta in volta applicabili, dalle regole di
        diligenza, della morale e dell’ordine pubblico ed in ogni caso, senza
        ledere qualsivoglia diritto di terzi.
        <br />
        7.2 Il Fornitore non è tenuto alla verifica dei dati e dei contenuti
        conservati nell’infrastruttura virtuale, salvo che ciò si renda
        necessario per adempiere a disposizioni di legge, a richiesta
        dell’Autorità Giudiziaria o di altra Autorità competente o a specifica
        richiesta del Cliente per ragioni di supporto tecnico da egli richiesto
        e pertanto non può essere in alcun modo ritenuto responsabile per la
        natura e le caratteristiche di tali dati, né per eventuali loro errori
        e/o omissioni, nonché per eventuali danni diretti e/o indiretti
        derivanti al Cliente e/o a terzi dall’utilizzo dei dati stessi.
        <br />
        7.3 Il Cliente si impegna a manlevare e tenere indenne il Fornitore da
        qualsiasi costo, onere spesa o danno che allo stesso possa essere
        cagionato a seguito di azioni di terzi, ivi incluse pubbliche autorità,
        conseguenti a violazione degli impegni di cui al presente articolo.
        <br />
        7.4 Il contraente si impegna a non utilizzare i dati registrati visibili
        sulla piattaforma SiDiet per finalità diverse da quelle autorizzate dal
        paziente (cliente finale). Il fornitore si impegna a consentire
        l’utilizzo del servizio app esclusivamente ai suoi pazienti.
        <br />
        7.5 Daplife si riserva il diritto di veicolare messaggi promozionali
        multimediali (banner, video, testi invormativi, ecc…) sulla piattaforma
        e sull’app impegnandosi comunque a garantire una fruibilità adeguata a
        tutti gli utilizzatori
        <h4>8. CASI DI SOSPENSIONE E/O INTERRUZIONE</h4>
        8.1 Il Fornitore, anche mediante il Cloud Service Provider, farà ogni
        ragionevole sforzo per garantire la massima disponibilità del servizio
        SiDiet. Il Cliente prende atto ed accetta che il Fornitore potrà
        sospendere e/o interrompere SiDiet per garantire gli interventi di
        manutenzione ordinaria o straordinaria che si rendano opportuni e/o
        necessari sia ai locali che ospitano l’infrastruttura che ai server e/o
        apparecchiature ivi contenute. In tali casi, il Fornitore si impegna a
        ripristinare, o a fare in modo che il Cloud Services Provider
        ripristini, SiDiet nel minor tempo possibile al fine di ridurre il
        disagio creato al Cliente.
        <br />
        8.2 Il Fornitore, altresì, ha facoltà di sospendere e/o interrompere la
        fornitura dell’Applicazione e dei Servizi Collegati:
        <br />
        <ul className="pl-40">
          <li>
            in caso di uso improprio o di violazioni del presente Contratto;
          </li>
          <li>
            in caso di guasti e/o malfunzionamenti alla rete e agli apparati di
            fornitura di SiDiet dipendenti da caso fortuito o forza maggiore o
            che comportino pericolo per la rete, per le persone e/o per le cose,
            nonché nel caso di modifiche e/o manutenzioni non programmabili e/o
            prevedibili e tecnicamente indispensabili;
          </li>
          <li>
            qualora ricorrano motivate ragioni di sicurezza e/o garanzia di
            riservatezza;
          </li>
          <li>
            in caso di errato o non conforme utilizzo di SiDiet da parte dal
            Cliente o comunque mancati adempimenti del Cliente ad obblighi di
            legge in materia di utilizzo dei servizi informatici e della rete
            internet;
          </li>
          <li>
            in caso di problemi di SiDiet che non siano rimediabili senza
            sospendere o interrompere SiDiet, in ogni caso informando il Cliente
            circa i tempi di intervento e di risoluzione delle problematiche
            riscontrate;
          </li>
        </ul>
        <p>
          8.3 In ogni caso il Cliente dovrà comunicare al Fornitore entro 24
          (ventiquattro) ore solari eventuali irregolarità o disfunzioni di
          SiDiet. Eventuali danni causati da una comunicazione non tempestiva
          del Cliente non saranno imputabili al Fornitore.  
        </p>
        <h4>9. ASSISTENZA</h4>
        9.1 In caso di disservizio e/o malfunzionamento di SiDiet (piattaforma
        ed app) il Fornitore può richiedere la risoluzione del problema mezzo
        mail entro 24 (ventiquattro) ore solari all’indirizzo
        supporto@daplife.it. Il Fornitore si impegna a completare gli interventi
        entro le 48 ore successive alla segnalazione da parte del Cliente.
        <h4>10. ONORARIO RICONOSCIUTO</h4>
        10.1 Attivazione gratuita per 90 giorni solari a partire dalla data di
        attivazione dell’account del cliente
        <h4>11. RINNOVO</h4>
        Allo scadere dei 90 giorni il cliente può chiedere attivazione a
        pagamento della piattaforma mezzo mail.
        <h4>12. GARANZIE E RESPONSABILITÀ</h4>
        12.1 Il Cliente prende atto e accetta che il Fornitore non rilascia
        dichiarazioni e garanzie espresse o implicite sul fatto che SiDiet sia
        adatta a soddisfare le esigenze del Cliente o che sia esente da errori.
        Il Cliente prende atto che il Fornitore, in nessun caso, potrà essere
        ritenuto responsabile per qualsiasi danno dovesse derivare al Cliente o
        a terzi in conseguenza di ritardi, mancato svolgimento o
        malfunzionamenti e/o interruzioni nell’erogazione di SiDiet. In ogni
        caso, nei limiti massimi consentiti dalla legge, la responsabilità del
        Fornitore non potrà mai eccedere l’ammontare della eventuale somma spesa
        negli ultimi 12 mesi dal Cliente.
        <br />
        12.2 Il Cliente prende altresì atto che il Fornitore in nessun caso
        potrà essere ritenuto responsabile per qualsiasi danno dovesse derivare
        al Cliente stesso o a terzi in conseguenza dell’uso di SiDiet così come
        delle elaborazioni generate dall’Applicazione o mediante i Servizi
        Collegati, essendo il Cliente tenuto in ogni caso a verificare la
        correttezza delle elaborazioni ottenute utilizzando l’Applicazione o i
        Servizi Collegati.
        <br />
        12.3 Senza pregiudizio per la generalità di quanto previsto dal presente
        articolo 3, il Cliente accetta e prende atto che in nessun caso il
        Fornitore potrà essere ritenuto responsabile in caso di guasti e/o
        malfunzionamenti alla rete né, in ogni caso, potrà essere ritenuto
        responsabile del risarcimento del danno per lucro cessante.
        <h4>13. PROPRIETÀ INTELLETTUALE</h4>
        13.1 Il Cliente è tenuto ad utilizzare SiDiet nel rispetto dei diritti
        di proprietà intellettuale e/o industriale del Fornitore e/o di terzi.
        Il Cliente accetta e riconosce che la titolarità dell’Applicazione de
        della piattaforma, inclusi i codici sorgente e gli eventuali
        adattamenti, sviluppi e migliorie apportati dal Fornitore, della
        relativa documentazione, nonché tutti i diritti di utilizzazione
        economica sugli stessi, rimangano in capo al Fornitore. Qualsiasi
        materiale che formi oggetto di diritti di proprietà intellettuale e/o
        industriale in favore di terzi e che sia messo a disposizione del
        Cliente tramite SiDiet, sarà utilizzato dal Cliente nel rispetto di tali
        diritti. Il Cliente assume ogni responsabilità in proposito, e si
        impegna a manlevare ed a tenere indenne, ora per allora, il Fornitore da
        qualsiasi conseguenza pregiudizievole.
        <br />
        13.2 Nel caso in cui il Cliente violi i diritti di proprietà industriale
        o intellettuale del Fornitore e/o di terzi, il Fornitore si riserva il
        diritto di risolvere il Contratto ai sensi dell’articolo 1456 del codice
        civile.
        <br />
        13.3 La titolarità di tutti i diritti sui marchi, loghi, nomi, e altri
        segni distintivi comunque associati a SiDiet è del Fornitore, con la
        conseguenza che il Cliente non potrà in alcun modo utilizzarli senza la
        preventiva autorizzazione scritta del Fornitore.
        <h4>14. REGISTRO LOG</h4>
        14.1 Con riferimento all’erogazione della piattaforma, dell’applicazione
        e dei Servizi Collegati, il Cliente prende espressamente atto ed accetta
        l’esistenza del Registro delle attività (LOG - dati relativi al traffico
        telematico), compilato e conservato dal Fornitore ovvero dal Cloud
        Service Provider, nei termini e con le modalità` stabilite dalla legge.
        Il predetto registro costituisce piena ed incontrovertibile prova dei
        fatti e degli atti compiuti dal Cliente di fronte al Fornitore e/o a
        terzi; esso ha carattere di riservatezza assoluta e potrà` essere
        esibito e/o fornito esclusivamente su richiesta dei soggetti
        espressamente indicati dalla Legge. Il Fornitore adotta tutte le misure
        tecniche ed organizzative necessarie a garantire la riservatezza dei
        registri di collegamento.
        <h4>15. RECESSO</h4>
        15.1 Il Cliente può recedere dal contratto mediante invio dall’indirizzo
        e-mail associato all’Account di una comunicazione contenente la
        dichiarazione “Richiesta di cancellazione account” al seguente indirizzo
        e-mail: amministrazione@daplife.it.
        <h4>16. DISPOSIZIONI FINALI</h4>
        16.1 In nessun caso eventuali inadempimenti e/o comportamenti del
        Cliente difformi rispetto al Contratto potranno essere considerati quali
        deroghe al medesimo o tacita accettazione degli stessi, anche se non
        contestati dal Fornitore. L'eventuale inerzia del Fornitore
        nell'esercitare o far valere un qualsiasi diritto o clausola del
        Contratto, non costituisce rinuncia a tali diritti o clausole.
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ marginRight: "auto" }}
          color="success"
          onClick={() =>
            window.open(
              process.env.REACT_APP_BACKEND_URL +
                "/tos/Termini_e_Condizioni_SiDiet.pdf"
            )
          }
        >
          Scarica in PDF
        </Button>
        {isError && (
          <div style={{ color: "red" }}>
            Si è verificato un errore, riprova più tardi.
          </div>
        )}
        <Button color="primary" onClick={userInfo.accettaTos}>
          Accetto
        </Button>{" "}
        <Button
          color="danger"
          outline
          onClick={() =>
            window.alert(
              "Mi dispiace, ma non potrai utilizzare la piattaforma SìDiet se non accetti i Termini e Condizioni."
            )
          }
        >
          Non Accetto
        </Button>
      </ModalFooter>
    </Modal>
  );
};
