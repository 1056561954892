import React, { Component } from 'react';
import Calendar from '../../components/calendar'
import { Card, CardBody } from 'reactstrap';
class View extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <Calendar {...this.props}></Calendar>
        </CardBody>
      </Card>
    )
  }
}

export default View;

