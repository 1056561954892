import React, { Component } from 'react';
import { Alert, Spinner, Button, Row, Col } from 'reactstrap';
import Controller from './Controller';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash, faWeight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { withRouter } from '../../helper';

class View extends Component {

  columns = [
    {
      name: 'Data',
      selector: row => moment(row.data).format('L'),
      width: "110px",
      sortable: true
    },
    {
      name: 'Peso',
      width: "80px",
      selector: row => row.peso === null ? " - " : row.peso + " Kg"
    },
    {
      name: 'Note',
      selector: row => row.note,
      format: row => <span style={{ whiteSpace: "unset" }}>{row.note}</span>,
    },
    {
      name: 'Note Private',
      selector: row => row.noteprivate,
      format: row => <span style={{ whiteSpace: "unset" }}>{row.noteprivate}</span>,
    },
    {
      name: 'Trattamento',
      selector: row => <span style={{ whiteSpace: "unset" }}>{row.interapia ? "Presente" : "-"}</span>,
      width: "110px",
      format: row => <span style={{ whiteSpace: "unset" }}>{row.interapia ? "Presente" : "-"}</span>,
    },
    {
      name: 'Azioni',
      width: "8rem",
      selector: row => {
        return <span className={"flexIt"} style={{ justifyContent: 'flex-start' }}>
          <Link to={`/users/${this.props.router.params.id}/visite/${row.id}`}>
            <Button outline color="primary"><FontAwesomeIcon icon={faEye} />
            </Button>
          </Link>
          &nbsp;
          <Button outline color="danger" onClick={() => this.props.elimina(row.id)}><FontAwesomeIcon icon={faTrash} />
          </Button>
        </span>
      }
    },
  ]

  render() {
    const { error, loading, visite } = this.props.state
    if (error) {
      return <Alert color="danger">
        Si è verificato un errore durante il caricamento dei dati, riprovare più tardi.
      </Alert>
    } else if (loading) {
      return <div className="wrap-spinner-central">
        <Spinner size="sm" color="info" />
        &nbsp; Carico...
      </div>
    } else return (
      <>
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <h4>Visite</h4>
            <Link to={`/users/${this.props.router.params.id}/visite/add`}><Button style={{ float: 'right' }} outline color="primary"><FontAwesomeIcon icon={faWeight} />&nbsp;Nuova</Button></Link>
          </Col>
        </Row>
        <hr />
        <div className="animated fadeIn">
          <Table data={visite} columns={this.columns} />
        </div>
      </>
    )
  }
}

export default withRouter(Controller(View));